import Amplify, { API } from 'aws-amplify'
import * as queries from './graphql/queries';
import * as constants from './constants.js';


// Dashboard data
export async function getVisits() {
  return await API.graphql({ query: queries.listVisits });
}

export async function getOutreach() {
  return await API.graphql({ query: queries.listOutreachs });
}

export function getReservedSpaceCount(reservationsObject) {
  let spaceReserved = 0;
  if (reservationsObject) {
    let reservations = reservationsObject.items;
    if (reservations || !reservations[i].purchaseDetails) {
      for (var i = 0; i < reservations.length; i++) {
        try {
          let purchaseDetails = JSON.parse(reservations[i].purchaseDetails);
          if (purchaseDetails) {
            for (var j = 0; j < purchaseDetails.items.length; j++) {
              let currentItem = purchaseDetails.items[j];
              spaceReserved += (constants.ITEM_QUANTITY_DICT[currentItem.name] ? constants.ITEM_QUANTITY_DICT[currentItem.name] * currentItem.quantity : 1 * currentItem.quantity)
            }
          }
        } catch (error) {
          spaceReserved += reservations[i].purchasedSpace;
        }
      }
    }
  }
  return spaceReserved;
}

export async function getCurrentUsersListing(listingId) {
  const queriedListing = await API.graphql({ query: queries.getListing, variables: { id: listingId }});
  return queriedListing;
}

export function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}

export function getCurrentSchoolAttribute(email, attribute) {
  let value = window.schools.find(school => email.substring(email.indexOf('@')).includes(school.emailExtension.substring(1)))
  console.log("got this school", value, attribute, value[attribute]);
  return value != null ? value[attribute] : ""
}

String.prototype.hashCode = function() {
  var hash = 0, i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr   = this.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

export function formatSchoolName(schoolName) {
  if (schoolName == "" || schoolName == null || schoolName == undefined) {
    return "No School";
  } else {
    return schoolName;
  }
}
