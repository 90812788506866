import React from 'react'
import Amplify, { Auth } from 'aws-amplify'
import Search from './search'
import * as reservationCache from './reservation_cache.js';

const LogOut = (props) => {

  const signOut = async () => {
    try {
        await Auth.signOut({ global: true });
        reservationCache.updateReservation("loggedIn", false);
        props.recentlyLoggedOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  signOut()
  return (
    <Search/>
  )
}

export default LogOut
