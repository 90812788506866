import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Faq from "react-faq-component";

class FAQ extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {

    const data = {
    title: " ",
    rows: [
        {
            title: "What items can I store?",
            content: `Students have used StoreStash to store suitcases, boxes (24x20x21 in), mini fridges, bikes, and more. We can give you a quote any type of item. Just click contact us, tell us what you'd like to store, and we'll match you with the perfect lister for you!`,
        },
        {
            title: "How does pricing work?",
            content:
                "Storing a box costs $44 with an additional $25 fee if you include the delivery option.",
        },
        {
            title: "Are my items protected?",
            content: `Yes, we Insure your items up to $50 in case of loss or damage. For additional security, store your items with a verified Lister!`,
        },
        {
            title: "Where are my items stored?",
            content: `You get to choose which lister will store your items, and by extension, which the apartment or house where they will be held.`,
        },
        {
          title: "How does delivery work?",
          content: `The lister has the option to provide delivery services to pick up and drop off your things. If the lister you choose does not provide delivery, you will be responsible for delivering and picking up your things at the agreed-upon date.`
        },
      ],
    };

    const styles = {
        // bgColor: 'white',
        titleTextColor: "#43495e",
        rowTitleColor: "#43495e",
        // rowContentColor: 'grey',
        // arrowColor: "red",
    };

    const config = {
        // animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    };

    return (
      <div>
        <Faq data={data} styles={styles} config={config}/>
      </div>
    )
  }

}

export default withRouter(FAQ);
