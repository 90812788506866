import React, { Component } from 'react';
import Amplify, { Auth } from 'aws-amplify'
import './auth.css';
import { withRouter } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import * as validator from '.././validate.js';
import ReactGA from 'react-ga';
import * as utils from '.././utils.js';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirm: '',
      errors: {},
      loadingSubmit: false
    }
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmChange = this.handleConfirmChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateSignUp = this.validateSignUp.bind(this);
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }

  handleConfirmChange(event) {
    this.setState({confirm: event.target.value});
  }

  async signUp() {
    let username = this.state.email;
    let password = this.state.password;
    let errors = this.state.errors;
    try {
      const { user } = await Auth.signUp({
        username,
        password
      });
      let authData = {email: username, password: password};
      this.setState({loadingSubmit: false});
      this.props.showAuthModal("confirmEmail", authData);
      const currentSchoolName = utils.getCurrentSchoolAttribute(username, "name");
      ReactGA.event({
        category: utils.formatSchoolName(currentSchoolName),
        action: 'Sign Up Send Code',
        label: 'signup.js',
      });
    } catch (error) {
      errors["cognito"] = error.message;
      this.setState({
        errors: errors,
        loadingSubmit: false
      });
    }
  }

  async validateSignUp() {
    this.setState({errors: {}});
    let email = this.state.email;
    let password = this.state.password;
    let confirm = this.state.confirm;
    let emailExtensions = window.schools.map(school => school.emailExtension.substring(1));
    let validationResponse = validator.validateSignUp(email, password, confirm, emailExtensions);
    var isValid = validationResponse.isValid;
    if (isValid) {
      this.signUp();
    } else {
      this.setState({
        loadingSubmit: false,
        errors: validationResponse.errors
      });
    }
  }

  handleSubmit(event) {
    setTimeout(this.validateSignUp, 1000);
    event.preventDefault();
  }

  render() {
    return (
      <div id="formContent">
        <p style={{marginTop: 10}} className="brand-registration">StoreStash</p>
        <form style={{marginBottom: 10}} onSubmit={this.handleSubmit}>
          <input type="text" id="login" placeholder="School Email" value={this.state.email} onChange={this.handleEmailChange}/>
          <div className="text-danger">{this.state.errors.email}</div>
          <input type="password" id="password" placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange}/>
          <div className="text-danger">{this.state.errors.password}</div>
          <input hidden={this.state.password == ""} type="password" id="confirm" placeholder="Confirm Password" value={this.state.confirm} onChange={this.handleConfirmChange}/>
          <div className="text-danger">{this.state.errors.confirm}</div>
          <div className="text-danger">{this.state.errors.cognito}</div>
          {this.state.loadingSubmit && <div><CircularProgress style={{color: "#fdad16", marginTop:10}}/></div>}
          <button class="home_onboard_customer" onClick={() => this.setState({loadingSubmit : true})} style={{width: "80%", marginTop: 5}} value="Sign Up">Sign Up</button>
          <div style={{width: "100%", marginTop: -5}}>
            <span style={{marginLeft: 0, color:"grey", fontSize: 10}}>By clicking "Sign Up", you agree  to our</span>
            <button className="button_empty"><a style={{marginLeft: 0, color:"grey", fontSize: 10, textDecoration: "underline"}} href="http://storestash-terms.s3-website-us-west-2.amazonaws.com/" target="_blank">{"Terms & Conditions"}</a></button>
          </div>
        </form>
        <div id="formFooter">
          <div>
            <button className="button_empty underlineHover" style={{color: "#43495e"}} onClick={() => this.props.showAuthModal("login")}>Already have an account?</button>
          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;
