/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://4ri5eozewffwtjovdmcbzrpzqm.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-jf7zihouqnhvhji3dqa7g2nbvy",
    "aws_cloud_logic_custom": [
        {
            "name": "stripeApi",
            "endpoint": "https://llkb5gudq6.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "ses",
            "endpoint": "https://rt1tfbe76d.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "venmoApi",
            "endpoint": "https://wafe8qsoc3.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "dynamoApi",
            "endpoint": "https://s3pc47uql6.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "cognitoApi",
            "endpoint": "https://1kzpzqph91.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "s3Api",
            "endpoint": "https://nvofug5ia1.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "locationApi",
            "endpoint": "https://b9h9eq1tuc.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:4a734846-5753-4dae-b7e6-d40f20bc857d",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_mcctHygXV",
    "aws_user_pools_web_client_id": "3i50ldpusokok1fqjvmetib6gh",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Listing-vz3vfq37nbhqld7lokpfri3msy-prod",
            "region": "us-west-2"
        },
        {
            "tableName": "Listing-6est4kmrkfgetdyr7oxbos72ri-dev",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
