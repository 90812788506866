/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createListing = /* GraphQL */ `
  mutation CreateListing(
    $input: CreateListingInput!
    $condition: ModelListingConditionInput
  ) {
    createListing(input: $input, condition: $condition) {
      id
      type
      capacity
      firstName
      paymentIdentifier
      expirationDate
      residence_id
      listerEmail
      user_id
      school_id
      img_url
      message
      unavailableRanges
      includesDelivery
      createdAt
      isMocked
      reservations {
        items {
          id
          listing_id
          customer_id
          customerEmail
          listerEmail
          purchasedPackaging
          purchasedDelivery
          purchasedSpace
          startDate
          endDate
          createdAt
          purchaseDetails
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      lister {
        email
        listingId
        reservationId
        stripeAccountId
        venmoHandle
        isSubscribedToEmails
        isAdmin
        isMarketer
        isVerified
        profilePicUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateListing = /* GraphQL */ `
  mutation UpdateListing(
    $input: UpdateListingInput!
    $condition: ModelListingConditionInput
  ) {
    updateListing(input: $input, condition: $condition) {
      id
      type
      capacity
      firstName
      paymentIdentifier
      expirationDate
      residence_id
      listerEmail
      user_id
      school_id
      img_url
      message
      unavailableRanges
      includesDelivery
      createdAt
      isMocked
      reservations {
        items {
          id
          listing_id
          customer_id
          customerEmail
          listerEmail
          purchasedPackaging
          purchasedDelivery
          purchasedSpace
          startDate
          endDate
          createdAt
          purchaseDetails
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      lister {
        email
        listingId
        reservationId
        stripeAccountId
        venmoHandle
        isSubscribedToEmails
        isAdmin
        isMarketer
        isVerified
        profilePicUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteListing = /* GraphQL */ `
  mutation DeleteListing(
    $input: DeleteListingInput!
    $condition: ModelListingConditionInput
  ) {
    deleteListing(input: $input, condition: $condition) {
      id
      type
      capacity
      firstName
      paymentIdentifier
      expirationDate
      residence_id
      listerEmail
      user_id
      school_id
      img_url
      message
      unavailableRanges
      includesDelivery
      createdAt
      isMocked
      reservations {
        items {
          id
          listing_id
          customer_id
          customerEmail
          listerEmail
          purchasedPackaging
          purchasedDelivery
          purchasedSpace
          startDate
          endDate
          createdAt
          purchaseDetails
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      lister {
        email
        listingId
        reservationId
        stripeAccountId
        venmoHandle
        isSubscribedToEmails
        isAdmin
        isMarketer
        isVerified
        profilePicUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createVisit = /* GraphQL */ `
  mutation CreateVisit(
    $input: CreateVisitInput!
    $condition: ModelVisitConditionInput
  ) {
    createVisit(input: $input, condition: $condition) {
      ip
      pageName
      countryName
      region
      city
      postal
      longitude
      latitude
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateVisit = /* GraphQL */ `
  mutation UpdateVisit(
    $input: UpdateVisitInput!
    $condition: ModelVisitConditionInput
  ) {
    updateVisit(input: $input, condition: $condition) {
      ip
      pageName
      countryName
      region
      city
      postal
      longitude
      latitude
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteVisit = /* GraphQL */ `
  mutation DeleteVisit(
    $input: DeleteVisitInput!
    $condition: ModelVisitConditionInput
  ) {
    deleteVisit(input: $input, condition: $condition) {
      ip
      pageName
      countryName
      region
      city
      postal
      longitude
      latitude
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createFailure = /* GraphQL */ `
  mutation CreateFailure(
    $input: CreateFailureInput!
    $condition: ModelFailureConditionInput
  ) {
    createFailure(input: $input, condition: $condition) {
      id
      cause
      description
      priority
      impact
      functionName
      userEmail
      user {
        email
        listingId
        reservationId
        stripeAccountId
        venmoHandle
        isSubscribedToEmails
        isAdmin
        isMarketer
        isVerified
        profilePicUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateFailure = /* GraphQL */ `
  mutation UpdateFailure(
    $input: UpdateFailureInput!
    $condition: ModelFailureConditionInput
  ) {
    updateFailure(input: $input, condition: $condition) {
      id
      cause
      description
      priority
      impact
      functionName
      userEmail
      user {
        email
        listingId
        reservationId
        stripeAccountId
        venmoHandle
        isSubscribedToEmails
        isAdmin
        isMarketer
        isVerified
        profilePicUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteFailure = /* GraphQL */ `
  mutation DeleteFailure(
    $input: DeleteFailureInput!
    $condition: ModelFailureConditionInput
  ) {
    deleteFailure(input: $input, condition: $condition) {
      id
      cause
      description
      priority
      impact
      functionName
      userEmail
      user {
        email
        listingId
        reservationId
        stripeAccountId
        venmoHandle
        isSubscribedToEmails
        isAdmin
        isMarketer
        isVerified
        profilePicUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      email
      listingId
      reservationId
      stripeAccountId
      venmoHandle
      isSubscribedToEmails
      isAdmin
      isMarketer
      isVerified
      profilePicUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      email
      listingId
      reservationId
      stripeAccountId
      venmoHandle
      isSubscribedToEmails
      isAdmin
      isMarketer
      isVerified
      profilePicUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      email
      listingId
      reservationId
      stripeAccountId
      venmoHandle
      isSubscribedToEmails
      isAdmin
      isMarketer
      isVerified
      profilePicUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
      name
      type
      emailExtension
      residences
      breakDateRanges
      acronym
      iconUrl
      packagingAvailable
      createdAt
      longitude
      latitude
      hex
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      name
      type
      emailExtension
      residences
      breakDateRanges
      acronym
      iconUrl
      packagingAvailable
      createdAt
      longitude
      latitude
      hex
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
      name
      type
      emailExtension
      residences
      breakDateRanges
      acronym
      iconUrl
      packagingAvailable
      createdAt
      longitude
      latitude
      hex
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createOutreach = /* GraphQL */ `
  mutation CreateOutreach(
    $input: CreateOutreachInput!
    $condition: ModelOutreachConditionInput
  ) {
    createOutreach(input: $input, condition: $condition) {
      id
      type
      marketerEmail
      toEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOutreach = /* GraphQL */ `
  mutation UpdateOutreach(
    $input: UpdateOutreachInput!
    $condition: ModelOutreachConditionInput
  ) {
    updateOutreach(input: $input, condition: $condition) {
      id
      type
      marketerEmail
      toEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOutreach = /* GraphQL */ `
  mutation DeleteOutreach(
    $input: DeleteOutreachInput!
    $condition: ModelOutreachConditionInput
  ) {
    deleteOutreach(input: $input, condition: $condition) {
      id
      type
      marketerEmail
      toEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createReservation = /* GraphQL */ `
  mutation CreateReservation(
    $input: CreateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    createReservation(input: $input, condition: $condition) {
      id
      listing_id
      customer_id
      customerEmail
      listerEmail
      purchasedPackaging
      purchasedDelivery
      purchasedSpace
      startDate
      endDate
      createdAt
      purchaseDetails
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateReservation = /* GraphQL */ `
  mutation UpdateReservation(
    $input: UpdateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    updateReservation(input: $input, condition: $condition) {
      id
      listing_id
      customer_id
      customerEmail
      listerEmail
      purchasedPackaging
      purchasedDelivery
      purchasedSpace
      startDate
      endDate
      createdAt
      purchaseDetails
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteReservation = /* GraphQL */ `
  mutation DeleteReservation(
    $input: DeleteReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    deleteReservation(input: $input, condition: $condition) {
      id
      listing_id
      customer_id
      customerEmail
      listerEmail
      purchasedPackaging
      purchasedDelivery
      purchasedSpace
      startDate
      endDate
      createdAt
      purchaseDetails
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
