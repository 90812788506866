import React, { Component } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

class Selector extends Component {

  handleChange = selectedOption => {
    this.props.updateHandler(selectedOption)
    console.log(selectedOption, "selected this option");
  };

  render() {
    console.log("default value", this.props.defaultValue);
    return (
      <Select
        isDisabled={this.props.isDisabled}
        placeholder={this.props.placeholder != null ? this.props.placeholder : "..."}
        classNamePrefix={"customSelect"}
        closeMenuOnSelect={this.props.closeMenuOnSelect ? this.props.closeMenuOnSelect : false}
        components={animatedComponents}
        isMulti
        options={this.props.options}
        defaultValue={this.props.defaultValue}
        onChange={this.handleChange}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            primary25: '#F5F5F5',
            primary: '#43495e',
          },
        })}
      />
    );
  }
}

export default Selector;
