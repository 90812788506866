import React, { Component } from 'react';
import EarningsTable from './earnings_table';
import Amplify, { Auth, API } from 'aws-amplify';
import FadeIn from 'react-fade-in';

class Earnings extends Component {

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillMount() {
    //remove mobile rendering event listener
    window.removeEventListener("resize", this.updateDimensions);
  }

  constructor(props) {
    super(props);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.state = {
      user: null
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  async isLoggedIn() {
    try {
      let user = await Auth.currentAuthenticatedUser();
      if (this.state.user == null) {
        let userListingId = user.attributes['custom:listingId'];
        let currentUser = {
          userListingId: userListingId != null ? userListingId : "",
          email: user.attributes.email,
          username: user.attributes.email.substr(0, user.attributes.email.indexOf('@'))
        }
        this.setState({user: currentUser});
      }
      return true;
    } catch(err) {
      console.log("No user", err);
    }
  }

  updateDimensions = () => {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let mobile = windowWidth < 600 ? true : false;
    var size = 0
    if (windowWidth >= 450) {
      size = 1
    }
    if (windowWidth >= 576) {
      size = 2
    }
    if (windowWidth >= 768) {
      size = 3
    }
    if (windowWidth >= 992) {
      size = 4
    }
    if (windowWidth >= 1200) {
      size = 5
    }
    this.setState({windowWidth: windowWidth, size: size, mobile: mobile});
  }

  render() {
    console.log("TESTING THIS:", window);
    this.isLoggedIn();
    const { windowWidth } = this.state;
    const { mobile } = this.state;

    let activeListerCard = <div class="card text-left" style={{borderRadius: 12, marginLeft: windowWidth * 0.05, marginRight: windowWidth * 0.05, minWidth: 330, marginBottom: 30}}>
        <div class="card-body">
        <h5 class="card-title"><b>Reach more customers</b></h5>
        <p style={{fontSize: 13+'px', position: "absolute"}} class="card-subtitle text-muted">Make $$$ for each item you store</p>
        <hr style={{marginTop: 30}}></hr>
        <textarea disabled class="text-body" rows={this.state.showMoreLister ? "12" : "4"} style={{paddingBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", resize: "none", backgroundColor: "#ffffff00"}}>
          {
            "Become a verified lister and earn more with StoreStash. Check out our tips to get more customers!"
          }
        </textarea>
        <br></br>
        { // No listings, show muted buttons
          <FadeIn>
            <button onClick={()=> this.props.history.push(`product_info`)} style={{marginTop:15}} className="home_onboard_customer">View Tips</button>
          </FadeIn>
        }
        </div>
      </div>

    return (<div>
      <div style={{ display: !mobile ? "grid" : null, gridTemplateColumns: "1fr 3fr", gridGap: 20, marginBottom: 0}}>
        <div class={!mobile ? "border-right" : null}>
          <div style={{marginTop: 30}}>
            {activeListerCard}
          </div>
        </div>
        {this.state.user && <EarningsTable listingId={this.state.user.userListingId} />}
      </div>

      {!this.state.user && <p>No user</p>}
    </div>)
  }
}

export default Earnings;
