import React from "react";
import '.././styles.css';
import { useFileUpload } from "use-file-upload";
import logoImage from '.././assets/logo.png';

function ImageUpload(props) {

  const [files, selectFiles] = useFileUpload();
  return (
    <div>
      <button class="button_empty" onClick={() =>
        selectFiles({ accept: "image/*" }, ({ name, size, source, file }) => {
          props.handleImageUpload(file)
        })}>
        <div class="crop" style={{position: "relative", textAlign: "center"}}>
          <img class="image-fit-img dark-overlay mt-3" src={files?.source || props.defaultImage} alt="preview" />
        </div>
        <div className="button button_secondary centered" style={{marginTop: props.textMarginTop}}> Change Photo </div>
      </button>
    </div>
  );
};
export default ImageUpload;
