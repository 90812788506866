import { useState } from 'react'
import Amplify, { Auth } from 'aws-amplify'
import { useHistory } from "react-router-dom"
import * as reservationCache from './reservation_cache.js';

// Material UI
import { CircularProgress } from '@material-ui/core';

const Login = (props) => {
  let history = useHistory()

  const [loadingSubmit, setLoadingSubmit] = useState()
  const [unconfirmedAccount, setUnconfirmedAccount] = useState()
  const [showForgotPasswordViews, setShowForgotPasswordViews] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [error, setError] = useState()

  const handleEmailChange = (event) => {
	   event.persist();
	   setEmail(event.target.value);
  }

  const handlePasswordChange = (event) => {
	   event.persist();
	   setPassword(event.target.value);
  }

  const authLogin = async (event) => {
    event.preventDefault();
    try {
      const user = await Auth.signIn(email, password);
      setLoadingSubmit(false);
      props.isLoggedIn();
      let user_id = email.substr(0, email.indexOf('@'));
      reservationCache.updateReservation("customer_id", user_id);
      reservationCache.updateReservation("loggedIn", true);
      props.showAuthModal("");
    } catch (error) {
      if (error.code && error.code == "UserNotConfirmedException") {
        // Handle unconfirmed account
        setTimeout(function() {
          setLoadingSubmit(false);
          setUnconfirmedAccount(true);
          setError("Account is unverified. Select 'Send Code' to continue.");
        }, 1000);
      } else {
        // Handle other errors
        setTimeout(function() {
          setLoadingSubmit(false);
          setError(error.message);
        }, 1000);
      }
    }
  }

  // User forgot password
  const sendForgotPasswordCode = async (event) => {
    event.preventDefault();
    try {
      let response = await Auth.forgotPassword(email);
      let authData = {email: email, password: password, forgotPassword: true};
      props.showAuthModal("confirmEmail", authData);
    } catch (error) {
      console.log(error, "error here..");
      setTimeout(function() {
        setLoadingSubmit(false);
        setError(error.message);
      }, 1000);
    }
  }

  // User has signed up but need to verify code
  const resendCode = async (event) => {
    try {
      let authData = {email: email, password: password};
      props.showAuthModal("confirmEmail", authData);
    } catch (error) {
      console.log(error, "error here..");
      setTimeout(function() {
        setLoadingSubmit(false);
        setError(error.message);
      }, 1000);
    }
  }

  return (
      <div id="formContent">
        <p style={{marginTop: 10}} className="brand-registration">StoreStash</p>
        <form style={{marginBottom: 5}} onSubmit={showForgotPasswordViews ? sendForgotPasswordCode : authLogin}>
          <input type="text" id="email" class="fadeInDown" placeholder="Email" value={email} onChange={handleEmailChange}/>
          <input hidden={showForgotPasswordViews} type="password" id="password" class="fadeInDown first" placeholder="Password" value={password} onChange={handlePasswordChange}/>
          <button class="fadeInDown second home_onboard_customer" onClick={() => setLoadingSubmit(true)} style={{width: "80%", marginTop: 5, marginBottom: 5}}>{showForgotPasswordViews ? "Send Code" : "Log In"}</button>
          <div className="text-danger">{error}</div>
          {loadingSubmit && <div><CircularProgress style={{color: "#fdad16", marginTop:10}}/></div>}
        </form>
        <div id="formFooter">
            <div>
                <button className="button_empty underlineHover" style={{color: "#43495e"}} onClick={() => props.showAuthModal("signUp")}>Sign Up</button>
                {!unconfirmedAccount && <button hidden={showForgotPasswordViews} style={{color: "#43495e"}} className="button_empty underlineHover" onClick={() => setShowForgotPasswordViews(true)}>Forgot Password?</button>}
                {unconfirmedAccount && <button className="button_empty underlineHover" onClick={() => setShowForgotPasswordViews(true)}>Send Code</button>}
            </div>
        </div>
      </div>
  )
}

export default Login
