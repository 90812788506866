import React, { Component } from 'react';
import { Button, CircularProgress, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { API, Cache } from 'aws-amplify';
import { injectStripe } from 'react-stripe-elements';
import * as reservationCache from './reservation_cache.js';
import AlertDialog from './alert';
import * as utils from '.././utils.js';
import ReactGA from 'react-ga';

class PayButtonContent extends Component {
    state = {
      loading: false,
      alertDetails: null
    }
    constructor() {
        super();
        this.handlePay = this.handlePay.bind(this)
    }

    async handlePay() {

      // Emit stat when User Opens Stripe Checkout
      try {
        ReactGA.event({
          category: this.props.currentSchool,
          action: 'Viewed Stripe Checkout',
          label: 'reservation_details.jsx',
        });
      } catch (error) {
        console.log("Failure: " + error.message);
      }

      let errorMessage = reservationCache.validReservation();
      console.log(errorMessage, "got this error");
      if (errorMessage == "Push Sign Up") {
        this.props.showAuthModal("signUp");
        return
      } else if (errorMessage == "Venmo Payment Chosen") {
        this.props.showVenmoModal();
        return
      } else if (errorMessage != "") {
        this.setState({alertDetails:
          {
            title: "Purchase Notice",
            description: errorMessage,
            open: true
          }
        })
        return
      }
      this.setState({ loading: true })

      const body = {
          name: this.props.name,
          description: this.props.description,
          images: this.props.images,
          amount: this.props.amount,
          currency: this.props.currency,
          quantity: this.props.quantity,
          success_url: this.props.success_url,
          cancel_url: this.props.cancel_url,
      }
      const response = await API.post(this.props.apiName, this.props.apiEndpoint, { body })
      this.props.stripe.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: response.session.id
      }).then(function (result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
      });
      this.setState({ loading: false });
    }

    render() {
        const { loading } = this.state;
        const { amount, currency } = this.props;
        return (
          <div class="row" style={{width: "100%", margin:0, display: "inline", justifyContent: "space-between"}}>
            {this.state.alertDetails != null &&
              <AlertDialog
                title={this.state.alertDetails.title}
                description={this.state.alertDetails.description}
                open={this.state.alertDetails.open}
                onClose={() => this.setState({alertDetails: null})}
              />
            }
            <button hidden={loading} className="button_reserve" onClick={this.handlePay}>Reserve</button>
            {loading ? <CircularProgress style={{color: "#fdad16"}}/> : null}
          </div>
        );
    }
}

PayButtonContent.propTypes = {
    apiName: PropTypes.string.isRequired,
    apiEndpoint: PropTypes.string.isRequired,

    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,

    success_url: PropTypes.string.isRequired,
    cancel_url: PropTypes.string.isRequired,
};

export default injectStripe(PayButtonContent);
