import React, { Component } from 'react';
import { withRouter } from 'react-router';
import CarouselItem from './carousel_item';
import Carousel from "react-multi-carousel";
import { Container, Row, Col } from 'react-bootstrap';
import "react-multi-carousel/lib/styles.css";
import customerStory1 from '.././assets/customer_story/1_listings_that_fit_your_schedule.png'
import customerStory2 from '.././assets/customer_story/2_pack_your_things.png'
import customerStory3 from '.././assets/customer_story/3_drop_off_your_items.png'
import customerStory4 from '.././assets/customer_story/4_complimentary_insurance.png'
import customerStory5 from '.././assets/customer_story/5_pick_up_your_items.png'


class CarouselStory extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    /*<Carousel interval={1000000} indicators={false} animation="slide" duration={700} navButtonsAlwaysVisible={true}>
      { noDeliveryCustomerStory.map( (item, i) => <div>
        <CarouselItem key={i} item={noDeliveryCustomerStory[i]} />
      </div> ) }
    </Carousel>*/
    var noDeliveryCustomerStory = [
        { title: "1) Listings That Fit Your Schedule", description: "Find a space that fits your storage needs. Whether you're looking for delivery, flexible timelines, or closeby listings, we've got you covered." },
        { title: "2) Pack Your Things", description: "Once you've chosen your space, you'll receive an email with your lister's contact info. You can coordinate the exact time you'll drop off your packed up items." },
        { title: "3) Drop Off Your Items", description: "Time to meet your lister! Take your things to the location listed in your reservation and they'll handle your storage from there."},
        { title: "4) Complimentary Insurance", description: "All listers are verified students in your campus community, so your items are in good hands. On top of that, your reservation comes with $50 of insurance included." },
        { title: "5) Pick Up Your Items", description: "Once the end date of your reservation rolls around, you're all set to get your items. Loved your experience? Feel free to rate and tip your lister." },
    ];

    var deliveryCustomerStory = [
        { name: "Listings That Fit Your Schedule", description: "Probably the most random thing you have ever seen!" },
        { name: "Pack Your Things", description: "Probably the most random thing you have ever seen!" },
        { name: "Delivery: Pick Up", description: "Hello World!" },
        { name: "Complimentary Insurance", description: "Hello World!!" },
        { name: "Delivery: Drop Off", description: "Hello World!" },
    ];

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 650 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 650, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
      }
    };

    return (
      <Carousel
        swipeable={false}
        draggable={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        <CarouselItem image={customerStory1} title={noDeliveryCustomerStory[0].title} description={noDeliveryCustomerStory[0].description}></CarouselItem>
        <CarouselItem image={customerStory2} title={noDeliveryCustomerStory[1].title} description={noDeliveryCustomerStory[1].description}></CarouselItem>
        <CarouselItem image={customerStory3} title={noDeliveryCustomerStory[2].title} description={noDeliveryCustomerStory[2].description}></CarouselItem>
        <CarouselItem image={customerStory4} title={noDeliveryCustomerStory[3].title} description={noDeliveryCustomerStory[3].description}></CarouselItem>
        <CarouselItem image={customerStory5} title={noDeliveryCustomerStory[4].title} description={noDeliveryCustomerStory[4].description}></CarouselItem>

      </Carousel>);

  }

}

export default withRouter(CarouselStory);
