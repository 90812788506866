import React, { Component } from 'react';
import Amplify, { API, graphqlOperation, Auth, Cache } from 'aws-amplify'
import { Row, Col } from 'react-bootstrap';
import logoImage from '.././assets/logoImage250.png';
import loadingReservationImage from '.././assets/loadingReservation.png';
import { createReservation } from '.././graphql/mutations'
import * as reservationCache from './reservation_cache.js';
import { v4 as uuidv4 } from 'uuid';
import { getReservation, getListing } from '.././graphql/queries'
import { CircularProgress } from '@material-ui/core';
import AlertDialog from './alert';
import { Modal, Button, FormControl, InputGroup } from 'react-bootstrap';
import ContactUs from './contact_us';
import * as utils from '.././utils.js';
import ReactGA from 'react-ga';

class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      isLister: false,
      isCustomer: false,
      isLoadingReservation: false,
      currentReservation: null,
      showMoreCustomer: false,
      showMoreLister: false,
      showContactUs: false,
      emailText: "",
      updatedUser: false,
      howItWorksStage: 2,
    }
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.logOutAction = this.logOutAction.bind(this);
    this.textChanged = this.textChanged.bind(this);
    this.showAccount = this.showAccount.bind(this);
    this.profileAction = this.profileAction.bind(this);
    this.createReservation = this.createReservation.bind(this);
    this.getReservation = this.getReservation.bind(this);
    this.nextStepsEmail = this.nextStepsEmail.bind(this);
    this.clearUser = this.clearUser.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.set = this.set.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);

    ReactGA.pageview(window.location.pathname);
  }

  componentDidMount() {
    //mobile rendering event listener
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillMount() {
    //remove mobile rendering event listener
    window.removeEventListener("resize", this.updateDimensions);
  }

  // Triggered when the window is resized
  updateDimensions = () => {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    var size = 0
    if (windowWidth >= 450) {
      size = 1
    }
    if (windowWidth >= 576) {
      size = 2
    }
    if (windowWidth >= 768) {
      size = 3
    }
    if (windowWidth >= 992) {
      size = 4
    }
    if (windowWidth >= 1200) {
      size = 5
    }
    this.setState({size});
  }

  closeModal() {
    this.setState({showContactUs: false})
  }


  textChanged(event) {
    this.setState({ emailText: event.target.value});
  }

  async createReservation(newReservation) {
    console.log("Attempting to save the following res", newReservation);
    console.log(window.location, "current window..?");
    try {
      await API.graphql(graphqlOperation(createReservation, {input: newReservation}));
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(user, {
          'custom:reservationId': newReservation.id
      });
      reservationCache.clearReservation();
      setTimeout(()=>this.setState({isLoadingReservation: false}), 1500);
      this.setState({isCustomer: true, currentReservation: newReservation, updatedUser: true,
        user: {
          ...this.state.user,
          reservationId: newReservation.id
        }
      });
    } catch(error) {
      console.log("Refresh causes a second call to createReservation. This log is good because it means the call was not made twice");
    }
  }

  async nextStepsEmail(customerEmail, listerEmail, listingId, purchasedDelivery) {
    const queriedReservation = await API.graphql({ query: getListing, variables: { id: listingId }});
    if (queriedReservation.data.getListing) {
      let listing_residence = queriedReservation.data.getListing.residence_id;
      console.log(queriedReservation);
      console.log("lister mail:", listerEmail, "customer email:", customerEmail, "residence:", listing_residence);
      // COME BACK HERE... emails are undefined
      const body = { customerEmail : customerEmail, listerEmail: listerEmail, residence: purchasedDelivery ? "your residence" :  listing_residence }
      const response = await API.post("ses", "/share-lister-contact", { body });
      // COME BACK HERE...
      console.log("This is the response", response);
      this.props.history.push("/profile/empty")
    }
  }

  async getPurchasedSpaceResidence(listingId) {
    const queriedReservation = await API.graphql({ query: getListing, variables: { id: listingId }});
    if (queriedReservation.data.getListing) {

    }
  }

  async logOutAction() {
    try {
        await Auth.signOut({ global: true });
        reservationCache.updateReservation("loggedIn", false);
        this.props.recentlyLoggedOut();
        window.location = Cache.getItem("urlPrefix");
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  profileAction = (action) => {
    console.log(action);
    if (action == "yourListing") {
      this.props.history.push("/new_listing/edit");
    } else if (action == "purchasedSpace") {
      // Get reservation
      this.getReservation();
    } else if (action == "logout") {
      this.setState({alertDetails:
        {
          title: "Confirm Logout",
          description: 'Are you sure you want to logout?',
          open: true
        }
      })
    } else if (action == "paymentHistory") {
      this.showAccount()
    } else if (action == "contactUs") {
      this.setState({showContactUs: true});
    } else if (action == "admin") {
      this.props.history.push("/admin");
    }
  }

  async getReservation() {
    console.log(this.state.user.reservationId, "query with this user id");
    const queriedReservation = await API.graphql({ query: getReservation, variables: { id: this.state.user.reservationId }});
    this.props.history.push("/details/view_" + queriedReservation.data.getReservation.listing_id + "_" + this.state.user.reservationId);
    console.log("this is the user's res", queriedReservation);
  }

  async showAccount() {
    const body = { "accountId" : this.state.user.stripeAccountId };
    const response = await API.post("stripeApi", "/dashboard", { body });
    window.location = response.url;
  }

  async isLoggedIn() {
      try {
        console.log("isLoggedIn right here...");
        let user = await Auth.currentAuthenticatedUser()
        console.log(user);

        if (this.state.user == null || this.state.updatedUser) {
          let stripeId = user.attributes['custom:stripeAccountId'];
          let userListingId = user.attributes['custom:listingId'];
          let reservationId = user.attributes['custom:reservationId'];
          let isAdmin = user.attributes['custom:isAdmin'];
          let isMarketer = user.attributes['custom:isMarketer'];
          console.log("this is the res id", reservationId);
          let currentUser = {
            stripeAccountId: stripeId != null ? stripeId : "",
            userListingId: userListingId != null ? userListingId : "",
            reservationId: reservationId != null ? reservationId : "",
            isAdmin: isAdmin != null ? isAdmin : "",
            isMarketer: isMarketer != null ? isMarketer : "",
            email: user.attributes.email,
            username: user.attributes.email.substr(0, user.attributes.email.indexOf('@'))
          }
          this.setState({user: currentUser, updatedUser: false});

          let urlComponents = window.location.href.split("/");
          let status = urlComponents[urlComponents.length - 1];
          if (status.includes("recentlyPurchased")) {

            // Emit stat when User Purchases Space
            try {
              const schoolName = utils.getCurrentSchoolAttribute(user.attributes.email, "name");
              ReactGA.event({
                category: utils.formatSchoolName(schoolName),
                action: 'Purchased Space',
                label: 'reservation_details.jsx',
              });
            } catch (error) {
              console.log("Failure: " + error.message);
            }

            this.setState({isLoadingReservation: true});
            let listingToReserveId = window.location.href.split("_")[1];
            let reservation = reservationCache.getReservation();
            console.log(reservation, "reservation from the cache");
            if (listingToReserveId) { // Update user with reservation
              let newReservation = {
                id: uuidv4().substring(0,8),
                listing_id: reservation.listing_id,
                customer_id: reservation.customer_id,
                customerEmail: reservation.customerEmail,
                listerEmail: reservation.listerEmail,
                purchasedPackaging: reservation.purchasedPackaging,
                purchasedDelivery: reservation.purchasedDelivery,
                purchasedSpace: reservation.purchasedSpace,
                startDate: reservation.startDate,
                endDate: reservation.endDate,
                purchaseDetails: reservation.purchaseDetails
              }
              this.createReservation(newReservation)
            }
            if (reservation.customerEmail && reservation.listerEmail) {
              this.nextStepsEmail(reservation.customerEmail, reservation.listerEmail, listingToReserveId, reservation.purchasedDelivery);
            }
          }
        }
        return true;
      } catch (error) {
        console.log("error", error);
        return false;
      }
  }

  async set() {
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.updateUserAttributes(user, {
        'custom:isVerified': '',
    });
    console.log("set result", result);
  }

  async clearUser() {
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.updateUserAttributes(user, {
        'custom:reservationId': "",
        'custom:listingId': "",
        'custom:venmoHandle': "",
        'custom:isSubscribedToEmails': ""
    });
  }



  render() {
    this.isLoggedIn();
    //this.set();
    //this.clearUser();
    if (this.state.user == null || this.state.updatedUser) {
      return (
      <div>
        <br></br>
        <br></br>
        <br></br>
        <CircularProgress style={{color: "#fdad16"}}/>
      </div>);
    }

    let loadingView = (
      <div>
        <img src={loadingReservationImage} style={{width: "50%", marginTop: "5%"}}></img>
        <h5 class="card-title" style={{color: "#43495e"}}>Loading Your Reservation...</h5>
        <CircularProgress style={{color: "#fdad16"}}/>
      </div>
    );

    var isHeightSet = this.state.howItWorksLabel1 != -1 && this.state.howItWorksLabel2 != -1 && this.state.howItWorksLabel3 != -1 && this.state.howItWorksLabel4 != -1;
    const payments = this.state.user.stripeAccountId == "" ? <button onClick={this.showAccount}>Setup Payments</button>
    : <button onClick={this.showAccount}>Payments & Account</button>;
    const cards = (this.state.user.userListingId == "" && this.state.user.reservationId == "") ?
    (<Row>
      <Col>
        <div class="card text-left" style={{marginBottom: 50}}>
          <div class="card-body">
            <h5 class="card-title"><b>Looking for storage?</b></h5>
            <p style={{fontSize: 13+'px', position: "absolute"}} class="card-subtitle text-muted">Cheap, Safe, and Convenient</p>
            <p class="text-body" style={{paddingBottom: 0, marginTop: 40, width: "100%", fontSize: 16, textAlign: "left", height: this.state.showMoreCustomer ? 320 : 50, overflow: "hidden", border: "none", resize: "none", backgroundColor: "#ffffff00"}}>
              {
                "StoreStash is the Airbnb of storage. Store your items with other students" +
                "\n\nDrop Off" +
                "\nPurchase space for however many boxes you want to store. Check your email for your lister's contact info. Coordinate when to drop off your items." +
                "\n\nPick Up" +
                "\nIf you dont have bags or suitcases for your items, pick up packaging (location emailed to you). If you didn't purchase delivery, drop the items off with your lister."
              }
            </p>
            <button onClick={() => this.setState({showMoreCustomer : !this.state.showMoreCustomer})} style={{marginBottom: 30, paddingLeft: 2, fontSize: 16, backgroundColor: "#ffffff00", color: "grey", textAlign: "left", border: "none", resize: "none"}}>
              {this.state.showMoreCustomer ? "less..." : "more..."}
            </button>
            <br></br>
            <button onClick={() => this.props.history.push("/search")} className="home_onboard_customer">Get Started</button>
          </div>
        </div>
      </Col>
      <Col>
        <div class="card text-left" style={{marginBottom: 50}}>
          <div class="card-body">
            <h5 class="card-title"><b>Earn with StoreStash</b></h5>
            <p style={{fontSize: 13+'px', position: "absolute"}} class="card-subtitle text-muted">Make $$$ for each box you store</p>
            <p class="text-body" style={{paddingBottom: 0, color: "#555555", marginTop: 40, width: "100%", fontSize: 16, textAlign: "left", border: "none", height: this.state.showMoreLister ? 300 : 50, overflow: "hidden", resize: "none", backgroundColor: "#ffffff00"}}>
              {
                "Put your extra space to good use. Store boxes for other students and get paid." +
                "\n\nCreate a Listing" +
                "\nInclude the number of boxes you'll store and get matched with students! Make $165 for just storing 5 boxes in your closet." +
                "\n\nOptional Delivery" +
                "\nIncluding delivery on your listing to get more matches. Get paid $25 each time you deliver a box to a tech student."
              }
            </p>
            <button onClick={() => this.setState({showMoreLister : !this.state.showMoreLister})} style={{marginBottom: 30, paddingLeft: 2, fontSize: 16, backgroundColor: "#ffffff00", color: "grey", textAlign: "left", border: "none", resize: "none"}}>
              {this.state.showMoreLister ? "less..." : "more..."}
            </button>
            <br></br>
            <button onClick={() => this.props.history.push("/new_listing/empty")} className="home_onboard_lister">List Your Space</button>
          </div>
        </div>
      </Col>
    </Row>)

    :

    (<Row>
      {this.state.user.reservationId != "" && <Col>
        <div class="card text-left" styles={{width: "18rem"}}>
          <div class="card-body">
            <h5 class="card-title">Your Reservation</h5>
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
              <div style={{width: "2%", marginTop: "3%"}}>
                <div class="vertical-line" style={{height: "96%"}}></div>
                  <div onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 1 ? this.state.howItWorksStage - 1 : 1})} style={{cursor: "pointer", position: "absolute", width: 20, height: 20, backgroundColor: "lightgrey", marginTop: "2%", borderRadius: 10, marginLeft: -8}}>
                    <div style={{position: "absolute", width: 14, height: 14, backgroundColor: this.state.howItWorksStage < 1 ? "lightgrey" : "#fdad16", marginTop: 3, marginLeft: 3, borderRadius: 7}}></div>
                  </div>

                  <div onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 2 ? this.state.howItWorksStage - 1 : 2})} style={{cursor: "pointer", position: "absolute", width: 20, height: 20, backgroundColor: "lightgrey", marginTop: isHeightSet ? (6 + this.state.howItWorksLabel2 - this.state.howItWorksLabel1) : "23%", borderRadius: 10, marginLeft: -8}}>
                    <div style={{position: "absolute", width: 14, height: 14, backgroundColor: this.state.howItWorksStage < 2 ? "lightgrey" : "#fdad16", marginTop: 3, marginLeft: 3, borderRadius: 7}}></div>
                  </div>

                  <div onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 3 ? this.state.howItWorksStage - 1 : 3})} style={{cursor: "pointer", position: "absolute", width: 20, height: 20, backgroundColor: "lightgrey", marginTop: isHeightSet ? (6 + this.state.howItWorksLabel3 - this.state.howItWorksLabel1) : "44%", borderRadius: 10, marginLeft: -8}}>
                    <div style={{position: "absolute", width: 14, height: 14, backgroundColor: this.state.howItWorksStage < 3 ? "lightgrey" : "#fdad16", marginTop: 3, marginLeft: 3, borderRadius: 7}}></div>
                  </div>

                  <div onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 4 ? this.state.howItWorksStage - 1 : 4})} style={{cursor: "pointer", position: "absolute", width: 20, height: 20, backgroundColor: "lightgrey", marginTop: isHeightSet ? (6 + this.state.howItWorksLabel4 - this.state.howItWorksLabel1) : "66%", borderRadius: 10, marginLeft: -8}}>
                    <div style={{position: "absolute", width: 14, height: 14, backgroundColor: this.state.howItWorksStage < 4 ? "lightgrey" : "#fdad16", marginTop: 3, marginLeft: 3, borderRadius: 7}}></div>
                  </div>
              </div>

              <div style={{width: "95%"}}>
                <p onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 1 ? this.state.howItWorksStage - 1 : 1})} ref={element => { if (!element) return; if (this.state.howItWorksLabel1 != element.getBoundingClientRect().top) this.setState({howItWorksLabel1: element.getBoundingClientRect().top}) }} class="mt-3 mb-1" style={{fontSize: 18, width: "max-content", cursor: "pointer", textAlign: "left", color: this.state.howItWorksStage == 1 ? "black" : "lightgrey"}}>
                  <b>1) Reserve</b> Storage Space
                </p>
                <ul hidden={this.state.howItWorksStage < 1} style={{fontSize: 16, textAlign: "left", color: this.state.howItWorksStage == 1 ? "black" : "lightgrey"}}>
                  <li> - Choose the timeline & items for your reservation.</li>
                  <li> - Select a payment method and reserve your space.</li>
                </ul>
                <p onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 2 ? this.state.howItWorksStage - 1 : 2})} ref={element => { if (!element) return; if (this.state.howItWorksLabel2 != element.getBoundingClientRect().top) this.setState({howItWorksLabel2: element.getBoundingClientRect().top}) }} class="mt-3 mb-1" style={{fontSize: 18, width: "max-content", cursor: "pointer", textAlign: "left", color: this.state.howItWorksStage == 2 ? "black" : "lightgrey"}}>
                  <b>2) Coordinate</b> With Your Lister
                </p>
                <ul hidden={this.state.howItWorksStage < 2} style={{fontSize: 16, textAlign: "left", color: this.state.howItWorksStage == 2 ? "black" : "lightgrey"}}>
                  <li> - Check your email/spam for your lister's contact info.</li>
                  <li> - Coordinate when to drop off your items.</li>
                </ul>
                <p onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 3 ? this.state.howItWorksStage - 1 : 3})} ref={element => { if (!element) return; if (this.state.howItWorksLabel3 != element.getBoundingClientRect().top) this.setState({howItWorksLabel3: element.getBoundingClientRect().top}) }} class="mt-3 mb-1" style={{fontSize: 18, width: "max-content", cursor: "pointer", textAlign: "left", color: this.state.howItWorksStage == 3 ? "black" : "lightgrey"}}>
                  <b>3) Drop Off</b> Your Items
                </p>
                <ul  hidden={this.state.howItWorksStage < 3} style={{fontSize: 16, textAlign: "left", color: this.state.howItWorksStage == 3 ? "black" : "lightgrey"}}>
                  <li>{"Bring your items to " + "listing.residence_id"}</li>
                  <li>(or choose a listing with delivery...)</li>
                </ul>
                <p onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 4 ? this.state.howItWorksStage - 1 : 4})} ref={element => { if (!element) return; if (this.state.howItWorksLabel4 != element.getBoundingClientRect().top) this.setState({howItWorksLabel4: element.getBoundingClientRect().top}) }} class="mt-3 mb-1" style={{fontSize: 18, width: "max-content", cursor: "pointer", textAlign: "left", color: this.state.howItWorksStage == 4 ? "black" : "lightgrey"}}>
                  <b>4) Pick Up</b> Your Items
                </p>
                <ul hidden={this.state.howItWorksStage < 4} style={{fontSize: 16, textAlign: "left", color: this.state.howItWorksStage == 4 ? "black" : "lightgrey"}}>
                  <li>{"Get your items from " + "listing.residence_id"}</li>
                  <li>(or choose a listing with delivery...)</li>
                </ul>
              </div>
            </div>
            <button style={{marginTop: 20}} onClick={() => this.profileAction("purchasedSpace")} className="profile_primary_link">View</button>
          </div>
        </div>
      </Col>}
      {this.state.user.userListingId != "" && <Col>
        <div class="card text-left" styles={{width: "18rem"}}>
          <div class="card-body">
            <h5 class="card-title">Your Space</h5>
            <p style={{fontSize: 13+'px', position: "absolute"}} class="card-subtitle text-muted">None Published</p>
            <p  style={{paddingTop: 20}} class="card-text">View and edit your listing</p>
            <button onClick={() => this.profileAction("yourListing")} className="profile_primary_link" style={{float: "left"}}>Edit</button>
          </div>
        </div>
      </Col>}

      {this.state.user.userListingId != "" && <Col>
        <div class="card text-left" styles={{width: "18rem"}}>
          <div class="card-body">
            <h5 class="card-title">Payments & Account</h5>
            <p style={{fontSize: 13+'px', position: "absolute"}} class="card-subtitle text-muted">Pending Setup</p>
            <p  style={{paddingTop: 20}} class="card-text">View your payments</p>
            <button onClick={this.showAccount} className="profile_primary_link">Update</button>
          </div>
        </div>
      </Col>}
    </Row>);

    return (
      <div class={"container-xl px-3 px-md-4 px-lg-5 mt-4"}>
      {this.state.alertDetails != null &&
        <AlertDialog
          title={this.state.alertDetails.title}
          description={this.state.alertDetails.description}
          open={this.state.alertDetails.open}
          extraButtonTitle={"Logout"}
          extraButtonAction={this.logOutAction}
          onClose={() => this.setState({alertDetails: null})}
        />
      }
      {this.state.showContactUs && <ContactUs closeModal={this.closeModal} customerEmail={this.state.user == null ? "" : this.state.user.email} showContactUs={true}></ContactUs>}
      <div class="gutter-condensed gutter-lg flex-column flex-md-row d-flex">
        <div class={this.state.size < 3 ? "flex-shrink-0 col-12 col-md-3 mb-4 mb-md-0" : "flex-shrink-0 col-12 col-md-3 mb-4 mb-md-0 mt-5"}>
          <button hidden className="button_empty"><img alt="" width="260" height="260" class="avatar border color-bg-primary" src="https://avatars.githubusercontent.com/u/44826293?v=4"></img></button>
          <h4 style={{color: "#43495e"}} class={this.state.size < 3 ? "text-left" : "text-left mt-5"}>{this.state.user.username}</h4>
          <ul>
            <p className="float-left" style={{color: "#aaaaaa"}}>{utils.getCurrentSchoolAttribute(this.state.user.email, "name")}</p>
            <br></br>
            <hr style={{backgroundColor: "#cccccc"}}></hr>
              <div className="float-left">
                <li hidden={this.state.user.reservationId == ""}><button onClick={() => this.profileAction("purchasedSpace")} className="button_empty" style={{float: "left", marginLeft: -5}}>Purchased Space</button></li>
                <li hidden={this.state.user.userListingId == ""}><button onClick={() => this.profileAction("yourListing")} className="button_empty" style={{float: "left", marginLeft: -5}}>Your Listing</button></li>
                <li hidden={this.state.user.userListingId == ""}><button onClick={() => this.profileAction("paymentHistory")} className="button_empty" style={{float: "left", marginLeft: -5}}>Payment History</button></li>
                <li> <button onClick={() => this.profileAction("contactUs")} className="button_empty" style={{float: "left", marginLeft: -5}}>Contact Us</button></li>
                <li><button hidden={!this.state.user.isMarketer && !this.state.user.isAdmin} onClick={() => this.profileAction("admin")} className="button_empty" style={{float: "left", marginLeft: -5}}>Admin Dashboard</button></li>
                <li><button onClick={() => this.profileAction("logout")} className="button_empty" style={{float: "left", marginLeft: -5}}>Log Out</button></li>
              </div>
          </ul>
        </div>

        <div class="flex-shrink-0 col-12 col-md-9 mb-4 mb-md-0">
          <div class="mt-4">
            <div class="container-lg">
              {this.state.isLoadingReservation ? loadingView :
                <div><div class="text-center">
                  <img hidden={this.state.size < 3} className="logo250" src={logoImage}></img>
                  <p hidden={this.state.size < 3 || this.state.user.userListingId || this.state.user.reservationId} class="brand-registration mb-2">{"Get started with StoreStash"}</p>
                </div>
                {cards}
                </div>
              }
            </div>
          </div>
        </div>

      </div>
    </div>);
  }
}

export default Profile;
