import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const ListingsPagination = ({postsPerPage, totalPosts, paginate}) => {

    const pageNumbers = [];
    const useStyles = makeStyles(() => ({
      ul: {
        "& .Mui-selected": {
          color: "black",
          backgroundColor: "#fedea2"
        }
      }
    }));
    const classes = useStyles();

    for(let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <Pagination classes={{ ul: classes.ul }} count={pageNumbers.length} color="#fdad16" onChange={(event, value)=> paginate(value)}/>
    )
}

export default ListingsPagination;
