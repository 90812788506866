import React, { Component } from 'react';
import verifiedBadge from '.././assets/verified_badge.png';
import * as constants from '../constants.js';


class ListingView extends Component {

  constructor(props) {
    super(props);
    this.handleImageAction = this.handleImageAction.bind(this);
  }

  handleImageAction(emptyListing) {
    if (emptyListing) {
      return alert("Empty listing");
    }
    this.props.showDetails(this.props.listing);
  }

  render() {
    let listing = this.props.listing;
    let mobile = this.props.mobile;
    let windowWidth = this.props.windowWidth;
    const legacySpaceReserved = false;
    var spaceReserved = 0;
    if (listing.reservations) {
      let reservations = listing.reservations.items;
      if (reservations || !reservations[i].purchaseDetails) {
        for (var i = 0; i < reservations.length; i++) {
          if (legacySpaceReserved) {
            spaceReserved += reservations[i].purchasedSpace;
          } else {
            try {
              let purchaseDetails = JSON.parse(reservations[i].purchaseDetails);
              if (purchaseDetails) {
                for (var j = 0; j < purchaseDetails.items.length; j++) {
                  let currentItem = purchaseDetails.items[j];
                  spaceReserved += (constants.ITEM_QUANTITY_DICT[currentItem.name] ? constants.ITEM_QUANTITY_DICT[currentItem.name] * currentItem.quantity : 1 * currentItem.quantity)
                }
              }
            } catch (error) {
              spaceReserved += reservations[i].purchasedSpace;
            }
          }
        }
      }
    }
    var inactive = false;
    let currentTime = new Date();
    if (new Date(listing.expirationDate) < currentTime || listing.isMocked || listing.capacity == spaceReserved) {
      inactive = true;
    }

    if (inactive) {
      return (
        <React.Fragment>
            <br></br>
            <div style={{textAlign: "left", marginLeft: !mobile ? windowWidth * 0.1 : 40, marginRight: !mobile ? 80 : 40}}>
              <div className="img_container">
                <img style={{opacity: 0.3, cursor: "pointer"}} src={listing.img_url} className="img_storage" onClick={()=> this.props.handleUnavailableListingModal()}></img>
              </div>
                <div class="unit-title-info" style={{fontSize: mobile ? 15 : null, textAlign: "left", marginLeft: 20, paddingTop: 10, display: "flex", justifyContent: "space-between", marginRight: 25}}>
                  <h5 class="mb-0 ml-1" style={{ color: "grey", fontSize: 17 }}><b>{listing.residence_id}</b></h5>
                  <img hidden={!listing.lister || listing.lister.isVerified == null} style={{width: 25, height: 25, marginTop: -5}} src={verifiedBadge}></img>
                </div>
              <div class="row" style={{ fontSize: mobile ? 12 : null, color: "grey", display: "flex", justifyContent: "space-between", marginLeft: 25, marginRight: 25}}>
                <p> {"Available Space: " + "Available Space: " + (listing.isMocked ? 0 : listing.capacity - spaceReserved) + "/" + this.props.listing.capacity} </p>
                <p> {this.props.listing.firstName} </p>
              </div>
            </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
          <br></br>
          <div style={{textAlign: "left", marginLeft: !mobile ? windowWidth * 0.1 : 40, marginRight: !mobile ? 80 : 40}}>
            <div className="img_container zoom">
              <img style={{cursor: "pointer"}} src={listing.img_url} className="img_storage" onClick={()=> this.handleImageAction(listing.capacity == spaceReserved)}></img>
            </div>
              <div class="unit-title-info" style={{fontSize: mobile ? 15 : null, textAlign: "left", marginLeft: 20, paddingTop: 10, display: "flex", justifyContent: "space-between", marginRight: 25}}>
                <h5 class="mb-0 ml-1" style={{color: listing.capacity == spaceReserved ? "grey" : "black", fontSize: 17 }}><b>{listing.residence_id}</b></h5>
                <img hidden={!listing.lister || listing.lister.isVerified == null} style={{width: 25, height: 25, marginTop: -5}} src={verifiedBadge}></img>
              </div>
            <div class="row" style={{ fontSize: mobile ? 12 : null, color: listing.capacity == spaceReserved ? "grey" : "black", display: "flex", justifyContent: "space-between", marginLeft: 25, marginRight: 25}}>
              <p> {"Available Space: " + (listing.capacity - spaceReserved) + "/" + this.props.listing.capacity} </p>
              <p> {this.props.listing.firstName} </p>
            </div>
          </div>
      </React.Fragment>
    );
  }
}

export default ListingView;
