export function validateSignUp(email, password, confirm, emailExtensions) {
  let isValid = true;
  let errors = {};

  if (!email) {
    isValid = false;
    errors["email"] = "Please enter your email address.";
  }

  if (typeof email !== "undefined") {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(email)) {
      isValid = false;
      errors["email"] = "Please enter a valid email address.";
    }
    for (var i = 0; i < emailExtensions.length; i++) {
      const currentExtension = emailExtensions[i];
      // Check if email has gatech.edu because instead of matching with @gatech.edu
      // because some emails have @buzz.gatech.edu
      if (email.includes(currentExtension.substring(1))) {
        isValid = true;
        break;
      } else {
        isValid = false;
      }
    }
    if (!isValid) {
      errors["email"] = "You must enter your school email";
    }
  }

  if (!password) {
    isValid = false;
    errors["password"] = "Please enter a password";
  }

  if (!confirm) {
    isValid = false;
    errors["confirm_password"] = "Please confirm your password";
  }

  if (typeof password !== "undefined" && typeof confirm !== "undefined") {
    if (password != confirm) {
      isValid = false;
      errors["confirm"] = "The passwords do not match";
    }
  }
  return { isValid: isValid, errors: errors }
}
