import React, { Component } from 'react';
import { Modal, Button, FormControl, InputGroup, Form } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import Amplify, { API } from 'aws-amplify'

// Material UI
import { CircularProgress } from '@material-ui/core';

class ContactUs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      emailText: "",
      customerEmail: this.props.customerEmail,
      showContactUs: this.props.showContactUs,
      loadingSend: false,
      showEmailTextfield: this.props.customerEmail == ""
    }
    this.sendContactUsEmail = this.sendContactUsEmail.bind(this);
    this.textChanged = this.textChanged.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      showContactUs: props.showContactUs,
      customerEmail: props.customerEmail
    });
  }

  textChanged(event) {
    this.setState({ customerEmail: event.target.value});
  }

  async sendContactUsEmail() {
    if (this.state.customerEmail == "") {
      alert("You need to enter an email");
      return
    }
    const body = {
      customerEmail: this.state.customerEmail,
      reason: "inquiry",
      priority: 2,
      text: this.state.emailText
    }
    if (this.state.emailText != "") {
      this.setState({loadingSend: true});
      const response = await API.post("ses", "/contact-us", { body });
    }
    this.setState({loadingSend: false, showContactUs: false});
  }

  render() {
    return (
      <Modal centered show={this.state.showContactUs} backdrop="static" keyboard={false} onHide={() => this.props.closeModal()} align="center">
          <Modal.Header closeButton>
            <h5 style={{fontWeight: "bold", fontFamily:'glacialindifference-bold', fontSize: 24, color: "#43495e", marginLeft: "28%"}}>
              Contact StoreStash
            </h5>
          </Modal.Header>

          <form hidden={!this.state.showEmailTextfield} noValidate autoComplete="off">
            <TextField id="outlined-basic" onChange={(event) => this.setState({ customerEmail: event.target.value})} label="Your Email" variant="outlined" />
          </form>

          <InputGroup>
            <FormControl rows="5" onChange={(event) => this.setState({ emailText: event.target.value})} as="textarea" aria-label="With textarea" placeholder="Enter your message for our customer support team. We usually take 1-2 days to respond." />
          </InputGroup>

          {this.state.loadingSend && <div><CircularProgress style={{color: "#fdad16", marginTop:20}}/></div>}
          {this.state.loadingSend && <div align="center" style={{color: "#43495e"}}><b>Sending Message...</b></div>}

          <Modal.Footer style={{ display: "flex", justifyContent: "center"}}>
            <Button className="button_primary" onClick={this.sendContactUsEmail} style={{fontWeight: "bold", backgroundColor: "#fdad16", width: "40%", borderColor: "#ffffff00"}}>
              Submit
            </Button>
          </Modal.Footer>
      </Modal>
    )
  }

}

export default ContactUs;
