import React, { Component } from 'react';
import customerStory1 from '.././assets/customer_story/1_listings_that_fit_your_schedule.png'
import customerStory2 from '.././assets/customer_story/2_pack_your_things.png'
import customerStory3 from '.././assets/customer_story/3_drop_off_your_items.png'
import customerStory4 from '.././assets/customer_story/4_complimentary_insurance.png'
import customerStory5 from '.././assets/customer_story/5_pick_up_your_items.png'


class CarouselItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
        <div className="zoom" style={{maxWidth: "100%", marginLeft: "7%", marginRight: "7%"}}>
            <div>
              <img className="subtle-shadow" style={{maxWidth: 400, width: "100%", marginBottom: 30, marginTop: 30}} src={this.props.image}></img>
              <h4 style={{fontFamily:'glacialindifference-bold', position: "static", textAlign: "left", maxWidth: 400, fontSize: 25, color: "#43495e"}}>{this.props.title}</h4>
              <textarea disabled style={{width: "100%", height: 170, maxWidth: 400, backgroundColor: "#ffffff00", color: "#83848c !important", opacity: 0.65, fontSize: 20, rows: 5}} className="empty_textarea">{this.props.description}</textarea>
            </div>
        </div>
    );
  }
}

export default CarouselItem;
