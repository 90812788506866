import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import deliveredImage from '.././assets/delivered.png';
import Footer from './footer';
import { withRouter } from 'react-router';
import packagingImage from '.././assets/packaging.png';
import upperclassmenImage from '.././assets/home_upperclassmen.jpg'
import boxImage from '.././assets/home_box.jpg'
import deliveryImage from '.././assets/home_delivery.jpg'


const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    //backgroundImage: 'url(https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/assets/wallpaper.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class CalculateEarnings extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillMount() {
    //remove mobile rendering event listener
    window.removeEventListener("resize", this.updateDimensions);
  }

  constructor(props) {
    super(props);
    this.state = {
      checkedDelivery: false,
      howItWorksStage: 1,
      howItWorksLabel1: -1,
      howItWorksLabel2: -1,
      howItWorksLabel3: -1,
      howItWorksLabel4: -1,
      capacity: 5,
      size: 5,
    }
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleToggleDelivery = this.handleToggleDelivery.bind(this);
    this.handleCapacityChange = this.handleCapacityChange.bind(this);
  }

  updateDimensions = () => {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    var size = 0
    if (windowWidth >= 450) {
      size = 1
    }
    if (windowWidth >= 576) {
      size = 2
    }
    if (windowWidth >= 768) {
      size = 3
    }
    if (windowWidth >= 992) {
      size = 4
    }
    if (windowWidth >= 1200) {
      size = 5
    }
    this.setState({size: size});
  }

  handleToggleDelivery() {
    this.setState({checkedDelivery: !this.state.checkedDelivery})
  }

  handleCapacityChange(event) {
    if (event.target.value > 20) {
      return
    }
    this.setState({capacity: event.target.value});
  }

  render() {

    const { history } = this.props;
    const { classes } = this.props;

    const CustomCheckbox = withStyles({
      root: {
        color: "#fdad16",
        '&$checked': {
          color: "#fdad16",
        },
      },
      checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    var total = this.state.capacity * 34;
    total = this.state.checkedDelivery ? total + this.state.capacity * 25 : total
    //This transfer will only happen on a date that you're available and customers will coordinate a time that fits your schedule
    //If you offer delivery, you make an extra $25 for every customer who purchases it. You make an additional $5 for each extra box that customer stores with you. You are responsible for picking up and dropping off the boxes at the location of the customer.
    //StoreStash offers ways to boost your earnings. Earn more per customer when you share one of our posts to your social media following.

    const highlightTopGraphic = this.state.howItWorksStage == 1 || this.state.size <=3;
    const highlightMiddleGraphic = this.state.howItWorksStage == 2 || this.state.size <=3;
    const highlightBottomGraphic = this.state.howItWorksStage == 3 || this.state.size <=3;
    let middleSpan = (<span className={highlightMiddleGraphic ? "earnings-text" : ""} disabled rows={"5"} style={{opacity: this.state.howItWorksStage == 2 || this.state.size <=3 ? 1.0 : 0.4, display: "block", textAlign: this.state.size > 3 ? "left" : "center", marginTop: this.state.size > 3 ? 10 : 50, marginLeft: this.state.size > 3 ? "15%" : "10%", width: this.state.size > 3 ? "35%" : "80%", marginRight: this.state.size > 3 ? 30 : "10%", fontSize: 17, minHeight: this.state.size > 3 ? 160 : 200, border: "none", resize: "none", backgroundColor: "#ffffff", padding: 20, display: "inline-flex", alignItems: "center", borderRadius: 10, borderColor: "#E8E8E8", border: '1px solid', color: this.state.howItWorksStage ? "black" : "#43495e"}}>
      {
        "\n\nStoring is hassle free since customers will drop off and pick up their items with you. Your earnings are transfered 3 days after the pick up date of each reservation."
      }
    </span>);
    var isHeightSet = this.state.howItWorksLabel1 != -1 && this.state.howItWorksLabel2 != -1 && this.state.howItWorksLabel3 != -1 && this.state.howItWorksLabel4 != -1;

    var timeLineComponent = (
      <div style={{width: this.state.size > 3 ? "80%" : "90%", marginLeft: this.state.size > 3 ? "10%" : "5%", marginRight: this.state.size > 3 ? "10%" : "5%", display: "flex", justifyContent: "space-between"}}>
        <div style={{width: "2%", marginTop: "3%"}}>
          <div class="vertical-line" style={{height: "96%"}}></div>
            <div onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 1 ? this.state.howItWorksStage - 1 : 1})} style={{cursor: "pointer", position: "absolute", width: 20, height: 20, backgroundColor: "lightgrey", borderRadius: 10, marginLeft: -8}}>
              <div style={{position: "absolute", width: 14, height: 14, backgroundColor: this.state.howItWorksStage < 1 ? "lightgrey" : "#fdad16", marginTop: 3, marginLeft: 3, borderRadius: 7}}></div>
            </div>

            <div onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 2 ? this.state.howItWorksStage - 1 : 2})} style={{cursor: "pointer", position: "absolute", width: 20, height: 20, backgroundColor: "lightgrey", marginTop: isHeightSet ? (6 + this.state.howItWorksLabel2 - this.state.howItWorksLabel1) : "23%", borderRadius: 10, marginLeft: -8}}>
              <div style={{position: "absolute", width: 14, height: 14, backgroundColor: this.state.howItWorksStage < 2 ? "lightgrey" : "#fdad16", marginTop: 3, marginLeft: 3, borderRadius: 7}}></div>
            </div>

            <div onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 3 ? this.state.howItWorksStage - 1 : 3})} style={{cursor: "pointer", position: "absolute", width: 20, height: 20, backgroundColor: "lightgrey", marginTop: isHeightSet ? (6 + this.state.howItWorksLabel3 - this.state.howItWorksLabel1) : "44%", borderRadius: 10, marginLeft: -8}}>
              <div style={{position: "absolute", width: 14, height: 14, backgroundColor: this.state.howItWorksStage < 3 ? "lightgrey" : "#fdad16", marginTop: 3, marginLeft: 3, borderRadius: 7}}></div>
            </div>
        </div>

        <div style={{width: "95%"}}>
          <p onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 1 ? this.state.howItWorksStage - 1 : 1})} ref={element => { if (!element) return; if (this.state.howItWorksLabel1 != element.getBoundingClientRect().top) this.setState({howItWorksLabel1: element.getBoundingClientRect().top}) }} class="mt-3 mb-1" style={{fontSize: 18, width: "max-content", cursor: "pointer", textAlign: "left", color: this.state.howItWorksStage == 1 ? "black" : "lightgrey"}}>
            <b>1) List</b> Your Space
          </p>
          <ul hidden={this.state.howItWorksStage < 1} style={{fontSize: 16, textAlign: "left", color: this.state.howItWorksStage == 1 ? "black" : "lightgrey"}}>
            <li> - Join your campus community </li>
            <li> - Create your listing in seconds </li>
          </ul>
          <p onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 2 ? this.state.howItWorksStage - 1 : 2})} ref={element => { if (!element) return; if (this.state.howItWorksLabel2 != element.getBoundingClientRect().top) this.setState({howItWorksLabel2: element.getBoundingClientRect().top}) }} class="mt-3 mb-1" style={{fontSize: 18, width: "max-content", cursor: "pointer", textAlign: "left", color: this.state.howItWorksStage == 2 ? "black" : "lightgrey"}}>
            <b>2) Earn</b> Passively
          </p>
          <ul hidden={this.state.howItWorksStage < 2} style={{fontSize: 16, textAlign: "left", color: this.state.howItWorksStage == 2 ? "black" : "lightgrey"}}>
            <li> - $34 earnings for each item you store </li>
            <li> - Store solely for verified students from your school </li>
          </ul>
          <p onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 3 ? this.state.howItWorksStage - 1 : 3})} ref={element => { if (!element) return; if (this.state.howItWorksLabel3 != element.getBoundingClientRect().top) this.setState({howItWorksLabel3: element.getBoundingClientRect().top}) }} class="mt-3 mb-1" style={{fontSize: 18, width: "max-content", cursor: "pointer", textAlign: "left", color: this.state.howItWorksStage == 3 ? "black" : "lightgrey"}}>
            <b>3) Drive </b> Up Your Paycheck
          </p>
          <ul style={{fontSize: 16, textAlign: "left", color: this.state.howItWorksStage == 3 ? "black" : "lightgrey"}}>
              <li hidden={this.state.howItWorksStage < 3}> - $25 extra earnings for delivery </li>
              <li hidden={this.state.howItWorksStage < 3}> - $5 bonus for each additional item </li>
              <li hidden={this.state.howItWorksStage < 3}> - 10% extra earnings when you publicize your space </li>
          </ul>
          <p onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 4 ? this.state.howItWorksStage - 1 : 4})} ref={element => { if (!element) return; if (this.state.howItWorksLabel4 != element.getBoundingClientRect().top) this.setState({howItWorksLabel4: element.getBoundingClientRect().top}) }} class="mt-3 mb-1" style={{fontSize: 18, width: "max-content", cursor: "pointer", textAlign: "left", color: this.state.howItWorksStage == 4 ? "black" : "lightgrey"}}>
          </p>
        </div>
      </div>

    );
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            {this.state.size > 2 && timeLineComponent}
            {this.state.size > 2 && <hr style={{width: "80%", marginTop: 30, marginBottom: 30, border: "1px solid lightgrey"}}></hr>}


            <form className={classes.form} noValidate>
            {<div class="card earnings-text" style={{borderRadius: 12, width: this.state.size > 3 ? "80%" : "90%", marginLeft: this.state.size > 3 ? "10%" : "5%", marginRight: this.state.size > 3 ? "10%" : "5%"}}>
              <div class="card-body">
                <div class="form-label-group" align="left" style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                    <input style={{width: "60%", marginLeft: -5}} type='number' value={this.state.capacity == -1 ? null : this.state.capacity} class="form-control" placeholder="# of boxes (1-20)" required="" onChange={this.handleCapacityChange}></input>
                    <img className="img_icon" src={packagingImage} style={{marginTop: 8}}></img>
                </div>
                <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                  <FormControlLabel
                    control={<CustomCheckbox checked={this.state.checkedDelivery} onChange={this.handleToggleDelivery} name="checkedG" />}
                    label="Include Delivery"
                  />
                  <img className="img_icon" src={deliveredImage} style={{marginTop: 8}}></img>
                </div>
                <hr></hr>
                {this.state.checkedDelivery && <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                  <p style={{fontSize: 17}}>{"(" + this.state.capacity + "x)" + " Delivery"}</p>
                  <p style={{fontSize: 17}}>{"$" + (this.state.capacity * 25)}</p>
                </div>}
                <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                  <p style={{fontSize: 17}}>{"(" + this.state.capacity + "x)" + " Storage"}</p>
                  <p style={{fontSize: 17}}>{"$" + (this.state.capacity * 34)}</p>
                </div>
                <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                  <p style={{fontSize: 17}}><b>{"You Earn:"}</b></p>
                  <p style={{fontSize: 17}}><b>{"$" + total.toFixed(2)}</b></p>
                </div>
                <button className={"home_onboard_customer"} onClick={() => history.push("/new_listing/empty")} style={{width: "50%"}}> Earn Now </button>
              </div>
            </div>}
            </form>
            {this.state.size <= 2 && <hr style={{width: "90%", marginTop: 50, marginBottom: 20, border: "1px solid lightgrey"}}></hr>}
            {this.state.size <= 2 && timeLineComponent}

          </div>
        </Grid>
        <Grid item xs={false} sm={4} md={7}>
          {/* Top graphic */}
          <div style={{display: this.state.size > 3 ? "flex" : "", marginTop: 30, float: "left"}}>
            <div className="card text-left border-0" style={{opacity: highlightTopGraphic ? 1.0 : 0.4, marginTop: this.state.size > 3 ? 10 : 100, width: this.state.size > 3 ? "30%" : "80%", marginBottom: this.state.size > 3 ? 25 : 0, height: this.state.size > 3 ? 130 : 200, marginLeft: this.state.size > 3 ? "15%" : "10%"}}>
              <img className={highlightTopGraphic ? "home-fit-img" : "home-fit-no-shadow-img"} src={upperclassmenImage}></img>
              <p id="upperclassmen-text" className={highlightTopGraphic ? "noselect home-label" : "noselect"} style={{fontFamily:'glacialindifference-bold', lineHeight: "30px", paddingLeft: 3, paddingRight: 3, textAlign: "center", backgroundColor: highlightTopGraphic ? "#565b6e" : "#D8D8D8", color: "white", height: 50, borderRadius: "0px 0px 10px 10px"}}> Get started in seconds </p>
            </div>
            <span className={highlightTopGraphic ? "earnings-text" : ""} disabled rows={"5"} style={{opacity: highlightTopGraphic ? 1.0 : 0.4, display: "block", textAlign: this.state.size > 3 ? "left" : "center", marginTop: this.state.size > 3 ? 10 : 50, marginLeft: this.state.size > 3 ? 30 : "10%", width: this.state.size > 3 ? "35%" : "80%", marginRight: this.state.size > 3 ? 15 : "10%", fontSize: 17, border: "none", resize: "none", backgroundColor: "#ffffff", padding: 20, display: "inline-flex", alignItems: "center", borderRadius: 10, color: highlightTopGraphic ? "black" : "#43495e", borderColor: "#E8E8E8", border: '1px solid'}}>
              {
                "\n\nSign up, list your space, and start earning! Yes, it's that simple. All you need is a school email and some extra space. "
              }
            </span>
          </div>

          {/* Middle graphic */}
          <div style={{display: this.state.size > 3 ? "flex" : "", marginTop: 30, float: "left"}}>
            {this.state.size > 3 && middleSpan}
            <div class="card text-left border-0" style={{opacity: highlightMiddleGraphic ? 1.0 : 0.4, marginTop: this.state.size > 3 ? 10 : 100, width: this.state.size > 3 ? "30%" : "80%",marginLeft: this.state.size > 3 ? 0 : "10%", height: this.state.size > 3 ? 130 : 200}}>
                <img className={highlightMiddleGraphic ? "home-fit-img" : "home-fit-no-shadow-img"} src={boxImage}></img>
                <p id="upperclassmen-text" className={highlightMiddleGraphic ? "noselect home-label" : "noselect"} style={{fontFamily:'glacialindifference-bold', lineHeight: "30px", paddingLeft: 3, paddingRight: 3, textAlign: "center", backgroundColor: highlightMiddleGraphic ? "#565b6e" : "#D8D8D8", color: "white", height: 50, borderRadius: "0px 0px 10px 10px"}}>Store items for other college students</p>
            </div>
            {this.state.size <= 3 && middleSpan}
          </div>

          {/* Bottom graphic */}
          <div style={{display: this.state.size > 3 ? "flex" : "", marginTop: 30, float: "left"}}>
            <div class="card text-left border-0" style={{opacity: highlightBottomGraphic ? 1.0 : 0.4, marginTop: this.state.size > 3 ? 10 : 100, width: this.state.size > 3 ? "30%" : "80%", marginBottom: this.state.size > 3 ? 25 : 0, height: this.state.size > 3 ? 130 : 200, marginLeft: this.state.size > 3 ? "15%" : "10%"}}>
                <img className={highlightBottomGraphic ? "home-fit-img" : "home-fit-no-shadow-img"} src={deliveryImage}></img>
                <p id="upperclassmen-text" className={highlightBottomGraphic ? "noselect home-label" : "noselect"} style={{fontFamily:'glacialindifference-bold', lineHeight: "30px", paddingLeft: 3, paddingRight: 3, textAlign: "center", backgroundColor: highlightBottomGraphic ? "#565b6e" : "#D8D8D8", color: "white", height: 50, borderRadius: "0px 0px 10px 10px"}}>Store items with other students</p>
            </div>
            <span className={highlightBottomGraphic ? "earnings-text" : ""} disabled rows={"5"} style={{opacity: this.state.howItWorksStage == 3 || this.state.size <=3 ? 1.0 : 0.4, display: "block", textAlign: this.state.size > 3 ? "left" : "center", marginTop: this.state.size > 3 ? 10 : 50, marginBottom: this.state.size > 3 ? 0 : 100, marginLeft: this.state.size > 3 ? 30 : "10%", width: this.state.size > 3 ? "35%" : "80%", marginRight: this.state.size > 3 ? 15 : "10%", fontSize: 17, border: "none", resize: "none", minHeight: this.state.size > 3 ? 160 : 200, backgroundColor: "#ffffff", padding: 20, display: "inline-flex", alignItems: "center", marginBottom: 30, borderRadius: 10, borderColor: "#E8E8E8", border: '1px solid', color: highlightBottomGraphic ? "black" : "#43495e"}}>
              {
                "\n\nIf you have a car, offer delivery to make an extra $25 for every customer who buys it. You'll never have to drive more than 5 minutes away!"
              }
            </span>
          </div>
        </Grid>
        <Footer showAuthModal={this.props.showAuthModal} style="pricing-footer"></Footer>
      </Grid>

    );
  }
}
export default withStyles(styles, { withTheme: true })(CalculateEarnings);
