import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Container, Row, Col } from 'react-bootstrap';
import LazyLoad from "react-lazyload";

// Image Imports
import tipsImage from '.././assets/info_tips.png'
import verifiedImage from '.././assets/info_verified.png'
import earnMoreImage from '.././assets/info_earn_more.png'
import moreCustomers from '.././assets/info_more_customers.png'
import addImage from '.././assets/tips_add_image.png'
import verifiedEarnings from '.././assets/tips_earnings.png'
import customerStory1 from '.././assets/customer_story/1_listings_that_fit_your_schedule.png'
import listerImage from '.././assets/home_product_lister.png'
import packagingImage from '.././assets/tips_packaging.png'



class ProductInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      infoTiles:
        [
          { title: "Listing Tips", image: tipsImage, isSelected: false },
          { title: "Get Verified", image: verifiedImage, isSelected: true },
          { title: "Earn More", image: earnMoreImage, isSelected: false },
          /* { title: "Reach More Customers", image: moreCustomers, isSelected: false } */
        ],
      infoDetails: {
        "Listing Tips" : [
            { subtitle: "Add An Image", mobileHeight: 220, description: "Adding an image boosts the placement of your storage space on the listings feed. Use an image of your residence to help students know where their items will go", image: addImage},
            { subtitle: "Include Your Schedule", mobileHeight: 320, description: "We encourage listers to include the dates that they won't be able to take in or give back items. When customers book with you on a date that you're not in town, the reservation is handled by another lister and your listing loses ranking placement.", image: customerStory1}
        ],
        "Get Verified": [
            // Enter your phone number, Share our post on instagram, and https://calendly.com/storestash-jobs/5min
            { subtitle: "Simple Steps", mobileHeight: 160, description: "Verified listers earn on average 60% more on StoreStash because of higher booking volume. Get verified in 3 simple steps!", image: listerImage},
            { subtitle: "More Customers", mobileHeight: 260, description: "Once you're verified, we can refer prospective customers to your listing. We value earnings transparency for our listers, so we've built this table to help you track your payments in real time.", image: verifiedEarnings},
        ],
        "Earn More": [
            { subtitle: "Packaging Delivery", mobileHeight: 100, description: "Earn up to $200 extra with packaging delivery.", image: packagingImage},
            { subtitle: "More Customers", mobileHeight: 260, description: "Once you're verified, we can refer prospective customers to your listing. We value earnings transparency for our listers, so we've built this table to help you track your payments in real time.", image: verifiedEarnings},
        ]
      },
    }
    this.updateSelected = this.updateSelected.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillMount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    var size = 0
    if (windowWidth >= 450) {
      size = 1
    }
    if (windowWidth >= 576) {
      size = 2
    }
    if (windowWidth >= 768) {
      size = 3
    }
    if (windowWidth >= 992) {
      size = 4
    }
    if (windowWidth >= 1200) {
      size = 5
    }
    this.setState({windowWidth, size});
  }

  updateSelected(selectedIndex) {
    var currentTiles = this.state.infoTiles;
    for (var i = 0; i < currentTiles.length; i++) {
      currentTiles[i].isSelected = i == selectedIndex
    }
    this.setState({infoTiles: currentTiles});
  }

  render() {

    const infoTileButtons = this.state.infoTiles.map((infoTile, index) =>
      <button style={{marginLeft: index == 0 ? "5%" : "0", marginTop: 50, marginBottom: 50, minWidth: 100, minHeight: 100}} onClick={() => this.updateSelected(index)} id={infoTile.isSelected ? "selected" : ""} className="product_info_button">
        <img style={{width: this.state.size > 4 ? 60 : 35, height: this.state.size > 4 ? 60 : 35, marginTop: this.state.size > 4 ? "35%" : "0%"}} src={infoTile.image}></img>
        <h5 style={{fontSize: this.state.size > 4 ? 22 : 14,  color: infoTile.isSelected ? "#43495e" : "#aaaaaa", marginTop: infoTile.title.split(" ").length > 2 ? "15%" : "20%", marginBottom: 50}}>{infoTile.title}</h5>
      </button>
    );

    var divHeight = document.getElementById('textarea1')?.offsetHeight;
    console.log(divHeight, "height!!!");

    let selectedTiles = this.state.infoTiles.filter(function (infoTile) {
        return infoTile.isSelected == true;
    });
    let selectedTile = selectedTiles[0];
    let infoDetails1 = this.state.infoDetails[selectedTile.title][0];
    let infoDetails2 = this.state.infoDetails[selectedTile.title][1];

    const lowerView = <div style={{background: "ffffff00", height: "50vh"}}>
      <hr></hr>

      <Row style={{paddingLeft: "15%", paddingRight: "15%", textAlign: "left", marginTop: "3%"}}>
        <Col lg={4} className="noselect" style={{textAlign:"left", marginTop: "10%"}}>
          <h4 style={{fontFamily:'glacialindifference-bold', fontSize: 45, color: "#43495e", textAlign: this.state.size < 3 ? "center" : "left"}}>
            {infoDetails1.subtitle}
          </h4>
          <textarea id="textarea1" key={uuidv4()} disabled style={{width: "100%", height: infoDetails1.mobileHeight, textAlign: this.state.size < 3 ? "center" : "left", backgroundColor: "#ffffff00", color: "#83848c !important", opacity: 0.65, fontSize: 20, rows: 10, marginBottom: 50}} className="empty_textarea">{infoDetails1.description}</textarea>
        </Col>

        <Col className="noselect" style={{marginBottom: 50}}>
          <LazyLoad>
            <img className="home-product-image" loading="lazy" alt="Find nearby storage spaces with StoreStash listings" style={{width: "90%", marginTop: this.state.size < 4 ? "-20%" : 0, marginLeft: this.state.size < 3 ? "5%" : "0px"}} src={infoDetails1.image}></img>
          </LazyLoad>
        </Col>
      </Row>

      <Row style={{paddingLeft: "15%", paddingRight: "15%", textAlign: "left", marginTop: "3%"}}>
        {this.state.size >= 4 && <Col className="noselect">
          <img className="home-product-image" alt="Post your listing and earn money storing items for classmates" style={{width: "90%", marginBottom: 100}} src={infoDetails2.image}></img>
        </Col>}
        <Col lg={5} className="noselect" style={{textAlign:"left", marginTop: "10%"}}>
          <h4 style={{fontFamily:'glacialindifference-bold', fontSize: 45, color: "#43495e", textAlign: this.state.size < 3 ? "center" : "left"}}>
            {infoDetails2.subtitle}
          </h4>
          <textarea key={uuidv4()} disabled style={{width: "100%", height: infoDetails2.mobileHeight, textAlign: this.state.size < 3 ? "center" : "left", backgroundColor: "#ffffff00", color: "#83848c !important", opacity: 0.65, fontSize: 20, rows: 5}} className="empty_textarea">{this.state.infoDetails[selectedTile.title][1].description}</textarea>
        </Col>
        {this.state.size < 4 && <Col className="noselect">
          <img className="home-product-image" alt="Post your listing and earn money storing items for classmates" style={{width: "100%", marginTop: "-15%", marginBottom: 100}} src={infoDetails2.image}></img>
        </Col>}
      </Row>
    </div>

    return (
      <div style={{height: "60vh", background: `linear-gradient(to bottom, rgba(218, 223, 225, 1), rgba(255,255,255,255)`}}>
        {infoTileButtons}
        {lowerView}
      </div>
    )
  }

}

export default ProductInfo;
