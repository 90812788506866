import React, { Component } from 'react';
import { Button } from 'semantic-ui-react'
import semantic_button from 'semantic-ui-css/components/button.min.css'
import semantic_icon from 'semantic-ui-css/components/icon.min.css'

class SecondaryButton extends Component {

  render() {
    return (
      <Button content={this.props.label} icon={this.props.icon} labelPosition='left'
      style={{borderRadius: 50, fontFamily:'glacialindifference-bold'}} />
    )
  }
}

export default SecondaryButton;
