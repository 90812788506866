/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const syncListings = /* GraphQL */ `
  query SyncListings(
    $filter: ModelListingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncListings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        capacity
        firstName
        paymentIdentifier
        expirationDate
        residence_id
        listerEmail
        user_id
        school_id
        img_url
        message
        unavailableRanges
        includesDelivery
        createdAt
        isMocked
        reservations {
          items {
            id
            listing_id
            customer_id
            customerEmail
            listerEmail
            purchasedPackaging
            purchasedDelivery
            purchasedSpace
            startDate
            endDate
            createdAt
            purchaseDetails
            _version
            _deleted
            _lastChangedAt
            updatedAt
          }
          nextToken
          startedAt
        }
        lister {
          email
          listingId
          reservationId
          stripeAccountId
          venmoHandle
          isSubscribedToEmails
          isAdmin
          isMarketer
          isVerified
          profilePicUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getListing = /* GraphQL */ `
  query GetListing($id: ID!) {
    getListing(id: $id) {
      id
      type
      capacity
      firstName
      paymentIdentifier
      expirationDate
      residence_id
      listerEmail
      user_id
      school_id
      img_url
      message
      unavailableRanges
      includesDelivery
      createdAt
      isMocked
      reservations {
        items {
          id
          listing_id
          customer_id
          customerEmail
          listerEmail
          purchasedPackaging
          purchasedDelivery
          purchasedSpace
          startDate
          endDate
          createdAt
          purchaseDetails
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      lister {
        email
        listingId
        reservationId
        stripeAccountId
        venmoHandle
        isSubscribedToEmails
        isAdmin
        isMarketer
        isVerified
        profilePicUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listListings = /* GraphQL */ `
  query ListListings(
    $filter: ModelListingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listListings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        capacity
        firstName
        paymentIdentifier
        expirationDate
        residence_id
        listerEmail
        user_id
        school_id
        img_url
        message
        unavailableRanges
        includesDelivery
        createdAt
        isMocked
        reservations {
          items {
            id
            listing_id
            customer_id
            customerEmail
            listerEmail
            purchasedPackaging
            purchasedDelivery
            purchasedSpace
            startDate
            endDate
            createdAt
            purchaseDetails
            _version
            _deleted
            _lastChangedAt
            updatedAt
          }
          nextToken
          startedAt
        }
        lister {
          email
          listingId
          reservationId
          stripeAccountId
          venmoHandle
          isSubscribedToEmails
          isAdmin
          isMarketer
          isVerified
          profilePicUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVisits = /* GraphQL */ `
  query SyncVisits(
    $filter: ModelVisitFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVisits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        ip
        pageName
        countryName
        region
        city
        postal
        longitude
        latitude
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVisit = /* GraphQL */ `
  query GetVisit($ip: ID!, $pageName: String!) {
    getVisit(ip: $ip, pageName: $pageName) {
      ip
      pageName
      countryName
      region
      city
      postal
      longitude
      latitude
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listVisits = /* GraphQL */ `
  query ListVisits(
    $ip: ID
    $pageName: ModelStringKeyConditionInput
    $filter: ModelVisitFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVisits(
      ip: $ip
      pageName: $pageName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ip
        pageName
        countryName
        region
        city
        postal
        longitude
        latitude
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFailures = /* GraphQL */ `
  query SyncFailures(
    $filter: ModelFailureFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFailures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cause
        description
        priority
        impact
        functionName
        userEmail
        user {
          email
          listingId
          reservationId
          stripeAccountId
          venmoHandle
          isSubscribedToEmails
          isAdmin
          isMarketer
          isVerified
          profilePicUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFailure = /* GraphQL */ `
  query GetFailure($id: ID!) {
    getFailure(id: $id) {
      id
      cause
      description
      priority
      impact
      functionName
      userEmail
      user {
        email
        listingId
        reservationId
        stripeAccountId
        venmoHandle
        isSubscribedToEmails
        isAdmin
        isMarketer
        isVerified
        profilePicUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listFailures = /* GraphQL */ `
  query ListFailures(
    $id: ID
    $filter: ModelFailureFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFailures(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        cause
        description
        priority
        impact
        functionName
        userEmail
        user {
          email
          listingId
          reservationId
          stripeAccountId
          venmoHandle
          isSubscribedToEmails
          isAdmin
          isMarketer
          isVerified
          profilePicUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        email
        listingId
        reservationId
        stripeAccountId
        venmoHandle
        isSubscribedToEmails
        isAdmin
        isMarketer
        isVerified
        profilePicUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($email: ID!) {
    getUser(email: $email) {
      email
      listingId
      reservationId
      stripeAccountId
      venmoHandle
      isSubscribedToEmails
      isAdmin
      isMarketer
      isVerified
      profilePicUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $email: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        listingId
        reservationId
        stripeAccountId
        venmoHandle
        isSubscribedToEmails
        isAdmin
        isMarketer
        isVerified
        profilePicUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSchools = /* GraphQL */ `
  query SyncSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSchools(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        type
        emailExtension
        residences
        breakDateRanges
        acronym
        iconUrl
        packagingAvailable
        createdAt
        longitude
        latitude
        hex
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      name
      type
      emailExtension
      residences
      breakDateRanges
      acronym
      iconUrl
      packagingAvailable
      createdAt
      longitude
      latitude
      hex
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        emailExtension
        residences
        breakDateRanges
        acronym
        iconUrl
        packagingAvailable
        createdAt
        longitude
        latitude
        hex
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOutreaches = /* GraphQL */ `
  query SyncOutreaches(
    $filter: ModelOutreachFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOutreaches(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        marketerEmail
        toEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOutreach = /* GraphQL */ `
  query GetOutreach($id: ID!) {
    getOutreach(id: $id) {
      id
      type
      marketerEmail
      toEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOutreachs = /* GraphQL */ `
  query ListOutreachs(
    $id: ID
    $filter: ModelOutreachFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOutreachs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        marketerEmail
        toEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReservations = /* GraphQL */ `
  query SyncReservations(
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReservations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        listing_id
        customer_id
        customerEmail
        listerEmail
        purchasedPackaging
        purchasedDelivery
        purchasedSpace
        startDate
        endDate
        createdAt
        purchaseDetails
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReservation = /* GraphQL */ `
  query GetReservation($id: ID!) {
    getReservation(id: $id) {
      id
      listing_id
      customer_id
      customerEmail
      listerEmail
      purchasedPackaging
      purchasedDelivery
      purchasedSpace
      startDate
      endDate
      createdAt
      purchaseDetails
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listReservations = /* GraphQL */ `
  query ListReservations(
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        listing_id
        customer_id
        customerEmail
        listerEmail
        purchasedPackaging
        purchasedDelivery
        purchasedSpace
        startDate
        endDate
        createdAt
        purchaseDetails
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listingsByDate = /* GraphQL */ `
  query ListingsByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelListingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listingsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        capacity
        firstName
        paymentIdentifier
        expirationDate
        residence_id
        listerEmail
        user_id
        school_id
        img_url
        message
        unavailableRanges
        includesDelivery
        createdAt
        isMocked
        reservations {
          items {
            id
            listing_id
            customer_id
            customerEmail
            listerEmail
            purchasedPackaging
            purchasedDelivery
            purchasedSpace
            startDate
            endDate
            createdAt
            purchaseDetails
            _version
            _deleted
            _lastChangedAt
            updatedAt
          }
          nextToken
          startedAt
        }
        lister {
          email
          listingId
          reservationId
          stripeAccountId
          venmoHandle
          isSubscribedToEmails
          isAdmin
          isMarketer
          isVerified
          profilePicUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const schoolsByName = /* GraphQL */ `
  query SchoolsByName(
    $type: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schoolsByName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        emailExtension
        residences
        breakDateRanges
        acronym
        iconUrl
        packagingAvailable
        createdAt
        longitude
        latitude
        hex
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const byListing = /* GraphQL */ `
  query ByListing(
    $listing_id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byListing(
      listing_id: $listing_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listing_id
        customer_id
        customerEmail
        listerEmail
        purchasedPackaging
        purchasedDelivery
        purchasedSpace
        startDate
        endDate
        createdAt
        purchaseDetails
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
