import React, { useState } from 'react';
import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import './calendar.css';
import { getDay } from 'date-fns';
import { isSameDay } from 'date-fns';
import * as dateRange from '.././date_range.js';

function DateRangePickerExample(props) {
  var [startDate, setStartDate] = useState()
  var [endDate, setEndDate] = useState()

  var unavailableRanges = props.selectedUnavailability.map(unavailablility => unavailablility.value);
  let disabledDates = dateRange.getDatesFromRange(unavailableRanges);

  const modifiers = {
    disabled: date => disabledDates.some(selectedDate => isSameDay(selectedDate, date))
  }

  if (endDate && startDate) {
    let startString = format(startDate, 'yyyy-MM-dd', { locale: enGB })
    let endString = format(endDate, 'yyyy-MM-dd', { locale: enGB })
    let dateRange = startString + "+" + endString;
    var dateContained = false;
    for (var i = 0; i < unavailableRanges.length; i++) {
        if (unavailableRanges[i].includes(startString) || unavailableRanges[i].includes(endString)) {
          dateContained = true
        }
    }
    if (!unavailableRanges.includes(dateRange) && !dateContained) {
      props.handleNewUnavailabilityRange(dateRange);
    }
  }

  return (
    <div>
    <DateRangePicker
      startDate={startDate}
      endDate={endDate}
      onStartDateChange={setStartDate}
      onEndDateChange={setEndDate}
      minimumDate={new Date()}
      minimumLength={2}
      modifiers={modifiers}
      format='dd MMM yyyy'
      locale={enGB}
    >
      {({ startDateInputProps, endDateInputProps, focus }) => (
        <div className='date-range'>
          <input
            className={'input' + (focus === START_DATE ? ' -focused' : '')}
            {...startDateInputProps}
            placeholder='Start Date'
          />
          <span className='date-range_arrow' />
          <input
            className={'input' + (focus === END_DATE ? ' -focused' : '')}
            {...endDateInputProps}
            placeholder='End Date'
          />
        </div>
      )}
    </DateRangePicker>
    </div>
  )
}

export default DateRangePickerExample;
