import { Cache, Auth } from 'aws-amplify';

export async function setReservation(details) {
  Cache.setItem("reservation", JSON.stringify(details));
}

export function getReservation() {
  var currentReservation = Cache.getItem("reservation");
  if (!currentReservation) {
    // COMEBACK! Update with proper fields
    let reservationDetails = {
      isMocked: false,
      alreadyPurchased: false,
      includesDelivery: false,
      showFilters: false,
      loggedIn: false,
      listing_id : "",
      customer_id: "",
      customerEmail: "",
      purchasedPackaging: false,
      purchasedDelivery: false,
      purchasedSpace: 0,
      startDate : "",
      endDate: "",
      paymentType: "Venmo",
      purchaseDetails: "",
    }
    return reservationDetails;
  }
  return JSON.parse(currentReservation);
}

export async function removeReservationFromCurrentUser() {
  const user = await Auth.currentAuthenticatedUser();
  const result = await Auth.updateUserAttributes(user, {
      'custom:reservationId': ""
  });
  updateReservation("alreadyPurchased", false);
}

export function validReservation() {
  let currentReservation = JSON.parse(Cache.getItem("reservation"));
  console.log(currentReservation, "in validate", );
  if (currentReservation.isMocked) {
    return "This listing is no longer available. All of its space has been purchased."
  } else if (currentReservation.listerEmail.substr(0, currentReservation.listerEmail.indexOf('@')) == currentReservation.customer_id) {
   return "You cannot purchase space from yourself."
  } else if (!currentReservation.loggedIn) {
    return "Push Sign Up"
  } else if (currentReservation.startDate == "") {
    return "Choose a date to drop off your items"
  } else if (currentReservation.endDate == "") {
    return "Choose a date to pick up your items"
  } else if (currentReservation.alreadyPurchased) {
    return "You already purchased storge space. StoreStash currently only supports one reservation at a time"
  } else if (currentReservation.purchasedSpace == 0) {
    return "Choose an item you want to store. Don't forget to specify the quantity of each item you're storing."
  } else if (currentReservation.paymentType == "Venmo") {
    // Should always be last because success case!
    return "Venmo Payment Chosen"
  }
  return ""
}

export async function clearReservation() {
  var currentReservation = Cache.setItem("reservation", null);
}

export function initReservation() {
  let reservationDetails = {
    isMocked: false,
    alreadyPurchased: false,
    includesDelivery: false,
    showFilters: false,
    loggedIn: false,
    listing_id : "",
    customer_id: "",
    customerEmail: "",
    purchasedPackaging: false,
    purchasedDelivery: false,
    purchasedSpace: 0,
    startDate : "",
    endDate: "",
    paymentType: "Venmo",
    purchaseDetails: "",
  }
  let reservationJSON = JSON.stringify(reservationDetails)
  Cache.setItem("reservation", reservationJSON);
  return reservationJSON
}

export async function updateReservation(key, value) {
  var reservationJSON = Cache.getItem("reservation");
  if (!reservationJSON) {
    reservationJSON = await initReservation();
  }
  let reservation = JSON.parse(reservationJSON);
  reservation[key] = value;
  let updatedReservationJSON = JSON.stringify(reservation);
  Cache.setItem("reservation", updatedReservationJSON);
}
