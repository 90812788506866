import React, { Component } from 'react';
import ImageUpload from './image_upload';
import logoImage from '.././assets/logo.png';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import AlertDialog from './alert';
import { v4 as uuidv4 } from 'uuid';
import { createSchool } from '.././graphql/mutations';
import { Modal, Button, FormControl, InputGroup, Form } from 'react-bootstrap';

// Material UI
import { CircularProgress } from '@material-ui/core';
import { TextField } from '@material-ui/core';

class OnboardSchool extends Component {

  constructor(props) {
      super(props);
      this.state = {
        schoolName: "",
        emailExtension: "",
        fullName: "",
        acronym: "",
        chosenImageUrl: "",
        residences: ["","","","",""],
        showEnterPassword: false,
        loadingSubmit: false,
        isOnboarded: false,
      }
      this.handleAcronymChange = this.handleAcronymChange.bind(this);
      this.handleSchoolNameChange = this.handleSchoolNameChange.bind(this);
      this.handleFullNameChange = this.handleFullNameChange.bind(this);
      this.handleEmailExtensionChange = this.handleEmailExtensionChange.bind(this);
      this.handleImageUpload = this.handleImageUpload.bind(this);
      this.handleResidenceChange = this.handleResidenceChange.bind(this);
      this.createSchool = this.createSchool.bind(this);
      this.validateSchool = this.validateSchool.bind(this);
      this.submitCreateSchool = this.submitCreateSchool.bind(this);
  }

  handleImageUpload = async (chosenFile) => {
    if (this.state.acronym == "") {
      this.setState({alertDetails:
        {
          title: "Upload Notice",
          description: 'You must enter an acronym before uploading your school logo.',
          open: true
        }
      });
      return
    } else if (this.state.acronym.length > 5) {
      this.setState({alertDetails:
        {
          title: "Upload Notice",
          description: 'You must enter an acronym that is less than 6 characters before uploading your school logo.',
          open: true
        }
      });
      return
    }

    this.setState({file: chosenFile})
    let reader = new FileReader()
    reader.onload = async (e) => {
      if (e.target.result.length > 3000000) {
        this.setState({alertDetails:
          {
            title: "Upload Notice",
            description: 'Image is loo large - 3Mb maximum',
            open: true
          }
        })
        return
      }
      const body = {
        imageString: e.target.result.split('base64,')[1],
        fileName: this.state.acronym.toLowerCase() + "-logo"
      }
      const response = await API.post("s3Api", "/upload-school-logo", { body });
      if (response["success"]) {
        this.setState({chosenImageUrl: response["location"], loadingImage: false})
      }
    }
    reader.readAsDataURL(chosenFile);
  }

  handleEmailExtensionChange(event) {
    this.setState({ emailExtension: event.target.value });
  }

  async handleFullNameChange(event) {
    this.setState({ fullName: event.target.value });
  }

  handleSchoolNameChange(event) {
    this.setState({ schoolName: event.target.value });
  }

  handleAcronymChange(event) {
    this.setState({ acronym: event.target.value });
  }

  handleResidenceChange(index, text) {
    let residencesCopy = this.state.residences;
    residencesCopy[index] = text;
    this.setState({residences: residencesCopy});
  }

  createSchool() {
    let isValidSchool = this.validateSchool();
    if (isValidSchool) {
      this.setState({showEnterPassword: true});
    }
  }

  validateSchool() {
    try {
      if (this.state.schoolName.length >= 100) {
        this.setState({alertDetails: {
            title: "New School Notice",
            description: 'The school name you enter needs to less than 100 characters',
            open: true
          }
        });
        return false
      } else if (this.state.schoolName.length < 9) {
        this.setState({alertDetails: {
            title: "New School Notice",
            description: 'The official school name you enter needs to more than 8 characters',
            open: true
          }
        });
        return false
      } else if (this.state.residences.includes('')) {
        this.setState({alertDetails: {
            title: "New School Notice",
            description: 'One or more of the entered residences has been left empty',
            open: true
          }
        });
        return false
      } else if ([...new Set(this.state.residences)].length != 5) {
        this.setState({alertDetails: {
            title: "New School Notice",
            description: 'You cannot have duplicated residence names',
            open: true
          }
        });
        return
      } else if (this.state.acronym.length > 5) {
        this.setState({alertDetails: {
            title: "New School Notice",
            description: 'The acronym cannot be longer than 5 characters',
            open: true
          }
        });
        return false
      } else if (this.state.acronym.length < 2) {
        this.setState({alertDetails: {
            title: "New School Notice",
            description: 'The acronym cannot be shorter than 2 characters',
            open: true
          }
        });
        return false
      } else if (!this.state.emailExtension.includes(".edu")) {
        this.setState({alertDetails: {
            title: "New School Notice",
            description: "The email extension you entered does not have '.edu' at the end",
            open: true
          }
        });
        return false
      }
    } catch (error) {
      this.setState({alertDetails: {
          title: "New School Notice",
          description: error.message,
          open: true
        }
      });
      return false
    }
    return true
  }

  async submitCreateSchool() {
    // Read S3 file and check if password exists
    this.setState({loadingSubmit: true});
    if (!this.state.enteredPassword) {
      this.setState({alertDetails:
        {
          title: "New School Notice",
          description: 'You must enter a password.',
          open: true
        },
        loadingSubmit: false,
      });
      return
    }

    var body = {};
    const response = await API.post("s3Api", "/verify-marketer-password", { body });
    if (response.data) {

      let body = { schoolName: this.state.schoolName };
      const geocodeResponse = await API.post("locationApi", "/geocode-school-name", { body });
      if (geocodeResponse.success) {
        this.setState({lat: geocodeResponse.data.lat, long: geocodeResponse.data.long});
      }

      var passwords = response.data.split(/\r?\n/);
      if (passwords.length > 0 && passwords.includes(this.state.enteredPassword)) {

        var emailExtension = this.state.emailExtension.charAt(0) == '@' ? this.state.emailExtension.toLowerCase() : '@' + this.state.emailExtension.toLowerCase()
        // check for multiple periods

        let periodCount = emailExtension.split(".").length - 1;
        if (periodCount > 1) {
          // Format email extension @rams.colstate.edu -> @colstate.edu
          // @colstate.edu handles longer domains like @rams.colstate.edu.
          const secondToLastPeriodIndex = emailExtension.split('.', periodCount - 1).join('.').length + 1;
          emailExtension = "@" + emailExtension.substring(secondToLastPeriodIndex);
        }

        // Submit school logic here
        let schoolId = this.state.acronym.toLowerCase() + uuidv4().substring(this.state.acronym.length, 8);
        let breaks = {
          "Summer Break": "2022-05-08+2022-08-23",
          "Winter Break": "2022-12-17+2023-01-10",
          "Spring Break": "2023-03-14+2023-03-21"
        };
        let newSchool = {
          id: schoolId,
          type: "School",
          name: this.state.schoolName,
          emailExtension: emailExtension,
          residences: JSON.stringify({"residences": this.state.residences}),
          breakDateRanges: JSON.stringify(breaks),
          acronym: this.state.acronym,
          iconUrl: this.state.chosenImageUrl == "" ? "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/default-logo.png" : this.state.chosenImageUrl,
          packagingAvailable: false
        };
        if (this.state.lat && this.state.long) {
          newSchool["latitude"] = this.state.lat;
          newSchool["longitude"] = this.state.long;
        }
        try {
            await API.graphql(graphqlOperation(createSchool, {input: newSchool}));
            // On success remove the password from the textfile
            const index = passwords.indexOf(this.state.enteredPassword);
            const updatedFullName = this.state.fullName.replace(" ", "_");
            if (index > -1) {
              passwords[index] = updatedFullName + ":\t" + this.state.enteredPassword;
            }
            const body = { remainingPasswords : passwords.join("\n") };
            await API.post("s3Api", "/remove-intern-password", { body });
            this.setState({loadingSubmit: false, showEnterPassword: false, isOnboarded: true});
        } catch(error) {
          this.setState({alertDetails:
            {
              title: "New School Notice",
              description: error.message,
              open: true
            },
            loadingSubmit: false,
          });
        }

      } else {
        this.setState({alertDetails:
          {
            title: "New School Notice",
            description: 'The password you entered was incorrect. Please try again.',
            open: true
          },
          loadingSubmit: false,
        });
        return
      }
    } else {
      this.setState({alertDetails:
        {
          title: "New School Notice",
          description: 'The password you entered was incorrect. Please try again.',
          open: true
        },
        loadingSubmit: false,
      });
      return
    }
  }

  render() {
    let onboardedSchoolCheckmark = (
      <div hidden={!this.state.isOnboarded}>
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
        <h5>{"Success! Onboarded " + this.state.acronym}</h5>
      </div>);

    return (<div class="container mt-5">
      {/* Alert Modal */}
      {this.state.alertDetails != null &&
        <AlertDialog
          title={this.state.alertDetails.title}
          description={this.state.alertDetails.description}
          open={this.state.alertDetails.open}
          onClose={()=> this.setState({alertDetails: null})}
        />
      }

      {/* Enter Password Modal */}
      <Modal show={this.state.showEnterPassword} backdrop="static" keyboard={false} onHide={() => this.setState({showEnterPassword: false})} align="center">
          <Modal.Header closeButton>
            <h5 style={{fontWeight: "bold", fontFamily:'glacialindifference-bold', fontSize: 24, color: "#43495e", marginLeft: "28%"}}>
              Finish Onboarding
            </h5>
          </Modal.Header>

          <form noValidate autoComplete="off">
            <TextField id="outlined-basic" onChange={(event) => this.setState({ enteredPassword: event.target.value})} label="Password" variant="outlined" />
          </form>

          {this.state.loadingSubmit && <div><CircularProgress style={{color: "#fdad16", marginTop:20}}/></div>}
          {this.state.loadingSubmit && <div align="center" style={{color: "#43495e"}}><b>Verifying Password...</b></div>}

          <Modal.Footer style={{ display: "flex", justifyContent: "center"}}>
            <Button className="button_primary" onClick={this.submitCreateSchool} style={{fontWeight: "bold", backgroundColor: "#fdad16", width: "40%", borderColor: "#ffffff00"}}>
              Submit
            </Button>
          </Modal.Footer>
      </Modal>

      <div class="row mb-5">
        {/* Left Column */}
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style={{marginBottom: 25}}>
          <fieldset class="border p-2" styles={{borderRadius: 10}}>
            <legend class="w-auto"><h5 style={{fontSize:"25px"}}>{"Your School Details"}</h5></legend>

              <div class={"row"}>
                <div class="col" style={{marginBottom: this.state.size < 4 && 20}}>
                  <div class="form-label-group ml-2 mt-1">
                      <p align="left" style={{marginTop: -3, color: "#aaaaaa", fontSize: 13}}>School's official name</p>
                      <input style={{width: "100%", marginTop: -15, color: "black"}} class="form-control" value={this.state.schoolName} placeholder={"Georgia Institute of Techn..."} required="" onChange={this.handleSchoolNameChange}></input>
                  </div>
                  <div class="form-label-group ml-2 mt-4" style={{marginRight: 10}}>
                      <p align="left" style={{marginTop: -3, color: "#aaaaaa", fontSize: 13}}>Email Extension</p>
                      <input style={{width: "100%", marginTop: -15, color: "black"}} class="form-control" value={this.state.emailExtension} placeholder={"Email (i.e. @gatech.edu)"} required="" onChange={this.handleEmailExtensionChange}></input>
                  </div>
                  <div hidden={this.state.acronym == ""} style={{marginTop: 25, marginLeft: -30}}>
                    <p align="left" style={{color: "#aaaaaa", fontSize: 13, marginBottom: -2, marginLeft: 40}}>School Logo</p>
                    <ImageUpload textMarginTop={65} handleImageUpload={this.handleImageUpload} defaultImage={this.state.chosenImageUrl != "" ? this.state.chosenImageUrl : logoImage}></ImageUpload>
                  </div>
                </div>

                <div class="col" style={{marginBottom: this.state.size < 4 && 20}}>
                  <div class="form-label-group ml-2 mt-1" style={{marginRight: 10}}>
                      <p align="left" style={{marginTop: -3, color: "#aaaaaa", fontSize: 13}}>School's acronym</p>
                      <input style={{width: "100%", marginTop: -15, color: "black"}} class="form-control" value={this.state.acronym} placeholder={"Acronym (i.e. GT)"} required="" onChange={this.handleAcronymChange}></input>
                  </div>
                  <div class="form-label-group ml-2 mt-4" style={{marginRight: 10}}>
                      <p align="left" style={{marginTop: -3, color: "#aaaaaa", fontSize: 13}}>Your full name</p>
                      <input style={{width: "100%", marginTop: -15, color: "black"}} class="form-control" value={this.state.fullName} placeholder={"(Gabe Wilson)"} required="" onChange={this.handleFullNameChange}></input>
                  </div>
                  <div class="form-label-group ml-2 mt-4" hidden={this.state.acronym == ""} style={{marginRight: 10}}>
                      <p align="left" style={{marginTop: -3, color: "#aaaaaa", fontSize: 13}}>Off Campus Apartments</p>
                      <input style={{width: "100%", color: "black", marginTop: -15}} class="form-control" value={this.state.residences[0]} placeholder={"Paloma West Midtown..."} required="" onChange={(event) => this.handleResidenceChange(0, event.target.value)}></input>
                      <input style={{width: "100%", color: "black"}} class="form-control" value={this.state.residences[1]} placeholder={"Square on Fifth..."} required="" onChange={(event) => this.handleResidenceChange(1, event.target.value)}></input>
                      <input style={{width: "100%", color: "black"}} class="form-control" value={this.state.residences[2]} placeholder={"The Icon..."} required="" onChange={(event) => this.handleResidenceChange(2, event.target.value)}></input>
                      <input style={{width: "100%", color: "black"}} class="form-control" value={this.state.residences[3]} placeholder={"Residence 4..."} required="" onChange={(event) => this.handleResidenceChange(3, event.target.value)}></input>
                      <input style={{width: "100%", color: "black"}} class="form-control" value={this.state.residences[4]} placeholder={"Residence 5..."} required="" onChange={(event) => this.handleResidenceChange(4, event.target.value)}></input>
                  </div>
                </div>
              </div>
              {onboardedSchoolCheckmark}
              <button hidden={this.state.isOnboarded} className="home_onboard_customer" style={{marginTop: 30, marginBottom: 15}} onClick={this.createSchool}>Create School</button>
          </fieldset>
        </div>

        {/* Right Column */}
        <div class="col">
          <fieldset class="border p-2" styles={{borderRadius: 10}}>
            <legend class="w-auto"><h5 style={{fontSize:"25px"}}>{this.state.isOnboarded ? "Next Steps" : "How It Works"}</h5></legend>
            <div class={"row"} hidden={!this.state.isOnboarded}>
              <div class="form-label-group ml-2 mt-1">
                  <p align="left" style={{marginTop: -3, color: "#aaaaaa", fontSize: 13}}>Create your StoreStash account</p>
                  <p align="left" style={{marginTop: -15}}>In this step, you'll verify that your onboarding worked. Clicking the StoreStash at the top left of your screen will bring you to the home page. Find your school in the dropdown and sign up with your school email address. If you do not see your school, notify Gabe through Slack.</p>
              </div>
            </div>
            <div class={"row"} hidden={this.state.isOnboarded}>
              <div class="col" style={{marginBottom: this.state.size < 4 && 20}}>
                <div class="form-label-group ml-2 mt-1">
                    <p align="left" style={{marginTop: -3, color: "#aaaaaa", fontSize: 13}}>School's official name</p>
                    <p align="left" style={{marginTop: -15}}>Enter the official name of your school. Use wikipedia to confirm the spelling and capitalization</p>
                </div>
                <div class="form-label-group ml-2 mt-4" style={{marginRight: 10}}>
                    <p align="left" style={{marginTop: -3, color: "#aaaaaa", fontSize: 13}}>Email Extension</p>
                    <p align="left" style={{marginTop: -15}}>Enter the email extension used by members of your campus community. This should include the '@' character and the '.edu' suffix</p>
                </div>
                <div hidden={this.state.acronym == ""} style={{marginTop: 25, marginLeft: -30}}>
                  <p align="left" style={{color: "#aaaaaa", fontSize: 13, marginBottom: -2, marginLeft: 40}}>School Logo</p>
                  <p align="left" style={{marginLeft: 40}}>Upload your school logo. Please do a wikipedia search of your school and upload the logo that is directly under the bolded name of your school.</p>
                </div>
              </div>

              <div class="col" style={{marginBottom: this.state.size < 4 && 20}}>
                <div class="form-label-group ml-2 mt-1" style={{marginRight: 10}}>
                    <p align="left" style={{marginTop: -3, color: "#aaaaaa", fontSize: 13}}>School's acronym</p>
                    <p align="left" style={{marginTop: -15}}>Enter the acronym of your school.</p>
                </div>
                <div class="form-label-group ml-2 mt-4" style={{marginRight: 10}}>
                    <p align="left" style={{marginTop: -3, color: "#aaaaaa", fontSize: 13}}>Your full name</p>
                    <p align="left" style={{marginTop: -15}}>Input your full name. This will help StoreStash backend services' workflows.</p>
                </div>
                <div class="form-label-group ml-2 mt-4" hidden={this.state.acronym == ""} style={{marginRight: 10}}>
                    <p align="left" style={{marginTop: -3, color: "#aaaaaa", fontSize: 13}}>Off Campus Apartments</p>
                    <p align="left" style={{marginTop: -15}}>Enter the names of the 5 most popular off campus apartments at your school. The names you add will be accessible through a dropdown in the "Create Listing" workflow. If you can think of more apartments to add please use the contact us feature and email us the names of these apartments.</p>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>);
  }

}

export default OnboardSchool;
