import React from 'react'
import { CChartLine } from '@coreui/react-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils'
import { CircularProgress } from '@material-ui/core';

const brandSuccess = getStyle('success') || '#4dbd74'
const brandInfo = getStyle('info') || '#20a8d8'
const brandDanger = getStyle('danger') || '#f86c6b'

const MainChartExample = React.memo(attributes => {

  // render
  if (!attributes.trafficBySchool || !attributes.schoolColorsDict) {
    return <CircularProgress style={{color: "#43495e", marginTop: "30%", marginLeft: "45%"}}/>
  }

  const maxChartValue = (() => {
    var max = 1
    console.log(attributes.trafficBySchool, "traffic by school");
    for (const [key, value] of Object.entries(attributes.trafficBySchool)) {
      let traffic = attributes.trafficBySchool[key]
      let max_traffic_key = Object.keys(traffic).reduce((a, b) => traffic[a] > traffic[b] ? a : b);
      console.log(key, traffic[max_traffic_key], max_traffic_key);
      max = Math.max(traffic[max_traffic_key], max);
    }
    return (5 - max % 5) + max;
  })()

  const datelLabels = (() => {
    var dateLabels = new Array();
    var currentDate = (new Date()).addDays(-28);
    var stopDate = new Date();
    while (currentDate < stopDate) {
      let dateComponents = String(currentDate).split(" ")
      let dateLabel = dateComponents[1] + " " + dateComponents[2];
      dateLabels.push(dateLabel);
      currentDate = currentDate.addDays(1);
    }
    console.log(dateLabels);
    return dateLabels;
  })()

  const random = (min, max)=>{
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const defaultDatasets = (()=> {
    let elements = 28
    const data1 = []
    const data2 = []
    const data3 = []
    for (let i = 0; i <= elements; i++) {
      data1.push(random(50, 100))
      data2.push(random(80, 100))
      data3.push(65)
    }

    var allTraffic = [];

    if (attributes.trafficBySchool) {
      for (const [key, dataDict] of Object.entries(attributes.trafficBySchool)) {
        let schoolTraffic = {
          label: key,
          backgroundColor: hexToRgba(attributes.schoolColorsDict[key], 10),
          borderColor: attributes.schoolColorsDict[key],
          pointHoverBackgroundColor: attributes.schoolColorsDict[key],
          borderWidth: 2,
          data: Object.values(dataDict).reverse()
        }
        allTraffic.push(schoolTraffic);
      }
    }
    return allTraffic
  })()

  const defaultOptions = (()=>{
    return {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              stepSize: Math.ceil(250 / 5),
              max: maxChartValue
            },
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    }
  )()

  return (
    <CChartLine
      {...attributes}
      datasets={defaultDatasets}
      options={defaultOptions}
      labels={datelLabels}
    />
  )
}, (prevProps, nextProps) => {
  if (prevProps.trafficBySchool == nextProps.trafficBySchool) {
    return true;
  }
  return false;
});


export default MainChartExample
