// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Listing, Reservation, User, Visit, Failure, School, Outreach } = initSchema(schema);

export {
  Listing,
  Reservation,
  User,
  Visit,
  Failure,
  School,
  Outreach
};