import React, { Component } from 'react';
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import { CircularProgress } from '@material-ui/core';
import statusUpdating from '.././assets/status_updating.png'
import statusSuccess from '.././assets/status_success.png'
import FadeIn from 'react-fade-in';

class UpdateStatus extends Component {

  constructor(props) {
    super(props);
    this.state = {
      statusTitle: "",
      statusText: "Loading..."
    }
    this.queryListingFromURL = this.queryListingFromURL.bind(this);
  }

  componentWillMount() {
    this.queryListingFromURL()
  }

  async queryListingFromURL() {
    let fullUrl = window.location.href
    let filteredUrl = fullUrl.replace('?', '');
    let urlComponents = filteredUrl.split("/");
    let listingComponents = urlComponents[urlComponents.length - 1].split("_");

    let action = listingComponents[listingComponents.length - 1];
    if (action == "renew" || action == "update" || action == "expire") {
      let listingId = listingComponents[0]
      let userId = listingComponents[1]
      let hash = listingComponents[2]
      let expirationInteger = parseInt(listingComponents[3])

      let today = new Date()
      let currentExpirationDate = new Date(expirationInteger)
      let monthBeforeExpiration = new Date(expirationInteger)
      monthBeforeExpiration.setDate(monthBeforeExpiration.getDate() - 30)

      if (hash == (listingId + userId).hashCode()) { // listingId + userId

        let body = {
          listingId: listingId,
          action: action
        }

        if (action == "renew") {
          if (today < currentExpirationDate) { //
            this.setState({
              statusText: "Your listing cannot be renewed yet. You can renew your listing on " +
              monthBeforeExpiration.toString().substring(0,15) + ". (1 month before your listing's expiration date)",
              statusTitle: "Error"
            })
            return
          }

          const response = await API.post("dynamoApi", "/update-listing-expiration", { body });
          if (response["success"] !== undefined) {
            this.setState({
              statusText: "Your listing was renewed successfully! Thanks for storing with us.",
              statusTitle: "Success"
            })
          }
        } else if (action == "expire") {
          const response = await API.post("dynamoApi", "/update-listing-expiration", { body });
          if (response["success"] !== undefined) {
            this.setState({
              statusText: "Your listing was expired successfully! Thanks for storing with us.",
              statusTitle: "Success"
            })
          }
        } else if (action == "update") {
          this.props.history.push('/new_listing/edit')
        }
      }
    } else if (action == "unsubscribe") {
      let emailBinary = listingComponents[0]
      let email = Buffer.from(emailBinary, 'base64').toString('binary')
      let hash = listingComponents[1]

      if (hash != (email + "custom:isSubscribedToEmails").hashCode()) {
        this.setState({
          statusText: "This action couldn't be performed right now. Contact us at storestash.reserve@gmail.com if you think something went wrong.",
          statusTitle: "Notice"
        })
        return
      }

      let body = {
        attributeName: "custom:isSubscribedToEmails",
        updatedValue: "false",
        email: email,
        hash: hash
      }
      const response = await API.post("cognitoApi", "/update-user-attribute", { body });
      this.setState({
        statusText: "We're sad to see you go. You have been unsubscribed from StoreStash emails.",
        statusTitle: "Success"
      })
    }
  }

  render() {
    return (
      <div align="center" style={{marginTop: "5%"}}>
        <FadeIn>
          <img style={{width: "33%"}} src={this.state.statusText == "Loading..." ? statusUpdating : statusSuccess}></img>
          {this.state.statusText == "Loading..." && <div><CircularProgress style={{color: "#fdad16"}}/></div>}
          <h5>{this.state.statusTitle}</h5>
          <p style={{width: "33%"}}>{this.state.statusText}</p>
          <button class="button_secondary" onClick={()=> this.props.history.push("/search")}>Home Page</button>
        </FadeIn>
      </div>
    )
  }
}

export default UpdateStatus;
