import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Select from 'react-select';

// Image Imports
import schoolImage from '.././assets/school.png'
import searchBackground from '.././assets/search_background.png'


class SchoolDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      schoolOptions: [],
      schools: null,
      isMenuOpen: false,
    }
  }

  render() {
    console.log("IN DETAILS");

    const customStyles = {
      control: (base, state) => ({
        ...base,
        fontSize: "18px",
        fontFamily:'glacialindifference-bold',
        borderColor: '#ffffff00',
        backgroundColor: '#ffffff00',
        boxShadow: "none",
        "&:hover": {
          borderColor: '#ffffff00',
          cursor: 'pointer'
        }
      })
    };

    return (
      <div>
        <div className="gradient" style={{width: "100%", marginTop: -250, height: 800, position: "absolute", zIndex: 1}}>
        </div>
        <div className="search-background" style={{width: "100%", marginTop: -250, height: 800, position: "absolute", opacity: 0.35}}>
        </div>

        <h1 style={{marginTop: 250, width: "50%", marginLeft: "25%", marginRight: "25%", zIndex: 2, position: "relative"}}>Storage At Your College</h1>
        <h5 class="mb-5" style={{color: "#83848c", fontFamily: 'glacialindifference-regular', position: "relative"}} align={"center"}>Because campus is the most familiar storage unit</h5>
        <div style={{width: "30%", marginLeft: "35%", marginRight: "35%", borderColor: "#dddddd",
          backgroundColor: "#ffffff00", border: '1px solid', height: 50,
          borderRadius: 25, marginTop: 50,
          textAlign: "left", display: "flex", justifyContent: "space-between", zIndex: 2, position: "relative"}}>
          <b onClick={this.openMenu}><img class="school_logo" alt={this.state.chosenSchool == null ? "School Logo" : this.state.chosenSchool.label} style={{width: 35, height: 35, marginTop: 6, marginLeft: 10}} src={this.state.logoIcon != null ? this.state.logoIcon : schoolImage }></img></b>
          <div style={{width: "90%", zIndex: 2}}>
            <Select
              styles={customStyles}
              className="basic-single"
              classNamePrefix="select"
              placeholder={'Choose your school'}
              isSearchable={true}
              onFocus={this.openMenu}
              openMenuOnClick={true}
              closeMenuOnSelect={true}
              blurInputOnSelect={true}
              onBlur={this.closeMenu}
              options={this.state.schoolOptions}
              menuIsOpen={this.state.isMenuOpen}
              onChange={chosenSchool => this.schoolChosenAction(chosenSchool)}
              ref={this.selector}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SchoolDetails);
