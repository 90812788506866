import React, { Component } from 'react';
import "./footer.css";
import { withRouter } from 'react-router';
import ContactUs from './contact_us';

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showContactUs: false,
      windowWidth: 0,
      size: 5,
    }
    this.closeModal = this.closeModal.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    //mobile rendering event listener
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillMount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    var size = 0
    if (windowWidth >= 450) {
      size = 1
    }
    if (windowWidth >= 576) {
      size = 2
    }
    if (windowWidth >= 768) {
      size = 3
    }
    if (windowWidth >= 992) {
      size = 4
    }
    if (windowWidth >= 1200) {
      size = 5
    }
    this.setState({windowWidth, size});
  }

  closeModal() {
    this.setState({showContactUs: false})
  }

  render() {
    const { history } = this.props;
    return (
      <div className={this.props.style} align="left" style={{fontFamily:'glacialindifference-regular'}}>
        <hr></hr>
        {this.state.showContactUs && <ContactUs closeModal={this.closeModal} customerEmail={this.props.user == null ? "" : this.props.user.email} showContactUs={true}></ContactUs>}
        <div className="container">
          <div className="row">
            {/* Column1 */}
            <div className="col">
              <h4 style={{fontFamily:'glacialindifference-regular'}}>Customer</h4>
              <ul className="list-unstyled">
                <li><button className="button_empty" onClick={ () => this.props.showAuthModal('signUp')} style={{color:"#43495e", marginLeft: -5, marginBottom: 5}}>{this.state.size == 0 ? "Search" : "Sign up for storage"}</button></li>
                <li><button className="button_empty" onClick={ () => history.push('/pricing')} style={{color:"#43495e", marginLeft: -5}}>Pricing</button></li>
              </ul>
            </div>
            {/* Column2 */}
            <div className="col">
              <h4 style={{fontFamily:'glacialindifference-regular'}}>Lister</h4>
              <ul className="list-unstyled">
                <li><button className="button_empty" onClick={ () => history.push("/new_listing/empty")} style={{color:"#43495e", marginLeft: -5, marginBottom: 5}}>{this.state.size == 0 ? "List" : "Become a Lister"}</button></li>
                <li><button className="button_empty" onClick={ () => history.push('/calculate_earnings')} style={{color:"#43495e", marginLeft: -5}}>Earnings</button></li>
              </ul>
            </div>
            {/* Column3 */}
            <div className="col">
              <h4 style={{fontFamily:'glacialindifference-regular'}}>StoreStash</h4>
              <ul className="list-unstyled">
                <li><button className="button_empty" style={{color:"#43495e", marginLeft: -5, marginBottom: 5}}  onClick={() => this.setState({showContactUs: true})}>{"Contact Us"}</button></li>
                <li><button className="button_empty" style={{color:"#43495e", marginLeft: -5}}  onClick={() => history.push('/product_info')}>{"FAQ"}</button></li>
              </ul>
            </div>
          </div>
          <hr style={{backgroundColor: "#43495e"}}/>
            <div align="right">
              <p className="col-sm">
                &copy;{new Date().getFullYear()} StoreStash |<button className="button_empty" style={{color:"#43495e", marginTop: -18}} onClick={() => window.open("http://storestash-terms.s3-website-us-west-2.amazonaws.com/", "_blank")}>
                  Terms & Conditions
                </button>
              </p>
            </div>
        </div>
      </div>
    );
  }

}

export default withRouter(Footer);
