import React, { Component, useState, useEffect } from 'react';
import ListingView from '../components/listing'
import InformationView from '../components/information'
import ListingsPagination from '../components/pagination'
import { Listing } from '.././models';
import { DataStore, Predicates } from '@aws-amplify/datastore'
import Amplify, { API, graphqlOperation, Auth, Cache } from 'aws-amplify'
import { createListing, updateListing, deleteListing } from '.././graphql/mutations'
import { listListings, listingsByDate, listUsers } from '.././graphql/queries'
import { withRouter } from "react-router-dom";
import awsExports from ".././aws-exports";
import { v4 as uuidv4 } from 'uuid';
import FilterDatePickerCalendar from './date_picker';
import AlertDialog from './alert';
import Selector from './selector';
import Home from './home';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import * as reservationCache from './reservation_cache.js';
import * as utils from '.././utils.js';
import * as validator from '.././validate.js';
import Footer from './footer';
import FadeIn from 'react-fade-in';
import SecondaryButton from './secondary_button';
import MetaTags from 'react-meta-tags';
import ReactGA from 'react-ga';

// Material UI
import { CircularProgress } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

// Calendar Imports
import { format } from 'date-fns'
import { enGB } from 'date-fns/locale'
import { DatePickerCalendar } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import * as dateRangeUtils from '.././date_range.js';

// Modal Imports
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

// Image Imports
import noSpaceImage from '.././assets/no_space.png'
import matchRequestedImage from '.././assets/match_requested.png'
import creditCardImage from '.././assets/credit_card.png'
import studentImage from '.././assets/student.png'
import secureImage from '.././assets/secure.png'
import residenceImage from '.././assets/residence.png'
import boxesImage from '.././assets/boxes.png'
import calendarImage from '.././assets/calendar.png'
import calendarEnteredImage from '.././assets/calendar_entered.png'
import deliveredImage from '.././assets/delivered.png';
Amplify.configure(awsExports);

class Search extends Component {

  state = {
    user: null,
    modalEmail: "",
    modalPassword: "",
    modalConfirmPassword: "",
    showModalCode: false,
    showModalSignUp: false,
    showMatchInProgress: false,
    recentlyMatched: false,
    showListerCard: false,
    errors: {},
    loadingListings: true,
    residences: [],
    listings: [],
    showFilters: false,
    selectedFilters: [{ value: 1, label: "1" }, { value: "All Locations", label: "All Locations" }],
    selectedCapacity: [{ value: 1, label: "1" }],
    selectedLocations: [{ value: "All Locations", label: "All Locations" }],
    selectedDelivery: [{ value: false, label: "Delivery" }],
    selectedStartDateLabel: [],
    selectedEndDateLabel: [],
    startDate: null,
    endDate: null,
    boxesOptions: [
      { value: 1, label: "1" },
      { value: 2, label: "2" },
      { value: 3, label: "3" },
      { value: 4, label: "4" },
      { value: 5, label: "5" },
    ],
    locationOptions: [
      { value: "Square on Fifth", label: "Square on Fifth" },
      { value: "Signature West Midtown", label: "Signature West Midtown" },
      { value: "University House Midtown", label: "University House Midtown" },
      { value: "Modera Midtown", label: "Modera Midtown" },
      { value: "Catalyst Midtown", label: "Catalyst Midtown" },
      { value: "M Street", label: "M Street" },
      { value: "The Standard", label: "The Standard" },
      { value: "Home Park", label: "Home Park" },
      { value: "GT Housing", label: "GT Housing" },
      { value: "All Locations", label: "All Locations" }
    ],
    informationDict: [
      {
        img: secureImage,
        bold_text: "Secure",
        extra_text: " storage"
      },
      {
        img: studentImage,
        bold_text: "Students",
        extra_text: " helping students"
      },
      {
        img: creditCardImage,
        bold_text: "$20",
        extra_text: " per month"
      }
    ],
    selectedIndex: -1,
    filtersList: [
      {
        img: boxesImage,
        bold_text: "Boxes",
        extra_text: " needed (#)"
      }, {
        img: deliveredImage,
        bold_text: "Delivery",
        extra_text: " included"
      }, {
        img: residenceImage,
        bold_text: "Location",
        extra_text: "  of storage"
      }, {
        img: calendarImage,
        bold_text: "Drop Off",
        extra_text: " date"
      }, {
        img: calendarImage,
        bold_text: "Pick Up",
        extra_text: " date"
      }
    ],
    currentPage: 1,
    postsPerPage: 4,
    windowWidth: 0,
    mobile: false,
    size: 0
  }

  constructor(props) {
    super(props);
    this.state.modalEmail = ""
    this.state.modalPassword = ""
    this.state.modalConfirmPassword = ""
    this.clearDates = this.clearDates.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSelectorUpdate = this.handleSelectorUpdate.bind(this);
    this.handleStartDateChosen = this.handleStartDateChosen.bind(this);
    this.handleEndDateChosen = this.handleEndDateChosen.bind(this);
    this.handleSelectedCapacity = this.handleSelectedCapacity.bind(this);
    this.handleSelectedLocations = this.handleSelectedLocations.bind(this);
    this.paginate = this.paginate.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateSignUp = this.validateSignUp.bind(this);
    this.confirmCode = this.confirmCode.bind(this);
    this.handleMatchMe = this.handleMatchMe.bind(this);
    this.updateMatchStatus = this.updateMatchStatus.bind(this);
    this.handleUnavailableListingModal = this.handleUnavailableListingModal.bind(this);

    ReactGA.pageview(window.location.pathname);

    let urlComponents = window.location.href.split("/");
    let status = urlComponents[urlComponents.length - 1];
    if (status.includes("lister_")) {
      const schoolId = status.split("_")[1];
      if (schoolId) {
          window.tempSchool = window.schools.find(school => school.id == schoolId);
      }
      window.isListerInterest = true;
    } else if (status.includes("customer_")) {
      const schoolId = status.split("_")[1];
      if (schoolId) {
          window.tempSchool = window.schools.find(school => school.id == schoolId);
      }
      window.isCustomerInterest = true;
    }
  }

  componentDidMount() {
    this.getListings();

    //mobile rendering event listener
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillMount() {
    let reservation = reservationCache.getReservation();
    if (reservation) {
      let startDateObject = new Date(reservation.startDate).addDays(1);
      let endDateObject = new Date(reservation.endDate).addDays(1);
      this.setState({
        selectedCapacity: [{ value: reservation.purchasedSpace, label: "" + reservation.purchasedSpace }],
        startDate: reservation.startDate != "" ? startDateObject : null,
        endDate: reservation.endDate != "" ? endDateObject : null,
        selectedStartDateLabel: reservation.startDate != "" ? [{value: "startDate" + reservation.startDate, label: dateRangeUtils.getDateLabel(startDateObject)}] : [],
        selectedEndDateLabel: reservation.endDate != "" ? [{value: "endDate" + reservation.endDate, label: dateRangeUtils.getDateLabel(endDateObject)}] : []
      });
    }

    //remove mobile rendering event listener
    window.removeEventListener("resize", this.updateDimensions);
  }

  async isLoggedIn() {
    try {
      let user = await Auth.currentAuthenticatedUser();
      if (this.state.user == null) {
        let stripeId = user.attributes['custom:stripeAccountId'];
        let userListingId = user.attributes['custom:listingId'];
        let reservationId = user.attributes['custom:reservationId'];
        var matchRequest = user.attributes['custom:matchRequest'];
        //reservationCache.removeReservationFromCurrentUser();

        console.log("before match request", matchRequest, user);
        if (matchRequest) {
          matchRequest = JSON.parse(user.attributes['custom:matchRequest']);
          // Show match in progress UI if current date is within matchRequest range
          console.log("showMatchInProgress", !(new Date() > Date.parse(matchRequest.lastDay), new Date(), Date.parse(matchRequest.lastDay)));
          this.setState({showMatchInProgress: !(new Date() > Date.parse(matchRequest.lastDay))})
        }

        let residenceData = JSON.parse(utils.getCurrentSchoolAttribute(user.attributes.email, "residences"));
        reservationCache.updateReservation("loggedIn", true);
        if (reservationId) {
          reservationCache.updateReservation("alreadyPurchased", true);
        } else {
          reservationCache.updateReservation("alreadyPurchased", false);
        }
        let currentUser = {
          stripeAccountId: stripeId != null ? stripeId : "",
          userListingId: userListingId != null ? userListingId : "",
          reservationId: reservationId != null ? reservationId : "",
          matchRequest: matchRequest,
          email: user.attributes.email,
          username: user.attributes.email.substr(0, user.attributes.email.indexOf('@'))}
        this.setState({user: currentUser, residences: residenceData.residences, showListerCard: userListingId != null, recentlyMatched: matchRequest == null ? false : matchRequest.status == "matched"});
      }
      return true;
    } catch(err) {
      console.log("No user", err);
    }
  }

  handleUnavailableListingModal() {
    console.log("handleUnavailableListingModal here");
    this.setState({alertDetails:
      {
        title: "No Available Space",
        description: "All of the space in this listing has recently been purchased. Would you like us to match you with a lister? We will email you once we find another potential match (within 5-7 days).",
        open: true
      }
    })
  }

  showDetails = (listing) => {
    console.log("showing details");
    if (this.state.user != null && listing.user_id == this.state.user.username) {
      this.props.history.push("new_listing/edit")
    } else {
      let currentReservation = reservationCache.getReservation();
      let reservationDetails = {
        isMocked: listing.isMocked,
        alreadyPurchased: currentReservation.alreadyPurchased,
        loggedIn: this.state.user != null,
        listingId : listing.id,
        customer_id: this.state.user != null ? this.state.user.username : "n/a",
        customerEmail: this.state.user != null ? this.state.user.email : "n/a",
        listerEmail: listing.listerEmail,
        purchasedPackaging: currentReservation.purchasedPackaging,
        purchasedDelivery: currentReservation.purchasedDelivery,
        purchasedSpace: this.state.selectedCapacity[0].value,
        startDate : this.state.selectedStartDateLabel.length > 0 ? this.state.selectedStartDateLabel[0].value.substring(9): currentReservation.startDate,
        endDate: this.state.selectedEndDateLabel.length > 0 ? this.state.selectedEndDateLabel[0].value.substring(7): currentReservation.endDate
      }
      reservationCache.setReservation(reservationDetails);

      this.props.history.push({
        pathname: "/details/" + listing.id,
        listing: listing,
        selectedCapacity: this.state.selectedCapacity[0].value
      });
    }
  }

  handleSelectedLocations = (locations) => {
    if (locations.length == 0) {
      locations = [{ value: "All Locations", label: "All Locations" }]
    } else {
      if (locations.length > 1) {
        if (locations[0].value == "All Locations") {
          locations = locations.filter(function(item) {
              return item.value !== "All Locations"
          })
        } else if (locations[locations.length - 1].value == "All Locations") {
          locations = [{ value: "All Locations", label: "All Locations" }]
        }
      }
    }
    this.setState({selectedLocations: locations});
  }

  handleSelectedCapacity = (capacity) => {
    this.setState({selectedCapacity: capacity});
  }

  handleSelectorUpdate = (filters) => {
    this.setState({selectedFilters: filters});
  }

  handleFilterChange(value) {
    this.setState({selectedIndex: value})
  }

  handleStartDateChosen = (chosenDate) => {
    this.setState({selectedIndex: 3, startDate: chosenDate, selectedStartDateLabel: [{value: "startDate" + format(chosenDate, 'yyyy-MM-dd', { locale: enGB }), label: dateRangeUtils.getDateLabel(chosenDate)}]});
  }

  handleEndDateChosen = (chosenDate) => {
    this.setState({selectedIndex: -1, endDate: chosenDate, selectedEndDateLabel: [{value: "endDate" + format(chosenDate, 'yyyy-MM-dd', { locale: enGB }), label: dateRangeUtils.getDateLabel(chosenDate)}]});
  }

  // Triggered when the window is resized
  updateDimensions = () => {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let mobile = windowWidth < 600 ? true : false;
    var size = 0
    if (windowWidth >= 450) {
      size = 1
    }
    if (windowWidth >= 576) {
      size = 2
    }
    if (windowWidth >= 768) {
      size = 3
    }
    if (windowWidth >= 992) {
      size = 4
    }
    if (windowWidth >= 1200) {
      size = 5
    }
    this.setState({windowWidth, size, mobile});
  }

  async getListings() {
    try {
      const allListings = await API.graphql(graphqlOperation(listingsByDate, {type: "Listing", sortDirection: 'DESC'}));
      var listings = allListings.data.listingsByDate.items;
      for (var i = 0; i < listings.length; i++) {
        var currentListing = listings[i];
        currentListing.ranking = 0.0;
        // Increase ranking for non mocked listings
        if (currentListing.isMocked) {
          currentListing.ranking -= 2.0
        }
        // Increase ranking for verified listings
        if (currentListing.isVerified) {
          currentListing.ranking += 2.0
        }

        const reservedSpaceCount = utils.getReservedSpaceCount(currentListing.reservations);
        if (reservedSpaceCount == currentListing.capacity) {
          currentListing.ranking -= 1.0;
        }

        let currentTime = new Date();
        if (new Date(currentListing.expirationDate) < currentTime) {
          currentListing.ranking -= 3.0;
        }
        // Increase ranking for listings with pictures
        if (currentListing.img_url != "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3-us-west-2.amazonaws.com/assets/storage.png") {
          currentListing.ranking += 1.0
        }
      }
      listings.sort((listing1, listing2) => (listing1.ranking < listing2.ranking) ? 1 : -1);
      this.setState({listings: allListings.data.listingsByDate.items, loadingListings: false });
    } catch (error) {
      console.log("Error:", error);
    }
  }

  async signUp() {
    let username = this.state.modalEmail;
    let password = this.state.modalPassword;
    let errors = this.state.errors;
    try {
      const { user } = await Auth.signUp({
        username,
        password
      });
      this.setState({showModalCode: true, showModalSignUp: false});
    } catch (error) {
      errors["cognito"] = error.message;
      this.setState({
        errors: errors
      });
    }
  }

  async confirmCode() {
    let username = this.state.modalEmail;
    let password = this.state.modalPassword;
    let code = this.state.code;
    try {
      await Auth.confirmSignUp(username, code);
      const user = await Auth.signIn(username, password);
      const today = new Date()
      const nextWeek = new Date(today)
      nextWeek.setDate(nextWeek.getDate() + 7)
      const result = await Auth.updateUserAttributes(user, {
        'custom:matchRequest': JSON.stringify({
          status: "pending",
          requestDate: today.toISOString().slice(0, 10),
          lastDay: nextWeek.toISOString().slice(0, 10)
        })
      });
      this.setState({loadingSubmit : false, errors: {}})
      let loggedIn = "loggedIn"
      reservationCache.updateReservation(loggedIn, true);
      let user_id = username.substr(0, username.indexOf('@'))
      reservationCache.updateReservation("customer_id", user_id);
      this.props.isLoggedIn();
      // Update the Empty UI
      this.setState({showModalCode: false, showModalSignUp: false, showMatchInProgress: true});
      const body = {
        customerEmail: this.state.user.email,
        reason: "matchRequest",
        priority: 1,
        text: "You have 7 days to find a lister for " + this.state.user.email
      }
      const response = await API.post("ses", "/contact-us", { body });
    } catch (error) {
      this.setState({showMatchInProgress: false, loadingSubmit : false, errors: {}})
      let errors = {}
      errors["code"] = error.message;
      this.setState({
        errors: errors
      });
    }
  }

  async validateSignUp() {
    this.setState({loadingSubmit : false, errors: {}})
    let email = this.state.modalEmail;
    let password = this.state.modalPassword;
    let confirm = this.state.modalConfirmPassword;
    let emailExtensions = window.schools.map(school => school.emailExtension.substring(1));
    let validationResponse = validator.validateSignUp(email, password, confirm, emailExtensions);
    var isValid = validationResponse.isValid;
    var errors = validationResponse.errors;
    if (errors) {
      this.setState({errors: errors})
    }
    if (isValid) {
      this.signUp();
    }
  }

  async handleMatchMe() {
    if (this.state.user) { // User is logged in
      const today = new Date()
      const nextWeek = new Date(today)
      nextWeek.setDate(nextWeek.getDate() + 7)
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(user, {
        'custom:matchRequest': JSON.stringify({
          status: "pending",
          requestDate: today.toISOString().slice(0, 10),
          lastDay: nextWeek.toISOString().slice(0, 10)
        })
      });

      // Update cognito attribute JSON: { "status" : "pending", "requestDate": "2021-08-06"}
      // status values: ["pending", "matched", "failed"]
      // Call function to notify storestash.reserve of the demand
      // This same function should be called after the user signs up from the modal
      this.setState({showMatchInProgress: true});
      const body = {
        customerEmail: this.state.user.email,
        reason: "matchRequest",
        priority: 1,
        text: "You have 7 days to find a lister for " + this.state.user.email
      }
      const response = await API.post("ses", "/contact-us", { body });
    } else { // Require user to sign up to get matched
      this.setState({showModalSignUp: true, alertDetails: null});
    }
  }

  handleSubmit(event) {
    if (this.state.showModalSignUp) {
      this.setState({loadingSubmit:true});
      setTimeout(this.validateSignUp, 1000);
      event.preventDefault();
    } else if (this.state.showModalCode) {
      this.setState({loadingSubmit: true});
      setTimeout(this.confirmCode, 1000);
      event.preventDefault();
    }
  }

  async clearDates() {
    reservationCache.updateReservation("startDate", "")
    reservationCache.updateReservation("endDate", "")
    this.setState({selectedStartDateLabel: [], selectedEndDateLabel: [], startDate: null, endDate: null});
  }

  async updateMatchStatus(listings) {
    if (this.state.recentlyMatched) {
      var matchRequest = this.state.user.matchRequest;
      if (listings.length == 0) {
        matchRequest.status = "pending"
      } else { // If there is space, mark as completed
        matchRequest.status = "completed"
      }
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(user, {
          'custom:matchRequest': JSON.stringify(matchRequest),
      });
      this.setState({recentlyMatched: false})
    }
  }

  getFilteredListings(allListings, selectedFilters) {
    var filteredListings = new Array();
    var minCapacity = selectedFilters.filter(entry => typeof entry.value === 'number').map(entry => entry.value)
      && selectedFilters.filter(entry => typeof entry.value === 'number').map(entry => entry.value)[0]
    var selectedLocations = selectedFilters.filter(entry => typeof entry.value === 'string').map(entry => entry.value)
    var selectedDelivery = selectedFilters.filter(entry => typeof entry.value === 'boolean').map(entry => entry.value)[0]

    var schoolId = ""
    let currentTime = new Date()
    if (this.state.user && !this.state.user.email.includes("@gmail.com")) {
      schoolId = utils.getCurrentSchoolAttribute(this.state.user.email, "id");
    } else if (!this.state.user && window.tempSchool) {
      schoolId = window.tempSchool.id;
    }
    for (var i = 0; i < allListings.length; i++) {
      let currentListing = allListings[i];

      var spaceRemaining = currentListing.capacity;
      if (currentListing.reservations.items.length > 0) {
        spaceRemaining -= currentListing.reservations.items.map(res => + res.purchasedSpace).reduce((a, b) => a + b, 0);
      }

      if ((schoolId == "" || schoolId == currentListing.school_id) &&
        dateRangeUtils.isAvailableDate(this.state.startDate, this.state.endDate, currentListing.unavailableRanges) &&
        (!selectedDelivery || selectedDelivery && currentListing.includesDelivery) && (minCapacity === undefined || spaceRemaining >= minCapacity) &&
        (selectedLocations === undefined || selectedLocations.length == 0 || selectedLocations.includes(currentListing.residence_id) || selectedLocations.includes("All Locations"))) {
        filteredListings.push(currentListing);
      }
    }
    return filteredListings;
  }

  paginate(pageNumber) {
    this.setState({currentPage: pageNumber});
  }

  render() {
    //reservationCache.removeReservationFromCurrentUser();
    this.isLoggedIn();
    const animatedComponents = makeAnimated();

    var selectedFilters = this.state.selectedCapacity.concat(this.state.selectedLocations);
    selectedFilters = this.state.selectedStartDateLabel.length > 0 ? selectedFilters.concat(this.state.selectedStartDateLabel) : selectedFilters;
    selectedFilters = this.state.selectedEndDateLabel.length > 0 ? selectedFilters.concat(this.state.selectedEndDateLabel) : selectedFilters;
    selectedFilters = selectedFilters.concat(this.state.selectedDelivery);

    var allListings = this.state.listings;
    allListings = this.getFilteredListings(allListings, selectedFilters);
    this.updateMatchStatus(allListings);
    console.log("all listings count:", allListings.length, allListings);
    console.log("current size", this.state.size);

    //pagination get current posts
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPosts = allListings.slice(indexOfFirstPost, indexOfLastPost);

    const evenListings = new Array();
    const oddListings = new Array();
    for (var i = 0; i < currentPosts.length; i++) {
      if (i % 2 == 0) {
        evenListings.push(currentPosts[i]);
      } else {
        oddListings.push(currentPosts[i]);
      }
    }

    const CustomCheckbox = withStyles({
      root: {
        color: "#fdad16",
        '&$checked': {
          color: "#fdad16",
        },
      },
      checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const {mobile} = this.state
    const {windowWidth} = this.state

    const mobileListingsViews = this.state.listings != null ? allListings.map((listing) =>
        <ListingView handleUnavailableListingModal={this.handleUnavailableListingModal} key={listing.id} listing={listing} showDetails={this.showDetails} mobile={true} windowWidth={windowWidth}/>) : <div><CircularProgress style={{color: "#fdad16", marginTop:"40%"}}/></div>;

    const evenListingViews = this.state.listings != null ? evenListings.map((listing) =>
        <ListingView key={listing.id} listing={listing} handleUnavailableListingModal={this.handleUnavailableListingModal} showDetails={this.showDetails} mobile={false}/>) : <div><CircularProgress style={{color: "#fdad16", marginTop:"40%"}}/></div>;
    const oddListingViews = this.state.listings != null ? oddListings.map((listing) =>
        <ListingView key={listing.id} listing={listing} handleUnavailableListingModal={this.handleUnavailableListingModal} showDetails={this.showDetails} mobile={false}/>) : <div><CircularProgress style={{color: "#fdad16", marginTop:"40%"}}/></div>;
    var locationOptions = [];
    for (var i = 0; i < this.state.residences.length; i++) {
      locationOptions.push({ value: this.state.residences[i], label: this.state.residences[i]});
    }
    locationOptions.push({ value: "Other", label: "Other"});
    const information = this.state.informationDict.map((information, index) =>
        <InformationView information={information} residences={locationOptions} handleFilterChange={this.handleFilterChange} currentIndex={index}/>);
    var emptyView;

    const unselectedBoxesView = (
      <div>
      <div class="row" style={{display: "flex", justifyContent: "space-between", marginRight: 20}}>
        <ul class="fa-ul" style={{display:"flex", flexDirection:"row",  listStyleType: "none"}}>
          <button className="button_empty" onClick={() => this.state.selectedIndex != 0 ? this.setState({selectedIndex: 0}) : this.setState({selectedIndex: -1})}>
            <img className="img_icon" src={boxesImage}></img>
          </button>
          <li>
              <button className="button_empty" onClick={() => this.state.selectedIndex != 0 ? this.setState({selectedIndex: 0}) : this.setState({selectedIndex: -1})}>
                <p class="mb-0 text-body" style={{fontSize: 16, marginLeft: 10}}>
                    <b>{this.state.filtersList[0].bold_text}</b>{this.state.filtersList[0].extra_text}
                </p>
              </button>
          </li>
        </ul>
        <p key={this.state.selectedCapacity} >{this.state.selectedCapacity.length > 0 ? this.state.selectedCapacity[0].value : ""}</p>
      </div>
      {this.state.selectedIndex == 0 && <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={this.state.selectedCapacity}
        options={this.state.boxesOptions}
        onChange={(capacity)=>this.setState({selectedCapacity: [capacity]})}
      />}
      </div>
    );

    const unselectedDeliveryView = (
      <div>
        <div class="row" style={{display: "flex", justifyContent: "space-between", paddingRight: 5}}>
          <ul class="fa-ul" style={{display:"flex", flexDirection:"row",  listStyleType: "none"}}>
            <button className="button_empty" onClick={() => this.setState({selectedDelivery: [{ value: !this.state.selectedDelivery[0].value, label: "Delivery" }]})}>
              <img className="img_icon" src={deliveredImage}></img>
            </button>
            <li>
                <button className="button_empty" onClick={() => this.setState({selectedDelivery: [{ value: !this.state.selectedDelivery[0].value, label: "Delivery" }]})}>
                  <p class="mb-0 text-body" style={{fontSize: 16, marginLeft: 10, marginTop: 8}}>
                      <b>{this.state.filtersList[1].bold_text}</b>{this.state.filtersList[1].extra_text}
                  </p>
                </button>
            </li>
          </ul>
          <div style={{paddingBottom: 8}}>
          <FormControlLabel
            control={<CustomCheckbox checked={this.state.selectedDelivery[0].value} onChange={() => this.setState({selectedDelivery: [{ value: !this.state.selectedDelivery[0].value, label: "Delivery" }]})} name="checkedG" />}
            labelPlacement="end"
          />
          </div>
        </div>
      </div>
    );

    const unselectedLocationView = (
      <div>
        <div class="row" style={{display: "flex", justifyContent: "space-between", marginRight: 20}}>
          <ul class="fa-ul" style={{display:"flex", flexDirection:"row",  listStyleType: "none"}}>
            <button className="button_empty" onClick={() => this.state.selectedIndex != 2 ? this.setState({selectedIndex: 2}) : this.setState({selectedIndex: -1})}>
              <img className="img_icon" src={residenceImage}></img>
            </button>
            <li>
                <button className="button_empty" onClick={() => this.state.selectedIndex != 2 ? this.setState({selectedIndex: 2}) : this.setState({selectedIndex: -1})}>
                  <p class="mb-0 text-body" style={{fontSize: 16, marginLeft: 10}}>
                      <b>{this.state.filtersList[2].bold_text}</b>{this.state.filtersList[2].extra_text}
                  </p>
                </button>
            </li>
          </ul>
          <p>{this.state.selectedLocations.length == 1 && this.state.selectedLocations[0].value == "All Locations" ? "All" : this.state.selectedLocations.length}</p>
        </div>
        {this.state.selectedIndex == 2 && <Select
          key = {uuidv4()}
          closeMenuOnSelect={this.props.closeMenuOnSelect ? this.props.closeMenuOnSelect : false}
          components={animatedComponents}
          isMulti
          defaultValue={this.state.selectedLocations}
          options={locationOptions}
          onChange={this.handleSelectedLocations}
        />}
      </div>
    );

    console.log(selectedFilters, "these are the selected filters", selectedFilters.filter(filter => typeof(filter.value) == "string" && filter.value.includes("startDate")).length);

    const unselectedDropOffView = (
      <div>
        <div class="row" style={{display: "flex", justifyContent: "space-between", marginRight: 20}}>
          <ul class="fa-ul" style={{display:"flex", flexDirection:"row",  listStyleType: "none"}}>
            <button className="button_empty" onClick={() => this.state.selectedIndex != 3 ? this.setState({selectedIndex: 3}) : this.setState({selectedIndex: -1})}>
              <img className="img_icon" src={this.state.startDate == null ? calendarImage : calendarEnteredImage}></img>
            </button>
            <li>
                <button className="button_empty" onClick={() => this.state.selectedIndex != 3 ? this.setState({selectedIndex: 3}) : this.setState({selectedIndex: -1})}>
                  <p class="mb-0 text-body" style={{fontSize: 16, marginLeft: 10}}>
                      <b>{this.state.filtersList[3].bold_text}</b>{this.state.filtersList[3].extra_text}
                  </p>
                </button>
            </li>
          </ul>
          <p>{selectedFilters.filter(filter => typeof(filter.value) == "string" && filter.value.includes("startDate")).length == 1
            ? selectedFilters.filter(filter => typeof(filter.value) == "string" && filter.value.includes("startDate"))[0].label : "" }</p>
        </div>
        {this.state.selectedIndex == 3 && <DatePickerCalendar
          date={this.state.startDate}
          minimumDate={new Date()}
          maximumDate={this.state.endDate}
          onDateChange={this.handleStartDateChosen}
          locale={enGB}
        />}
      </div>
    );
    const unselectedPickUpView = (
      <div>
        <div class="row" style={{display: "flex", justifyContent: "space-between", marginRight: 20}}>
          <ul class="fa-ul" style={{display:"flex", flexDirection:"row",  listStyleType: "none"}}>
            <button className="button_empty" onClick={() => this.state.selectedIndex != 4 ? this.setState({selectedIndex: 4}) : this.setState({selectedIndex: -1})}>
              <img className="img_icon" src={this.state.endDate == null ? calendarImage : calendarEnteredImage}></img>
            </button>
            <li>
                <button className="button_empty" onClick={() => this.state.selectedIndex != 4 ? this.setState({selectedIndex: 4}) : this.setState({selectedIndex: -1})}>
                  <p class="mb-0 text-body" style={{fontSize: 16, marginLeft: 10}}>
                      <b>{this.state.filtersList[4].bold_text}</b>{this.state.filtersList[4].extra_text}
                  </p>
                </button>
            </li>
          </ul>
          <p>{selectedFilters.filter(filter => typeof(filter.value) == "string" && filter.value.includes("endDate")).length == 1
            ? selectedFilters.filter(filter => typeof(filter.value) == "string" && filter.value.includes("endDate"))[0].label : "" }</p>
        </div>
        {this.state.selectedIndex == 4 && <DatePickerCalendar
          date={this.state.startDate.addDays(1)}
          minimumDate={this.state.startDate}
          maximumDate={this.state.startDate.addDays(150)}
          onDateChange={this.handleEndDateChosen}
          locale={enGB}/>}
      </div>
    );

    const listingsToRender = !mobile ? <React.Fragment><div>{evenListingViews}</div><div>{oddListingViews}</div></React.Fragment> : <div>{mobileListingsViews}</div>
    const pagination = !mobile ? <div style={{marginLeft: windowWidth * 0.05}}><React.Fragment>
      <br></br>
      <ListingsPagination
          postsPerPage={this.state.postsPerPage}
          totalPosts={allListings.length}
          paginate={this.paginate}
      />
    </React.Fragment></div> : null

    if (this.state.loadingListings) {
      if (this.state.size <= 2) {
        emptyView = (
          <React.Fragment>
            <div style={{marginTop: "5%"}}>
              <CircularProgress style={{color: "#fdad16", marginTop:"5%"}}/>
              <br></br>
              <h5 class="card-title" style={{color: "#43495e"}}>Loading Listings...</h5>
            </div>
          </React.Fragment>)
      } else {
        emptyView = (
          <React.Fragment>
            <div style={{position: "absolute", right: "15%", left: "48%", top: "15%"}}>
              <CircularProgress style={{color: "#fdad16", marginTop:"40%"}}/>
              <br></br>
              <h5 class="card-title" style={{color: "#43495e"}}>Loading Listings...</h5>
            </div>
          </React.Fragment>)
      }
    } else if (allListings.length == 0) {
      if (this.state.size <= 2) {
        emptyView = (<React.Fragment>
          <div style={{marginTop: 30}}>
            <FadeIn>
              <img style={{width: 250}} src={this.state.showMatchInProgress ? matchRequestedImage : noSpaceImage}></img>
              <h5 class="card-title" style={{color: "#43495e"}}>{this.state.showMatchInProgress ? "We'll match you soon!" : "All Spaces Reserved Now"}</h5>
              <p style={{color: "#aaaaaa", marginLeft: 10, marginRight: 10}}>{this.state.showMatchInProgress ? "Congrats you've signed up and requested a match! Give us 6-8 days to connect you with a lister who has space for you." : "We can still find a home for your items! Click 'Match Me' below and we'll connect you (in 6-8 days) with a lister who has space for you."}</p>
              {!this.state.showMatchInProgress && <button className="home_onboard_customer" style={{marginTop: 15, marginBottom: 30}} onClick={this.handleMatchMe}>Match Me</button>}
            </FadeIn>
          </div>
        </React.Fragment>)
      } else {
        emptyView = (
          <React.Fragment>
            <div style={{position: "absolute", right: "15%", left: this.state.size < 4 ? (48 + 3*this.state.size).toString() + "%" : "48%", top: "15%"}}>
              <FadeIn>
                <img style={{width: 250}} src={this.state.showMatchInProgress ? matchRequestedImage : noSpaceImage}></img>
                <h5 class="card-title" style={{color: "#43495e"}}>{this.state.showMatchInProgress ? "We'll match you soon!" : "All Spaces Reserved Now"}</h5>
                <p style={{color: "#aaaaaa"}}>{this.state.showMatchInProgress ? "Congrats you've signed up and requested a match! Give us 6-8 days to connect you with a lister who has space for you." : "We can still find a home for your items! Click 'Match Me' below and we'll connect you (in 6-8 days) with a lister who has space for you."}</p>
                {!this.state.showMatchInProgress && <button className="home_onboard_customer" style={{marginTop: 15, marginBottom: 30}} onClick={this.handleMatchMe}>Match Me</button>}
              </FadeIn>
            </div>
          </React.Fragment>)
      }
    }

    var modalBody;
    if (this.state.showModalSignUp) {
      modalBody = (<Modal.Body>
        <Form.Group>
          <Form.Control type="text" id="login" onChange={(newText) => this.setState({modalEmail: newText.target.value})} defaultValue={this.state.modalEmail} placeholder={"School Email"}/>
          <div className="text-danger">{this.state.errors.email}</div>
          <Form.Control type="password" autocomplete="new-password" id="password" onChange={(newText) => this.setState({modalPassword: newText.target.value})} value={this.state.modalPassword} placeholder="Password"/>
          <div className="text-danger">{this.state.errors.password}</div>
          <Form.Control type="password" id="confirm" onChange={(newText) => this.setState({modalConfirmPassword: newText.target.value})} value={this.state.modalConfirmPassword} placeholder="Confirm Password"/>
          <div className="text-danger">{this.state.errors.confirm}</div>
          <div className="text-danger">{this.state.errors.cognito}</div>
          {this.state.loadingSubmit && <div><CircularProgress style={{color: "#fdad16"}}/></div>}
        </Form.Group>
      </Modal.Body>);
    } else if (this.state.showModalCode) {
      modalBody = (<Modal.Body>
        <Form.Group>
          <div>
            <form style={{marginBottom: 5}} onSubmit={this.handleSubmit}>
                <input type="number" id="code" placeholder="Code" value={this.state.code} onChange={(newText) => this.setState({code: newText.target.value})}/>
                <div className="text-danger">{this.state.errors.code}</div>
                <p align="center" style={{width: "80%", color: "#aaaaaa", fontSize: 13}}>{"The code has been sent to your email. Check your spam folder if you are having trouble finding it."}</p>
             </form>
             {this.state.loadingSubmit && <div><CircularProgress style={{color: "#fdad16"}}/></div>}
           </div>
        </Form.Group>
      </Modal.Body>);
    }

    let activeListerCard = <div hidden={this.state.showFilters} class="card text-left" style={{borderRadius: 12, marginLeft: windowWidth * 0.05, marginRight: windowWidth * 0.05, minWidth: 330, marginBottom: 30}}>
        <div class="card-body">
        <h5 class="card-title"><b>Reach more customers</b></h5>
        <p style={{fontSize: 13+'px', position: "absolute"}} class="card-subtitle text-muted">Make $$$ for each item you store</p>
        <hr style={{marginTop: 30}}></hr>
        <textarea disabled class="text-body" rows={this.state.showMoreLister ? "12" : "4"} style={{paddingBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", resize: "none", backgroundColor: "#ffffff00"}}>
          {
            "Become a verified lister and earn more with StoreStash. Check out our tips to get more customers!"
          }
        </textarea>
        <br></br>
        { // No listings, show muted buttons
          allListings.length == 0 ? <div><br></br><button className="button_empty" style={{marginLeft: -6}} onClick={()=> this.props.history.push("new_listing/empty")}><SecondaryButton icon="plus circle" label="Create Listing"/></button></div>
          :
          <FadeIn>
            <button onClick={()=> this.props.history.push("product_info")} style={{marginTop:15}} className="home_onboard_customer">View Tips</button>
          </FadeIn>
        }
        </div>
      </div>

      const listerCardSubtitle = "Passive income is just clicks away"
      const listerCardText = this.state.size > 3
      ?
      ("Earn $170-$1100 storing items for other students." +
      "\n\nCreate a listing," +
      "\n include the number of boxes you'll store, and get matched with students! Make $170 for just storing 5 boxes in your closet." +
      "\nOptionally include delivery to get paid an extra $25 when a student purchases boxes with that service. You'll never have to drive more than 5 from campus!")
      :
      ("Earn $170-$1100 storing items for students at your college.")


      let potentialListerCard = <div hidden={this.state.showFilters || window.isCustomerInterest} class="card text-left" style={{borderRadius: 12, marginLeft: windowWidth * 0.05, marginRight: windowWidth * 0.05, minWidth: 330}}>
        <div class="card-body">
        <h5 class="card-title"><b>Get paid for your extra space</b></h5>
        <p style={{fontSize: 13+'px', position: "absolute"}} class="card-subtitle text-muted">{listerCardSubtitle}</p>
        <hr style={{marginTop: 30}}></hr>
        <p rows={this.state.showMoreLister ? "12" : "2"} style={{color: "black", paddingBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", overflow: "hidden", height: this.state.showMoreLister ? 220 : 50, backgroundColor: "#ffffff00"}}>
          {listerCardText}
        </p>
        <button hidden={this.state.size < 3} onClick={() => this.setState({showMoreLister : !this.state.showMoreLister})} style={{marginRight: "90%", paddingLeft: 2, fontSize: 16, marginTop: -10, marginBottom: 10, backgroundColor: "#ffffff00", color: "grey", textAlign: "left", border: "none", resize: "none"}}>
          {this.state.showMoreLister ? "less..." : "more..."}
        </button>
        { // No listings, show muted buttons
          allListings.length == 0 ? <div><br></br><button style={{marginLeft: -6}} className="button_empty" onClick={()=> this.props.history.push("new_listing/empty")}><SecondaryButton icon="plus circle" label="Create Listing"/></button></div>
          :
          <FadeIn>
            <button onClick={()=> this.props.history.push("../new_listing/empty")} style={{marginTop: this.state.size < 3 ? 0 : 15}} className={window.isListerInterest ? "home_onboard_customer" : "home_onboard_lister"}>Create Listing</button>
          </FadeIn>
        }
        </div>
      </div>

    var searchCallToActionButton;
    if (this.state.showListerCard || this.props.recentlyCreatedListing) {
      searchCallToActionButton = <div><br></br><button className="button_empty" onClick={() => this.setState({showFilters: !this.state.showFilters})}><SecondaryButton label="Get Started"/></button></div>
    } else if (allListings.length == 0) {
      searchCallToActionButton = <div><br></br><button style={{marginLeft: -6}} className="button_empty" onClick={() => this.setState({showFilters: !this.state.showFilters})}><SecondaryButton icon="search" label="Get Started"/></button></div>
    } else {
      searchCallToActionButton = <button className="home_onboard_customer" style={{marginTop: 15}} onClick={() => this.setState({showFilters: !this.state.showFilters})}>Get Started</button>
    }

    return (
      <div>
        <MetaTags>
          <title>StoreStash: Find a home for your items</title>
          <meta id="meta-description" name="description" content="Search through listings at your school to find nearby storage with delivery."/>
        </MetaTags>
        {this.state.alertDetails && (<AlertDialog
          title={this.state.alertDetails.title}
          description={this.state.alertDetails.description}
          open={this.state.alertDetails.open}
          extraButtonTitle={"Match Me"}
          extraButtonAction={this.handleMatchMe}
          onClose={() => this.setState({alertDetails: null})}
        />)}
        <Modal centered show={this.state.showModalSignUp || this.state.showModalCode} backdrop="static" keyboard={false} onHide={() => this.setState({showModalSignUp: false})} align="center">
          <Modal.Header closeButton>
            <h5 style={{fontWeight: "bold", fontFamily:'glacialindifference-bold', fontSize: 24, color: "#43495e", marginLeft: "35%"}}>
              Get Matched
            </h5>
          </Modal.Header>
          {modalBody}
          <Modal.Footer style={{ display: "flex", justifyContent: "center"}}>
            <Button className="button_primary" onClick={this.handleSubmit} style={{fontWeight: "bold", backgroundColor: "#fdad16", width: "40%", borderColor: "#ffffff00"}}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>


        {
          // No Listings
          this.state.size > 2 && emptyView
        }
        {(!this.state.showListerCard && !this.props.recentlyCreatedListing) && this.state.size <= 2 && emptyView}

        {
          // Grid
        }
        <div style={{ display: !mobile ? "grid" : null, gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20, marginBottom: this.state.size > 3 && window.isListerInterest && emptyView ? 180 : 0}}>
          <div class={!mobile ? "border-right" : null}>
            <div style={{marginTop: 30}}>
              {(this.state.showListerCard || this.props.recentlyCreatedListing) && activeListerCard}
              <div hidden={this.state.showFilters || window.isListerInterest} class="card text-left" style={{borderRadius: 12, marginLeft: windowWidth * 0.05, marginRight: windowWidth * 0.05, minWidth: 330, marginBottom: 20}}>
                <div class="card-body">
                <h5 class="card-title" align="left"><b>Looking for storage?</b></h5>
                <p style={{fontSize: 13+'px', position: "absolute"}} class="card-subtitle text-muted">Cheap, Safe, and Convenient</p>
                <hr style={{marginTop: 30}}></hr>
                <p style={{color: "black", paddingBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", overflow: "hidden", height: this.state.showMoreCustomer ? 90 : 50, backgroundColor: "#ffffff00"}}>
                  {
                    "StoreStash is the Airbnb of storage. Store your items with nearby students for a fraction of the cost."
                  }
                </p>
                <button onClick={() => this.setState({showMoreCustomer : !this.state.showMoreCustomer})} style={{paddingLeft: 2, fontSize: 16, marginRight: "90%", backgroundColor: "#ffffff00", color: "grey", border: "none", resize: "none"}}>
                  {this.state.showMoreCustomer ? "less..." : "more..."}
                </button>
                { // No listings, show muted buttons
                  searchCallToActionButton
                }
                </div>
                <div>
                </div>
              </div>
              {(!this.state.showListerCard && !this.props.recentlyCreatedListing) && potentialListerCard}
            </div>

            <div>
              <div hidden={!this.state.showFilters} class="card" style={{borderRadius: 12, marginLeft: windowWidth * 0.05, marginRight: windowWidth * 0.05, minWidth: 330}}>
                <div class="card-body">
                  <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                    <h5 align="left" style={{paddingTop: 10}}>Find Your Space</h5>
                    <button class="button_secondary" onClick={() => this.setState({showFilters: false})}> {"Back"}</button>
                    {allListings.length == 0 && <p style={{color: "#aaaaaa", textAlign: "left"}}>No items to search now. Click "Match Me" and we'll connect you (in 6-8 days) with a lister who has space.</p>}
                  </div>
                  <hr></hr>
                  {unselectedBoxesView}
                  {unselectedDeliveryView}
                  {unselectedLocationView}
                  {unselectedDropOffView}
                  {this.state.startDate && unselectedPickUpView}
                  <button hidden={this.state.selectedStartDateLabel.length == 0 || this.state.selectedEndDateLabel.length == 0} onClick={this.clearDates} className="button button_secondary">Clear Dates</button>
                </div>
              </div>
              {allListings.length > 4 && pagination}
              </div>
              {mobile ? listingsToRender : null}
            </div>
            {!mobile ? listingsToRender: null}
        </div>
        <Footer showAuthModal={this.props.showAuthModal} user={this.state.user} style={"main-footer"}></Footer>
      </div>
    );
  }
}

export default withRouter(Search);
