import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(props.open);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        styles={{minWidth: 300}}
        open={open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"><span style={{color: '#43495e', fontFamily:'glacialindifference-bold'}}>{props.title ?? "Notice"}</span></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p style={{color: 'black', textAlign: "left", marginTop: -10}}>
            {props.description ?? ""}
            </p>
            <hr></hr>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{marginTop: -20, paddingBottom: 20, display: "flex", justifyContent: "space-between"}}>
            <Button onClick={props.onClose} style={{float: "left", backgroundColor:"#aaaaaa", borderColor: "#aaaaaa", color: "white", fontFamily:'glacialindifference-bold', borderRadius: 40, paddingLeft: 15, marginLeft: 15, paddingRight: 15}}>
              Close
            </Button>
            {props.extraButtonAction &&
            <Button onClick={props.extraButtonAction} style={{backgroundColor:"#fdad16", borderColor: "#fdad16", color: "white", fontFamily:'glacialindifference-bold', borderRadius: 40, paddingLeft: 15, marginRight: 15, paddingRight: 15}}>
              {props.extraButtonTitle}
            </Button>
            }
        </DialogActions>
      </Dialog>
    </div>
  );
}
