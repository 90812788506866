import './App.css';
import React, { Component } from 'react';
import Search from './components/search';
import CreateListing from './components/create_listing';
import CalculateEarnings from './components/calculate_earnings';
import Earnings from './components/earnings';
import Pricing from './components/pricing';
import ProductInfo from './components/product_info';
import UpdateStatus from './components/update_status';
import AdminDashboard from './components/admin_dashboard';
import OnboardSchool from './components/onboard_school';
import ReservationDetails from './components/reservation_details';
import Login from './components/login';
import Profile from './components/profile';
import SchoolDetails from './components/school_details';
import Home from './components/home';
import ConfirmEmail from './components/confirm_email';
import LogOut from './components/logout';
import SignUp from './components/signup';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Modal } from 'react-bootstrap';
import { BrowserRouter as Router,Link,NavLink,Route } from "react-router-dom";
import Amplify, { Cache, Auth, graphqlOperation, API } from 'aws-amplify'
import { schoolsByName } from './graphql/queries';
import * as constants from './constants.js';
import { CircularProgress } from '@material-ui/core';

// Image Assets
import navLogo from './assets/nav_logo_white_border.png';
import profileImage from './assets/profile.png';
import logoImage from './assets/logo_copy.png';

// Google Analytics
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-221056245-1";
ReactGA.initialize(TRACKING_ID, { debug: true });

class App extends Component {

  state = {
    user: null,
    authType: "",
    authData: null,
    loadingSchools: true,
    recentlyCreatedListing: false
  }

  constructor(props) {
    super(props);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.showAuthModal = this.showAuthModal.bind(this);
    this.getSchools = this.getSchools.bind(this);
    this.recentlyLoggedOut = this.recentlyLoggedOut.bind(this);
    this.handleNewListing = this.handleNewListing.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    if (window.location.host.includes("localhost:3000")) {
      Cache.setItem("env", "test");
    } else if (window.location.host.includes("dev")) {
      Cache.setItem("env", "dev");
    } else if (window.location.host.includes("mystorestash")) {
      Cache.setItem("env", "prod");
    }
    Cache.setItem("urlPrefix", window.location.origin + "/");
    this.getSchools();
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillMount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    var size = 0
    if (windowWidth >= 450) {
      size = 1
    }
    if (windowWidth >= 576) {
      size = 2
    }
    if (windowWidth >= 768) {
      size = 3
    }
    if (windowWidth >= 992) {
      size = 4
    }
    if (windowWidth >= 1200) {
      size = 5
    }
    this.setState({windowWidth, size});
  }

  async getSchools() {
    if (!window.schools) {
      window.schools = constants.SCHOOLS;
      const queriedSchools = await API.graphql(graphqlOperation(schoolsByName, {type: "School", sortDirection: 'ASC'}));
      let allSchoolData = queriedSchools.data.schoolsByName.items;
      window.schools = allSchoolData;
      this.setState({loadingSchools: false });
    }
  }

  async showAuthModal(authType, authData = null) {
    this.setState({authType: authType, authData: authData});
  }

  async isLoggedIn() {
      try {
        let user = await Auth.currentAuthenticatedUser();
        if (this.state.user == null) {
          let stripeId = user.attributes['custom:stripeAccountId'];
          let userListingId = user.attributes['custom:listingId'];
          let reservationId = user.attributes['custom:reservationId'];
          let currentUser = {
            stripeAccountId: stripeId != null ? stripeId : "",
            userListingId: userListingId != null ? userListingId : "",
            reservationId: reservationId != null ? reservationId : "",
            email: user.attributes.email,
            username: user.attributes.email.substr(0, user.attributes.email.indexOf('@'))
          }
          this.setState({user: currentUser});
        }
        return true;
      } catch(error) {
        console.log("No user app.js", error);
      }
  }

  handleNewListing(newListingId) {
    this.setState(prevState => ({
        recentlyCreatedListing: true,
        user: {                   // object that we want to update
            ...prevState.user,    // keep all other key-value pairs
            userListingId: newListingId      // update the value of specific key
        }
    }))
  }

  recentlyLoggedOut() {
    if (this.state.user != null) {
      this.setState({user: null});
    }
  }

  render() {
    this.isLoggedIn();

    var authModal = null;
    if (this.state.authType == "forgotPassword") {
      authModal = (
        <Modal centered show={this.state.authType == "forgotPassword"} keyboard={false} onHide={() => this.setState({authType: ""})} align="center">
          <ConfirmEmail isLoggedIn={this.isLoggedIn} authData={this.state.authData}/>
        </Modal>
      )
    } else if (this.state.authType == "signUp") {
      ReactGA.event({
        category: window.tempSchool?.name,
        action: 'Sign Up Interest',
        label: 'App.js',
      });
      authModal = (
        <Modal centered show={this.state.authType == "signUp"} keyboard={false} onHide={() => this.setState({authType: ""})} align="center">
          <SignUp showAuthModal={this.showAuthModal}/>
        </Modal>
      )
    } else if (this.state.authType == "login") {
      authModal = (
        <Modal centered show={this.state.authType == "login"} keyboard={false} onHide={() => this.setState({authType: ""})} align="center">
          <Login isLoggedIn={this.isLoggedIn} showAuthModal={this.showAuthModal}/>
        </Modal>
      )
    } else if (this.state.authType == "confirmEmail") {
      authModal = (
        <Modal centered show={this.state.authType == "confirmEmail"} keyboard={false} onHide={() => this.setState({authType: ""})} align="center">
          <ConfirmEmail isLoggedIn={this.isLoggedIn} showAuthModal={this.showAuthModal} authData={this.state.authData}/>
        </Modal>
      )
    }

    var profileButton;
    if (this.state.user != null) {

      profileButton = (<Nav.Link as={NavLink} to="/profile/empty">
        <div style={{marginTop: 5}}>
        <href style={{fontFamily:'glacialindifference-bold', fontSize: 15, marginRight: -5}}>
          <b>{this.state.user.username}</b>
        </href>
        </div>
      </Nav.Link>);
    } else {
      profileButton = (
        <Nav.Link onClick={() => this.setState({authType: "signUp"})}>
          <href style={{fontFamily:'glacialindifference-bold', fontSize: 15}}>
            <b>{ this.state.user != null ? this.state.user.username : "Sign Up" }</b>
          </href>
        </Nav.Link>);
    }

    const chooseSchoolButton = (
      <Nav.Link as={NavLink} style={{width: this.state.size < 4 ? "100%" : 200}} to="/schools/test">
        <href style={{fontFamily:'glacialindifference-bold', fontSize: 15, marginRight: -5, marginTop: 5}}>
          <b>{"Choose Your School"}</b>
        </href>
      </Nav.Link>
    )

    const earningsButton = (
      <Nav.Link as={NavLink} style={{width: this.state.size < 4 ? "100%" : 130, marginTop: 6}} to={"/earnings"}>
        <href style={{fontFamily:'glacialindifference-bold', fontSize: 15, marginRight: -5, marginTop: 5}}>
          <b>{"Earnings"}</b>
        </href>
      </Nav.Link>
    )

    const profileIcon = this.state.user != null ? (
      <Nav.Link as={NavLink} to="/profile/empty">
        <href className="underlineHover" style={{cursor: "pointer"}}><img
          src={profileImage}
          width="30"
          height="30"
          className="d-inline-block align-top"
        /></href>
      </Nav.Link>
    ) : (
      <href className="underlineHover" style={{cursor: "pointer"}} onClick={() => this.setState({authType: "signUp"})}><img
        alt="Profile icon"
        src={profileImage}
        width="30"
        height="30"
        className="d-inline-block align-top"
      /></href>
    )

    return (
      <div className="App">
      <meta name="apple-mobile-web-app-status-bar-style" content="white"/>
      {authModal}
      {/* !window.schools won't happen right now because we're using constants.js
          What does that mean for newly added schools. The page will need to be closed
          and reopened to open the see the new schools.
      */}
      {!window.schools ? <div>
          <img src={logoImage} style={{marginTop: "10%", width: "10%"}}></img>
          <br></br>
          <br></br>
          <CircularProgress style={{color: "#43495e", marginTop: 10}}/>
        </div> :
        <Router>
          <Navbar collapseOnSelect className="color-nav" expand="lg" variant="dark">
            <Navbar.Brand as={NavLink} to={this.state.user != null ? "/search" : "/"}>
              <href className="navbar-brand" style={{fontFamily:'glacialindifference-bold', fontSize: 20}}>
                StoreStash
                <img
                  alt="StoreStash logo"
                  src={navLogo}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                />
              </href>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link as={NavLink} to="/new_listing/empty"  hidden={window.location.pathname == "/admin"}>
                  <href style={{fontFamily:'glacialindifference-bold', fontSize: 15}}>
                    {(this.state.user && this.state.user.userListingId != "") ? "Edit Listing" : "List Your Space"}
                  </href>
                </Nav.Link>
              </Nav>
              <Nav>
                {/* TODO gwilson: finish the choose school workflow */}
                {/*chooseSchoolButton*/}
                {(this.state.user && this.state.user.userListingId != "") && earningsButton}
                {profileButton}
                {profileIcon}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Route exact path="/" exact render={this.state.user ? (props) => <Search showAuthModal={this.showAuthModal} isLoggedIn={this.isLoggedIn} recentlyCreatedListing={this.state.recentlyCreatedListing} {...props} /> : (props) => <Home isLoggedIn={this.isLoggedIn} showAuthModal={this.showAuthModal} {...props} />} />
          <Route exact path="/search" exact render={(props) => <Search showAuthModal={this.showAuthModal} isLoggedIn={this.isLoggedIn} recentlyCreatedListing={this.state.recentlyCreatedListing} {...props} />} />
          <Route exact path="/search/:status/" exact render={(props) => <Search showAuthModal={this.showAuthModal} isLoggedIn={this.isLoggedIn} recentlyCreatedListing={this.state.recentlyCreatedListing} {...props} />} />
          <Route exact path="/new_listing/:status/" exact render={(props) => <CreateListing handleNewListing={this.handleNewListing} showAuthModal={this.showAuthModal} {...props} />} />
          <Route exact path="/product_info" component={ProductInfo} />
          <Route exact path="/calculate_earnings" exact render={(props) => <CalculateEarnings showAuthModal={this.showAuthModal} {...props} />} />
          <Route exact path="/pricing" exact render={(props) => <Pricing showAuthModal={this.showAuthModal} {...props} />} />
          <Route exact path="/update_status/:id/" component={UpdateStatus} />
          <Route exact path="/details/:id/" exact render={(props) => <ReservationDetails showAuthModal={this.showAuthModal} {...props} />} />
          <Route exact path="/admin" component={AdminDashboard} />
          <Route exact path="/onboard" component={OnboardSchool} />
          <Route exact path="/schools/:id/" component={SchoolDetails} />
          <Route exact path="/earnings" component={Earnings} />
          <Route exact path="/earnings/product_info" component={ProductInfo} />
          <Route exact path="/profile/:id/" exact render={(props) => <Profile recentlyLoggedOut={this.recentlyLoggedOut} {...props} />} />
          <Route path="/confirm_email" exact render={(props) => <ConfirmEmail isLoggedIn={this.isLoggedIn} {...props} />} />
          <Route path="/logout" exact render={(props) => <LogOut recentlyLoggedOut={this.recentlyLoggedOut} {...props} />} />
          <Route path="/login" exact render={(props) => <Login isLoggedIn={this.isLoggedIn} showAuthModal={this.showAuthModal} {...props} />} />
        </Router>
      }
      </div>
    );
  }
}

export default App;
