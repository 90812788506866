import React, { Component } from 'react';
import Selector from './selector';
import { v4 as uuidv4 } from 'uuid';
import FilterDatePickerCalendar from './date_picker';

class InformationView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      boxesOptions: [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
      ],
      locationOptions: [
        { value: "Square on Fifth", label: "Square on Fifth" },
        { value: "Signature West Midtown", label: "Signature West Midtown" },
        { value: "University House Midtown", label: "University House Midtown" },
        { value: "Modera Midtown", label: "Modera Midtown" },
        { value: "The Mark", label: "The Mark" },
        { value: "Catalyst Midtown", label: "Catalyst Midtown" },
        { value: "M Street", label: "M Street" },
        { value: "The Standard", label: "The Standard" },
        { value: "Home Park", label: "Home Park" },
        { value: "All Locations", label: "All Locations" }
      ]
    }
    this.handleFilterIndexChange = this.handleFilterIndexChange.bind(this);
    this.handleSelectorUpdate = this.handleSelectorUpdate.bind(this);
    this.handleSelectedCapacity = this.handleSelectedCapacity.bind(this);
    this.handleSelectedLocations = this.handleSelectedLocations.bind(this);
  }

  handleFilterIndexChange() {
    this.props.handleFilterChange(this.props.currentIndex);
  }

  handleSelectedLocations = (locations) => {
    this.props.handleSelectedLocations(locations);
  }

  handleSelectedCapacity = (capacity) => {
    this.props.handleSelectedCapacity(capacity);
  }

  handleSelectorUpdate = (filters) => {
    this.props.handleSelectorUpdate(filters);
  }

  render() {

    const imageView = (
      <button className="button_empty" onClick={this.handleFilterIndexChange}>
        <img className="img_icon" src={this.props.information.img}></img>
      </button>
    );

    const unselectedView = (
      <ul class="fa-ul" style={{display:"flex", flexDirection:"row",  listStyleType: "none"}}>
        {imageView}
        <li>
            <button className="button_empty" onClick={this.handleFilterIndexChange}>
              <p class="mb-0 text-body" style={{fontSize: 16, marginLeft: 10}}>
                  <b>{this.props.information.bold_text}</b>{this.props.information.extra_text}
              </p>
            </button>
        </li>
      </ul>
    );

    const boxesView = (
      <ul class="fa-ul" style={{display:"flex", flexDirection:"row", listStyleType: "none"}}>
        {imageView}
        <li>
            <Selector defaultValue={this.props.handleSelectedCapacity} closeMenuOnSelect={true} options={this.state.boxesOptions} updateHandler={this.handleSelectedCapacity} ></Selector>
        </li>
      </ul>
    );

    console.log("in information", this.props.residences);

    const locationView = (
      <ul class="fa-ul" style={{display:"flex", flexDirection:"row", listStyleType: "none"}}>
        {imageView}
        <li>
            <Selector style={{width: '500px'}} defaultValue={this.props.selectedFilters} options={this.props.residences} updateHandler={this.handleSelectedLocations} ></Selector>
        </li>
      </ul>
    );

    var chosenView = null;
    if (this.props.selectedIndex == this.props.currentIndex && this.props.currentIndex == 0) {
      chosenView = boxesView;
    } else if (this.props.selectedIndex == this.props.currentIndex && this.props.currentIndex == 1) {
      chosenView = locationView;
    } else {
      chosenView = unselectedView;
    }
    return (
      <div class="row" style={{display: "flex", justifyContent: "space-between", marginRight: 20}}>
        {chosenView}
        <p>3</p>
      </div>
      );
  }
}

export default InformationView;
