import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import Amplify, { API, Cache, graphqlOperation } from 'aws-amplify';
import { schoolsByName } from '.././graphql/queries';
import { BottomNavigation } from '@material-ui/core';
import Footer from './footer';
import { withRouter } from 'react-router';
import MetaTags from 'react-meta-tags';
import * as utils from '.././utils.js';
import LazyLoad from "react-lazyload";
import ReactGA from 'react-ga';
import CarouselStory from './carousel_story';
import FAQ from './faq';

// Animation Imports
import Fade from 'react-reveal/Fade';
import ScrollAnimation from 'react-animate-on-scroll';

// Image Imports
import cheaperImage from '.././assets/home_cheaper.png'
import saferImage from '.././assets/home_safer.png'
import convenientImage from '.././assets/home_convenient.png'
import schoolImage from '.././assets/school.png'
import upperclassmenImage from '.././assets/home_upperclassmen.jpg'
import boxImage from '.././assets/home_box.jpg'
import deliveryImage from '.././assets/home_delivery.jpg'
import productImage from '.././assets/home_product.jpg'
import customerImage from '.././assets/home_product_customer.png'
import listerImage from '.././assets/home_product_lister.png'
import quotesImage from '.././assets/home_quotes.png'

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
      size: 5,
      schoolOptions: [],
      schools: null,
      isMenuOpen: false,
      chosenSchool: null,
      logoIcon: null
    }
    this.selector = React.createRef();
    this.focusSelector = this.focusSelector.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.customerOnboardAction = this.customerOnboardAction.bind(this);
    this.listerOnboardAction = this.listerOnboardAction.bind(this);
    this.schoolChosenAction = this.schoolChosenAction.bind(this);
    this.trackVisit = this.trackVisit.bind(this);
    this.trackVisit();
  }

  async trackVisit() {
    function json(url) {
      return fetch(url).then(res => res.json());
    }

    let ipKey = Cache.getItem("urlPrefix").includes("mystorestash") ? "46f3603931689e390f6ff818aeb1248ddb674f71d4cd21524f6195de" : '0272948ee63cf5f09424f83ec5ff90341f5e23cd0240cc3376725f39';
    json(`https://api.ipdata.co?api-key=${ipKey}`).then(async data => {
      let body = {
        ip: data.ip,
        pageName: "home",
        countryName: data.country_name,
        city: data.city,
        region: data.region,
        postal: data.postal,
        longitude: data.longitude,
        latitude: data.latitude,
      }
      const response = API.post("dynamoApi", "/track-visit", { body });
    });
  }

  focusSelector() {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    this.selector.current.focus();
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillMount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  schoolChosenAction = (chosenSchool) => {
    if (chosenSchool != null && chosenSchool.value == "Other") {
      this.setState({ logoIcon: null});
    } else if (this.state.customerActionQueued || this.state.listerActionQueued) {
      let tempCurrentSchool = chosenSchool != null ? window.schools.find(school => school.id == chosenSchool.value) : null; // Only used for unregistered users
      window.tempSchool = tempCurrentSchool;
      try {
        const action = this.state.customerActionQueued ? 'Customer Interest' : 'Lister Interest';
        if (action == 'Lister Interest') {
          window.isListerInterest = true;
        } else {
          window.isListerInterest = false;
        }
        ReactGA.event({
          category: utils.formatSchoolName(window.tempSchool.name),
          action: action,
          label: 'home.jsx',
        });
      } catch(error) {
        console.log("Failure: " + error.message);
      }
      this.props.history.push("/search");
    } else {
      let tempCurrentSchool = chosenSchool != null ? window.schools.find(school => school.id == chosenSchool.value) : null; // Only used for unregistered users
      window.tempSchool = tempCurrentSchool;
      this.setState({ isMenuOpen: false, chosenSchool: (chosenSchool != null ? chosenSchool : null), logoIcon: (chosenSchool != null ? window.schools.find(school => school.id == chosenSchool.value).iconUrl : null)});
    }
  };

  customerOnboardAction = () => {
    if (this.state.chosenSchool == null) {
      this.focusSelector();
      this.setState({ isMenuOpen: true, customerActionQueued: true, listerActionQueued: false });
    } else {
      try {
        ReactGA.event({
          category: utils.formatSchoolName(this.state.chosenSchool.label),
          action: 'Customer Interest',
          label: 'home.jsx',
        });
      } catch (error) {
        console.log("Failure: " + error.message);
      }
      this.props.history.push("/search");
    }
  };

  listerOnboardAction = () => {
    if (this.state.chosenSchool == null) {
      this.focusSelector();
      this.setState({ isMenuOpen: true, listerActionQueued: true, customerActionQueued: false });
    } else {
      try {
        ReactGA.event({
          category: utils.formatSchoolName(this.state.chosenSchool.label),
          action: 'Lister Interest',
          label: 'home.jsx',
        });
        window.isListerInterest = true;
      } catch (error) {
        console.log("Failure: " + error.message);
      }
      this.props.history.push("/search");
    }
  };

  openMenu = () => {
    this.setState({ isMenuOpen: true });
  };

  closeMenu = () => {
    this.setState({ isMenuOpen: false });
  };

  updateDimensions = () => {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    var size = 0
    if (windowWidth >= 450) {
      size = 1
    }
    if (windowWidth >= 576) {
      size = 2
    }
    if (windowWidth >= 768) {
      size = 3
    }
    if (windowWidth >= 992) {
      size = 4
    }
    if (windowWidth >= 1200) {
      size = 5
    }
    this.setState({windowWidth, size});
  }

  render() {
    const customStyles = {
      control: (base, state) => ({
        ...base,
        fontFamily:'glacialindifference-bold',
        borderColor: '#ffffff00',
        backgroundColor: '#ffffff00',
        boxShadow: "none",
        "&:hover": {
          borderColor: '#ffffff00',
          cursor: 'pointer'
        }
      })
    };

    var schoolOptions = new Array();
    window.schools.map(school => {
      schoolOptions.push({value: school.id, label: school.name})
    })
    schoolOptions.push({value: "Other", label: "Other"})

    const {windowWidth} = this.state;
    let extendCardFourHeight = false;

    let cardIds = ['upperclassmen-text', 'delivery-text', 'pricing-text', 'cta-text'];
    var twoLineCardDict = {'upperclassmen-text': false, 'delivery-text': false, 'pricing-text': false, 'cta-text': false };
    for (var i = 0; i < cardIds.length; i++) {
      let currentId = cardIds[i]
      if (document.getElementById(currentId) != null) {
        let pricingHeight = document.getElementById(currentId).offsetHeight;
        let padding = 32
        let lines = (pricingHeight - padding) / 16;
        twoLineCardDict[currentId] = lines > 1
      }
    }

    var testimonialHeight;
    if (this.state.size == 5) {
      testimonialHeight = 320;
    } else if (this.state.size == 4) {
      testimonialHeight = 365;
    } else if (this.state.size == 3) {
      testimonialHeight = 300;
    } else if (this.state.size == 2) {
      testimonialHeight = 300;
    } else if (this.state.size == 1) {
      testimonialHeight = 320;
    } else if (this.state.size == 0) {
      testimonialHeight = 365;
    }

    return <div>
      <MetaTags>
        <title>StoreStash: Summer storage for college students</title>
        <meta id="meta-description" name="description" content="Summer storage for college students" />
      </MetaTags>
      <Container>
        <Row style={{marginBottom: 40}}>
          <Col sm={this.state.size == 2 ? 12 : 4}>
            <div class="mt-3 mt-xl-5">
              <Fade left>
                <h1 align={this.state.size <= 2 ? "center" : "left"}>The Airbnb of Storage</h1>
              </Fade>
              <h5 class="mb-5" style={{color: "#83848c", fontFamily: 'glacialindifference-regular'}} align={this.state.size < 3 ? "center" : "left"}>Cheap, Safe, & Convenient</h5>
              <div class="mt-5" style={{width: "100%", borderColor: "#dddddd",
                backgroundColor: "#ffffff00", border: '1px solid', height: 50,
                borderRadius: 25, fontSize: "18px", fontFamily:'glacialindifference-regular',
                textAlign: "left", display: "flex", justifyContent: "space-between"}}>
                <b onClick={this.openMenu}><img class="school_logo" alt={this.state.chosenSchool == null ? "School Logo" : this.state.chosenSchool.label} style={{width: 35, height: 35, marginTop: 6, marginLeft: 10}} src={this.state.logoIcon != null ? this.state.logoIcon : schoolImage }></img></b>
                <div style={{width: "90%"}}>
                  <Select
                    styles={customStyles}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder={'Choose your school'}
                    isSearchable={true}
                    onFocus={this.openMenu}
                    openMenuOnClick={true}
                    closeMenuOnSelect={true}
                    blurInputOnSelect={true}
                    onBlur={this.closeMenu}
                    options={schoolOptions}
                    menuIsOpen={this.state.isMenuOpen}
                    onChange={chosenSchool => this.schoolChosenAction(chosenSchool)}
                    ref={this.selector}
                  />
                </div>
              </div>
              <div class={"mt-xl-5 mt-lg-2 mt-md-2 mt-sm-2 mt-5"} style={{width: "100%", display: this.state.size == 0 || this.state.size == 3 || this.state.size == 4   ? "" : "flex", justifyContent: "space-between"}}>
                <button className={"home_onboard_customer" + (this.state.size <= 4 && this.state.size != 1 ? " mt-2" : "")} style={{width: (this.state.size == 4 || this.state.size == 3 || this.state.size == 0) && "100%"}} onClick={this.customerOnboardAction}>Store Your Items</button>
                <button className={"home_onboard_lister" + (this.state.size <= 4 && this.state.size != 1 ? " mt-2" : "")} style={{width: (this.state.size == 4 || this.state.size == 3 || this.state.size == 0) && "100%"}} onClick={this.listerOnboardAction}>List Your Space</button>
              </div>
            </div>
          </Col>

          <Col sm={this.state.size == 2 && 12}>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <Row style={{marginBottom: this.state.size < 3 ? 20 : (20 + 10 * this.state.size)}}>
                <div class="card text-left border-0 zoom" style={{width: this.state.size < 3 ? "45%" : "40%", height: this.state.size == 3 ? 300 : 260, paddingTop: 30, marginLeft: this.state.size < 3 ? "3%" : "17%", backgroundColor: "#ffffff00"}}>
                    <img className="home-fit-img" alt="Student on floor storing items for the summer" src={upperclassmenImage}></img>
                    <p id="upperclassmen-text" className="noselect home-label" style={{fontFamily:'glacialindifference-bold', lineHeight: "16px", paddingTop: twoLineCardDict['upperclassmen-text'] ? 12 : 16, paddingBottom: twoLineCardDict['upperclassmen-text'] ? 18 : 16, paddingLeft: 3, paddingRight: 3, textAlign: "center", backgroundColor: "#565b6e", color: "white", height: 50, borderRadius: "0px 0px 10px 10px"}}>Store items with other students</p>
                </div>
                <div class="card text-left border-0 zoom" style={{width: this.state.size < 3 ? "45%" : "40%", height: this.state.size == 3 ? 300 : 260, paddingTop: 30, marginLeft: "3%", backgroundColor: "#ffffff00"}}>
                  <img className="home-fit-img" alt="College students delivering box" src={deliveryImage}></img>
                  <p id="delivery-text" className="noselect home-label" style={{fontFamily:'glacialindifference-bold', lineHeight: "16px", paddingTop: twoLineCardDict['delivery-text'] ? 12 : 16, paddingBottom: twoLineCardDict['delivery-text'] ? 18 :  16, paddingLeft: 3, paddingRight: 3, textAlign: "center", backgroundColor: "#43495e", color: "white", height: 50, borderRadius: "0px 0px 10px 10px"}}>Get items picked up & delivered</p>
                </div>
              </Row>

              <Row>
                <div class="card text-left border-0 zoom" style={{width: this.state.size < 3 ? "45%" : "40%", height: 300, marginLeft:  this.state.size < 3 ? "3%" : "12%", backgroundColor: "#ffffff00"}}>
                  <img className="home-fit-img" alt="Box on bed with bed for flat storage fee" src={boxImage}></img>
                  <p id="pricing-text" className="noselect home-label" style={{fontFamily:'glacialindifference-bold', lineHeight: "16px", paddingTop: twoLineCardDict['pricing-text'] ? 12 : 16, paddingBottom: twoLineCardDict['pricing-text'] ? 18 : 16, textAlign: "center", backgroundColor: "#43495e", color: "white", borderRadius: "0px 0px 10px 10px"}}>$44 for the entire Summer</p>
                </div>
                <div class="card text-left border-0 zoom" style={{width: this.state.size < 3 ? "45%" : "40%", height: 300, marginBottom: 20, marginLeft: "3%", backgroundColor: "#ffffff00"}}>
                  <img className="home-fit-img" alt="Find your storage space with product listings" src={productImage}></img>
                  <p id="cta-text" className="noselect home-label" style={{fontFamily:'glacialindifference-bold', lineHeight: "16px", paddingTop: twoLineCardDict['pricing-text'] ? 22 : 16, paddingBottom: twoLineCardDict['pricing-text'] ? 22 : 16, textAlign: "center", backgroundColor: "#565b6e", color: "white", borderRadius: "0px 0px 10px 10px"}}>Find your space</p>
                </div>
              </Row>
            </ScrollAnimation>

          </Col>
        </Row>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Row style={{display: "flex", justifyContent: "space-between", marginTop: 150}}>
            <Col className="noselect">
              <div class="col-sm-6 mx-auto" style={{width: 50, height: 50, borderRadius: 40, background: 'linear-gradient(45deg, #fdad16 20%, #fedea2 90%)', border: "2px solid #43495e"}}>
                <img alt="Cheap storage sale icon" style={{width: 25, height: 25, marginTop: 10, marginLeft: -4}} src={cheaperImage}></img>
              </div>
              <h5 style={{color: "#43495e", fontSize: 28, marginTop: 10}}>{"Affordable"}</h5>
              <textarea disabled style={{width: "90%", height: 200, textAlign: "center", backgroundColor: "#ffffff00", color: "#83848c !important", opacity: 0.65, fontSize: 18, rows: 5}} className="empty_textarea">Store your items with fellow students for half the price compared to big box companies' offerings. </textarea>
            </Col>

            <Col className="noselect" md={4} style={{marginTop: this.state.size < 3 ? "-20%" : 0}}>
              <div class="col-sm-6 mx-auto" style={{width: 50, height: 50, borderRadius: 40, background: 'linear-gradient(45deg, #fdad16 20%, #fedea2 90%)', border: "2px solid #43495e"}}>
                <img alt="Convenient storage option thumbs up logo" style={{width: 25, height: 25, marginTop: 10, marginLeft: -4}} src={convenientImage}></img>
              </div>
              <h5 style={{color: "#43495e", fontSize: 28, marginTop: 10}}>{"Stress Free"}</h5>
              <textarea disabled style={{width: "90%", height: 200, textAlign: "center", backgroundColor: "#ffffff00", color: "#83848c !important", opacity: 0.65, fontSize: 18, rows: 5}} className="empty_textarea">Convenience is key at StoreStash. Get your items put away at a time and place that fits your schedule. </textarea>
            </Col>

            <Col className="noselect" md={4} style={{marginTop: this.state.size < 3 ? "-20%" : 0}}>
              <div class="col-sm-6 mx-auto" style={{width: 50, height: 50, borderRadius: 40, background: 'linear-gradient(45deg, #fdad16 20%, #fedea2 90%)', border: "2px solid #43495e"}}>
                <img alt="Safe storage lock icon" style={{width: 25, height: 25, marginTop: 8, marginLeft: -4}} src={saferImage}></img>
              </div>
              <h5 style={{color: "#43495e", fontSize: 28, marginTop: 10}}>{"Safe"}</h5>
              <textarea disabled style={{width: "90%", height: 200, textAlign: "center", backgroundColor: "#ffffff00", color: "#83848c !important", opacity: 0.65, fontSize: 18, rows: 5}} className="empty_textarea">Leverage the trust of your university community by storing with peers who have been verified by our system.</textarea>
            </Col>
          </Row>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Row style={{marginTop: -50}}>
            <Col lg={4} className="noselect" style={{textAlign:"left", marginTop: "10%"}}>
              <h4 style={{fontFamily:'glacialindifference-bold', fontSize: 45, color: "#43495e", textAlign: this.state.size < 3 ? "center" : "left"}}>
                Find a home for your items
              </h4>
              <textarea disabled style={{width: "100%", height: 200, textAlign: this.state.size < 3 ? "center" : "left", backgroundColor: "#ffffff00", color: "#83848c !important", opacity: 0.65, fontSize: 20, rows: 5}} className="empty_textarea">College is complex enough. Let us simplify your storage needs by finding you a place for your items.</textarea>
            </Col>

            <Col className="noselect">
              <LazyLoad>
                <img className="home-product-image" loading="lazy" alt="Find nearby storage spaces with StoreStash listings" style={{width: "100%", marginTop: this.state.size < 4 ? "-20%" : 0}} src={customerImage}></img>
              </LazyLoad>
            </Col>
          </Row>
          <hr style={{marginBottom: 100, marginTop: 30}}></hr>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <h4 style={{fontFamily:'glacialindifference-bold', fontSize: 45, maxWidth: this.state.size < 3 ? "100%" : "50%", color: "#43495e", textAlign: this.state.size < 3 ? "center" : "left", marginTop: 100, marginLeft: "2.5%"}}>
            Storage with nearby upperclassmen
          </h4>
          <LazyLoad>
            <CarouselStory/>
          </LazyLoad>
          <hr style={{marginBottom: 150}}></hr>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Row>
            {this.state.size >= 4 && <Col className="noselect">
              <img className="home-product-image" alt="Post your listing and earn money storing items for classmates" style={{width: "100%"}} src={listerImage}></img>
            </Col>}
            <Col lg={5} className="noselect" style={{textAlign:"left", marginTop: "10%"}}>
              <h4 style={{fontFamily:'glacialindifference-bold', fontSize: 45, color: "#43495e", textAlign: this.state.size < 3 ? "center" : "left"}}>
                Convert your closet into a piggy bank
              </h4>
              <textarea disabled style={{width: "100%", height: 200, textAlign: this.state.size < 3 ? "center" : "left", backgroundColor: "#ffffff00", color: "#83848c !important", opacity: 0.65, fontSize: 20, rows: 5}} className="empty_textarea">Earn passively by storing items in your extra space. Items such as boxes, bikes, and suitcases will only come from verified students at your university. </textarea>
            </Col>
            {this.state.size < 4 && <Col className="noselect">
              <img className="home-product-image" alt="Post your listing and earn money storing items for classmates" style={{width: "100%", marginTop: "-15%"}} src={listerImage}></img>
            </Col>}
          </Row>
          <hr style={{marginBottom: 150, marginTop: 30}}></hr>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Row style={{width: "100%", marginLeft: 0, marginRight: 0}}>
              <h4 style={{fontFamily:'glacialindifference-bold', color: "#43495e", fontSize: 45, width: "100%", textAlign: "center"}}>
                Loved by students
              </h4>
              <textarea disabled style={{width: this.state.size < 4 ? "90%" : "60%", height: this.state.size < 4 ? 300 : 200, marginLeft: this.state.size < 4 ? "5%" : "20%", textAlign: "center", backgroundColor: "#ffffff00", color: "#83848c !important", opacity: 0.65, fontSize: 20, rows: 5}} className="empty_textarea">See what students around the U.S. have to say about their experience using StoreStash. No more breaking the bank, rigid schedules, or hassle with storage units. Welcome to the platform for students by students.</textarea>
          </Row>

          <Row style={{width: "100%", marginTop: -50, marginLeft: 0, marginRight: 0}}>
            <Col className="noselect" lg={4} style={{marginTop: 0}}>
              <div style={{backgroundColor: "#43495e", height: testimonialHeight, borderRadius: 10}}>
                <img className="float-left" alt="Orange quotation mark" src={quotesImage} style={{height: 30, marginTop: 30, marginLeft: "10%"}}></img>
                <br></br>
                <br></br>
                <br></br>
                <p style={{height: this.state.size == 4 || this.state.size == 0 ? 230 :  180, width:"80%", textAlign: "left", backgroundColor: "#ffffff00", color: "white", marginLeft: "10%", fontSize: 18, rows: 5}} className="empty_textarea">StoreStash helped me make over $320 just for storing boxes in my apartment! My roommates and I have a stash piling up in the corner of our living room which now has a new PS5.</p>
                <hr style={{width: "80%", backgroundColor: "white", marginTop: this.state.size == 3 || this.state.size == 2 ? "-5%" : -2}}></hr>
                <p style={{marginLeft: "10%", marginTop: -5, color:"#dddddd", textAlign: "left"}}>Paolo Francisco<b> /
                  </b><button
                        className="button_empty"
                        style={{color: "#fdad16"}}
                        onClick={() => window.open("https://www.gatech.edu/", "_blank")}>
                          <b>Georgia Tech</b>
                      </button>
                </p>
              </div>
            </Col>
            <Col className="noselect" lg={4} style={{marginTop: this.state.size < 4 ? 50 : 0}}>
              <div style={{backgroundColor: "#43495e", height: testimonialHeight, borderRadius: 10}}>
                <img className="float-left" alt="Orange quotation mark" src={quotesImage} style={{height: 30, marginTop: 30, marginLeft: "10%"}}></img>
                <br></br>
                <br></br>
                <br></br>
                <p style={{height: this.state.size == 4 || this.state.size == 0 ? 230 :  180, width:"80%", textAlign: "left", backgroundColor: "#ffffff00", color: "white", marginLeft: "10%", fontSize: 18, rows: 5}} className="empty_textarea">In state students who have cars can earn a bunch from offering delivery. Many international friends already stored boxes with me, so I was able to earn from StoreStash's matches.</p>
                <hr style={{width: "80%", backgroundColor: "white", marginTop: this.state.size == 3 || this.state.size == 2 ? "-5%" : -2}}></hr>
                <p style={{marginLeft: "10%", marginTop: -5, color:"#dddddd", textAlign: "left"}}>Vincent Patrick<b> /
                  </b><button
                        className="button_empty"
                        style={{color: "#fdad16"}}
                        onClick={() => window.open("http://www.washington.edu/", "_blank")}>
                          <b>UW Seattle</b>
                      </button>
                </p>
              </div>
            </Col>
            <Col className="noselect" lg={4} style={{marginTop: this.state.size < 4 ? 50 : 0}}>
              <div style={{backgroundColor: "#43495e", height: testimonialHeight, borderRadius: 10}}>
                <img className="float-left" alt="Orange quotation mark" src={quotesImage} style={{height: 30, marginTop: 30, marginLeft: "10%"}}></img>
                <br></br>
                <br></br>
                <br></br>
                <p style={{height: this.state.size == 4 || this.state.size == 0 ? 230 :  180, width:"80%", textAlign: "left", backgroundColor: "#ffffff00", color: "white", marginLeft: "10%", fontSize: 18, rows: 5}} className="empty_textarea">Easily the cheapest option for storing my items. I got my suitcase picked up right from my dorm and didn't have to worry for the whole summer.</p>
                <hr style={{width: "80%", backgroundColor: "white", marginTop: this.state.size == 3 || this.state.size == 2 ? "-5%" : -2}}></hr>
                <p style={{marginLeft: "10%", marginTop: -5, color:"#dddddd", textAlign: "left"}}>Angela Scaria<b> /
                  </b><button
                        className="button_empty"
                        style={{color: "#fdad16"}}
                        onClick={() => window.open("https://www.gatech.edu/", "_blank")}>
                          <b>Georgia Tech</b>
                      </button>
                </p>
              </div>
            </Col>
          </Row>
        </ScrollAnimation>

        <Row style={{marginTop: 100}}>
          <h4 style={{fontFamily:'glacialindifference-bold', color: "#43495e", fontSize: 45, width: "100%", textAlign: "center"}}>
            Still have questions?
          </h4>
          <FAQ/>
        </Row>
      </Container>
      <Footer props={this.props} showAuthModal={this.props.showAuthModal} style="main-footer"></Footer>
    </div>
  }
}

export default withRouter(Home);
