import Amplify, { Cache } from 'aws-amplify'

export const ITEM_QUANTITY_DICT = {
  "bicycle": 2
}

export const SCHOOLS = !Cache.getItem("urlPrefix") || Cache.getItem("urlPrefix").includes("mystorestash") ?
  [
    {
      "id": "asu67b0c",
      "name": "Arizona State University",
      "type": "School",
      "emailExtension": "@asu.edu",
      "residences": "{\"residences\":[\"Gateway\",\"Union\",\"Sol\",\"Apollo\",\"Atmosphere\"]}",
      "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
      "acronym": "ASU",
      "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/asu-logo.jpeg",
      "packagingAvailable": false,
      "createdAt": "2022-03-23T12:38:40.035Z",
      "longitude": -111.93493999999998,
      "latitude": 33.42192000000006,
      "hex": null,
      "_version": 1,
      "_deleted": null,
      "_lastChangedAt": 1648039120058,
      "updatedAt": "2022-03-23T12:38:40.035Z"
    },
    {
        "id": "bc6965e0",
        "name": "Boston College",
        "type": "School",
        "emailExtension": "@bc.edu",
        "residences": "{\"residences\":[\"Avalon\",\"Continuum Apartments\",\"Commonwealth Apartments\",\"Parkside Place\",\"Nova Apartments\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "BC",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/bc-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-03-28T02:04:43.133Z",
        "longitude": -71.07744999999994,
        "latitude": 42.35165000000006,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1648433083152,
        "updatedAt": "2022-03-28T02:04:43.133Z"
    },
    {
        "id": "cp273c80",
        "name": "Cal Poly SLO",
        "type": "School",
        "emailExtension": "@calpoly.edu",
        "residences": "{\"residences\" : [\"Academy SLO\", \"CalPark Apartments\", \"Casa Bonita Apartments\",\n\"Casa Veranda Townhomes\",\n\"College Gardens Apartments\",\n\"De Tolosa Ranch Apartments\",\n\"Fairview Apartments\",\n\"Ferrini Village Apartments\",\n\"Garfield Arms Apartments\",\n\"Hillrise Manor\",\n\"The Icon SLO\",\n\"Lee Arms Apartments\",\n\"Mustang Village\",\n\"Murray Station\",\n\"Peachtree Apartments\",\n\"Pacific Apartments\",\n\"The SLO\",\n\"Roundhouse Place\",\n\"San Luis Village Apartments\",\n\"Sheri Apartments\",\n\"Stafford Gardens – La Casitas – Triplex\",\n\"Stanmark Apartments\",\n\"Twin Creeks Apartments\",\n\"University Chorro Commons\",\n\"University Foothill Commons\",\n\"University Rockview Place\",\n\"Valencia Apartments\",\n\"Vintage at SLO\"\n]}",
        "breakDateRanges": "{\n  \"Summer Break\": \"2021-06-21+2021-09-20\",\n  \"Winter Break\": \"2021-12-15+2022-01-2\",\n  \"Spring Break\": \"2022-03-22+2022-03-27\"\n}",
        "acronym": "CP",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3-us-west-2.amazonaws.com/logos/cp-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2021-05-13T18:18:56.858Z",
        "longitude": -120.6581,
        "latitude": 35.30039,
        "hex": "#154734",
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1620929936877,
        "updatedAt": "2021-05-13T18:18:56.858Z"
    },
    {
        "id": "cmua4f5c",
        "name": "Central Michigan University",
        "type": "School",
        "emailExtension": "@cmich.edu",
        "residences": "{\"residences\":[\"Southpoint Village\",\"Deerfield Village\",\"Union Square\",\"West Campus Village - West\",\"West Campus Village - East\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "CMU",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/cmu-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-05T02:05:15.225Z",
        "longitude": -84.77555999999998,
        "latitude": 43.59068000000008,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644026715256,
        "updatedAt": "2022-02-05T02:05:15.225Z"
    },
    {
        "id": "csu5709c",
        "name": "Colorado State University",
        "type": "School",
        "emailExtension": "@colostate.edu",
        "residences": "{\"residences\":[\"The District at Campus West \",\"Union on Elizabeth \",\"Union on Plum \",\"Union on Alley\",\"Ram's Village \"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "CSU",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/csu-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-03T22:48:36.798Z",
        "longitude": -105.08155,
        "latitude": 40.578070000000025,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1643928516820,
        "updatedAt": "2022-02-03T22:48:36.798Z"
    },
    {
        "id": "duea2af9",
        "name": "Duke University",
        "type": "School",
        "emailExtension": "@duke.edu",
        "residences": "{\"residences\":[\"Cortland\",\"Trinity Commons\",\"Station 9\",\"Lofts\",\"The Heights\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "DU",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/du-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-03-29T14:24:47.895Z",
        "longitude": -78.93064999999996,
        "latitude": 36.00009000000006,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1648563887917,
        "updatedAt": "2022-03-29T14:24:47.895Z"
    },
    {
        "id": "gw41ea79",
        "name": "George Washington University",
        "type": "School",
        "emailExtension": "@gwu.edu",
        "residences": "{\"residences\":[\"The Avenue Apartments\",\"2400 M Apartments\",\"West End Apartments\",\"Boathouse Apartments\",\"Varsity on K\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "GW",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/gw-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-04T19:36:25.988Z",
        "longitude": -77.05011999999994,
        "latitude": 38.900510000000054,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644003386010,
        "updatedAt": "2022-02-04T19:36:25.988Z"
    },
    {
        "id": "gt0dde06",
        "name": "Georgia Tech",
        "type": "School",
        "emailExtension": "@gatech.edu",
        "residences": "{\"residences\": [\"Signature West Midtown\",\"Square on Fifth\",\"University House Midtown\",\"Modera Midtown\",\"The Mark\",\"Catalyst Midtown\", \"M Street\", \"The Standard\", \"Home Park\"]}",
        "breakDateRanges": "{\n  \"Summer Break\": \"2021-05-08+2021-08-23\",\n  \"Winter Break\": \"2021-12-17+2022-01-10\",\n  \"Spring Break\": \"2022-03-14+2022-03-21\"\n}",
        "acronym": "GT",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3-us-west-2.amazonaws.com/logos/georgia-tech-logo.jpeg",
        "packagingAvailable": true,
        "createdAt": "2021-05-13T18:18:56.858Z",
        "longitude": -84.392929,
        "latitude": 33.771309,
        "hex": "#003057",
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1620929936877,
        "updatedAt": "2021-05-13T18:18:56.858Z"
    },
    {
        "id": "ksuae3c4",
        "name": "Kennesaw State University",
        "type": "School",
        "emailExtension": "@kennesaw.edu",
        "residences": "{\"residences\":[\"Revel100\",\"U Pointe Kennesaw\",\"U Club on Frey\",\"Bixby Kennesaw\",\"SOVA\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "KSU",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/ksu-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-05T01:19:16.636Z",
        "longitude": -84.58129999999994,
        "latitude": 34.03216000000003,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644023956658,
        "updatedAt": "2022-02-05T01:19:16.636Z"
    },
    {
        "id": "luc46aeb",
        "name": "Loyola University Chicago",
        "type": "School",
        "emailExtension": "@luc.edu",
        "residences": "{\"residences\":[\"Beachside Apartments\",\"Vivian Apartments\",\"Kenmore Place\",\"Sheridan Terrace\",\"Sheridan Glen\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "LUC",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/luc-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-04T00:11:50.624Z",
        "longitude": -87.83336999999995,
        "latitude": 41.86166000000003,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1643933510648,
        "updatedAt": "2022-02-04T00:11:50.624Z"
    },
    {
        "id": "mit8ae4d",
        "name": "Massachusetts Institute of Technology",
        "type": "School",
        "emailExtension": "@mit.edu",
        "residences": "{\"residences\": [\"95 Saint\",\"The Overlook at St. Gabriel's\",\"Continuum\",\"Nova Quincy\"]}",
        "breakDateRanges": "{\n  \"Summer Break\": \"2021-05-08+2021-08-23\",\n  \"Winter Break\": \"2021-12-17+2022-01-10\",\n  \"Spring Break\": \"2022-03-14+2022-03-21\"\n}",
        "acronym": "MIT",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/mit-logo.png",
        "packagingAvailable": true,
        "createdAt": "2021-05-13T18:18:56.858Z",
        "longitude": -71.09314,
        "latitude": 42.359245,
        "hex": "#A31F34",
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1620929936877,
        "updatedAt": "2021-05-13T18:18:56.858Z"
    },
    {
        "id": "muac9181",
        "name": "Marquette University",
        "type": "School",
        "emailExtension": "@marquette.edu ",
        "residences": "{\"residences\":[\"Modern Living\",\"The Lark\",\"Maryland Court\",\"Union Street Apartments \",\"Renee Row\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "MU",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/default-logo.png",
        "packagingAvailable": false,
        "createdAt": "2022-02-12T01:39:29.438Z",
        "longitude": -87.92777999999998,
        "latitude": 43.03833000000003,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644629969475,
        "updatedAt": "2022-02-12T01:39:29.438Z"
    },
    {
        "id": "mu230454",
        "name": "Miami University - Oxford ",
        "type": "School",
        "emailExtension": "@miamioh.edu",
        "residences": "{\"residences\":[\"Level 27\",\"Annex\",\"The Verge \",\"Fox and Hounds \",\"Miami Preserve \"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "MU",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/mu-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-10T16:56:20.176Z",
        "longitude": -84.73449999999997,
        "latitude": 39.50950000000006,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644512180207,
        "updatedAt": "2022-02-10T16:56:20.176Z"
    },
    {
        "id": "msu6c584",
        "name": "Michigan State University",
        "type": "School",
        "emailExtension": "@msu.edu",
        "residences": "{\"residences\":[\"Campus Village\",\"University Edge\",\"Campus View\",\"Cedar Village\",\"College Towne\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "MSU",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/msu-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-05T02:14:58.435Z",
        "longitude": -84.48040999999995,
        "latitude": 42.729460000000074,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644027298456,
        "updatedAt": "2022-02-05T02:14:58.435Z"
    },
    {
        "id": "ne64c551",
        "name": "Northeastern University",
        "type": "School",
        "emailExtension": "@northeastern.edu",
        "residences": "{\"residences\": [\"LightView\",\"Lower Roxbury Housing\",\"Roxse Homes\",\"Mission Main Apartments\",\"Westland Apartments\"]}",
        "breakDateRanges": "{\n  \"Summer Break\": \"2021-06-21+2021-09-20\",\n  \"Winter Break\": \"2021-12-15+2022-01-2\",\n  \"Spring Break\": \"2022-03-22+2022-03-27\"\n}",
        "acronym": "NEU",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/neu-logo.png",
        "packagingAvailable": false,
        "createdAt": "2021-05-13T18:18:56.858Z",
        "longitude": -71.08889,
        "latitude": 42.34008,
        "hex": "#D41B2C",
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1620929936877,
        "updatedAt": "2021-05-13T18:18:56.858Z"
    },
    {
        "id": "nau9d57e",
        "name": "Northern Arizona University",
        "type": "School",
        "emailExtension": "@nau.edu",
        "residences": "{\"residences\":[\"The Standard - Flagstaff\",\"Freemont stations\",\"The Grove \",\"Uncommons\",\"Hilltop\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "NAU",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/nau-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-05T02:38:26.435Z",
        "longitude": -111.65415999999999,
        "latitude": 35.177140000000065,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644028706459,
        "updatedAt": "2022-02-05T02:38:26.435Z"
    },
    {
        "id": "osu02278",
        "name": "Oregon State University",
        "type": "School",
        "emailExtension": "@oregonstate.edu",
        "residences": "{\"residences\":[\"Oak Park Apartments\",\"Tyler Townhomes\",\"23rd Street Commons\",\"7th Street Station\",\"Oak Vale Apartments\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "OSU",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/osu-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-03T20:29:30.431Z",
        "longitude": -123.27414999999996,
        "latitude": 44.56453000000005,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1643920170450,
        "updatedAt": "2022-02-03T20:29:30.431Z"
    },
    {
        "id": "psu9b5ec",
        "name": "Penn State University ",
        "type": "School",
        "emailExtension": "@psu.edu",
        "residences": "{\"residences\":[\"The Heights\",\"The Retreat\",\"The Pointe\",\"The Yard at Old State\",\"Copper Beech\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "PSU",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/psu-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-04T19:31:37.326Z",
        "longitude": -77.85886999999997,
        "latitude": 40.80709000000007,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644003097348,
        "updatedAt": "2022-02-04T19:31:37.326Z"
    },
    {
        "id": "pu979c10",
        "name": "Purdue University",
        "type": "School",
        "emailExtension": "@purdue.edu",
        "residences": "{\"residences\":[\"Campus Edge on Pierce\",\"Aspire at Discovery park\",\"FUSE\",\"Crestview Apartments\",\"Launch Apartments\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "PU",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/pu-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-05T01:03:38.019Z",
        "longitude": -86.91443999999996,
        "latitude": 40.42930000000007,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644023018040,
        "updatedAt": "2022-02-05T01:03:38.019Z"
    },
    {
        "id": "ru99fb52",
        "name": "Rutgers University ",
        "type": "School",
        "emailExtension": "@rutgers.edu",
        "residences": "{\"residences\":[\"Verve New Brunswick\",\"The Hub on Morrell\",\"Premier Properties of New Brunswick\",\"RU Living\",\"The Standard at New Brunswick\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "RU",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/ru-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-04T03:01:53.840Z",
        "longitude": -74.17523999999997,
        "latitude": 40.73929000000004,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1643943713873,
        "updatedAt": "2022-02-04T03:01:53.840Z"
    },
    {
        "id": "sf8519f1",
        "name": "Santa Fe College",
        "type": "School",
        "emailExtension": "@sfcollege.edu",
        "residences": "{\"residences\":[\"Liv+ Gainesville\",\"Crossing at Santa Fe Apartments\",\"Hideaway Gainesville\",\"The Retreat at Gainesville\",\"West 20\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "SF",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/sf-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-10T17:58:11.790Z",
        "longitude": -82.43034999999998,
        "latitude": 29.680430000000058,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644515891818,
        "updatedAt": "2022-02-10T17:58:11.790Z"
    },
    {
        "id": "cua197c5",
        "name": "The Catholic University of America",
        "type": "School",
        "emailExtension": "@cua.edu",
        "residences": "{\"residences\":[\"Cornerstone Apartments \",\"Everton Apartments\",\"Portland Flats\",\"Brookland Works\",\"The Cloisters\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "CUA",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/cua-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-04T15:56:24.210Z",
        "longitude": -76.99786420499998,
        "latitude": 38.93282952800007,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1643990184230,
        "updatedAt": "2022-02-04T15:56:24.210Z"
    },
    {
        "id": "ut27b27f",
        "name": "The University of Texas at Austin",
        "type": "School",
        "emailExtension": "@utexas.edu",
        "residences": "{\"residences\":[\"Lark Austin\",\"Moontower\",\"Ion Austin\",\"Legacy on Rio\",\"Torrè\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "UT",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/ut-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-04T22:13:11.518Z",
        "longitude": -97.73244999999997,
        "latitude": 30.278720000000078,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644012791540,
        "updatedAt": "2022-02-04T22:13:11.518Z"
    },
    {
        "id": "ucb3e725",
        "name": "University of California, Berkeley",
        "type": "School",
        "emailExtension": "@berkeley.edu",
        "residences": "{\"residences\":[\"Varsity Berkeley\",\"Sterling Berkeley - Allston\",\"Aquatic Shattuck\",\"Garden Village\",\"Sterling Berkeley - Haste\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "UCB",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/ucb-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-04T07:55:08.531Z",
        "longitude": -122.25928999999996,
        "latitude": 37.86948000000007,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1643961308567,
        "updatedAt": "2022-02-04T07:55:08.531Z"
    },
    {
        "id": "ucla2385",
        "name": "University of California, Los Angeles",
        "type": "School",
        "emailExtension": "@ucla.edu",
        "residences": "{\"residences\":[\"Midvale Luxury Apartments\",\"Club California\",\"Village Villa Apartments\",\"The Atrium\",\"Levering Heights\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "UCLA",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/ucla-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-05T04:58:27.400Z",
        "longitude": -118.43802,
        "latitude": 34.07394000000005,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644037107438,
        "updatedAt": "2022-02-05T04:58:27.400Z"
    },
    {
        "id": "ucsc02fb",
        "name": "University of California, Santa Cruz",
        "type": "School",
        "emailExtension": "@ucsc.edu",
        "residences": "{\"residences\":[\"Outlook Apartments\",\"Cypress Point Apartments\",\"Hilltop Apartments\",\"Chestnut Street Apartments\",\"1010 Pacific Apartments\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "UCSC",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/ucsc-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-04T03:13:08.832Z",
        "longitude": -122.05225999999999,
        "latitude": 36.977460000000065,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1643944388871,
        "updatedAt": "2022-02-04T03:13:08.832Z"
    },
    {
        "id": "ucfcbd08",
        "name": "University of Central Florida ",
        "type": "School",
        "emailExtension": "@ucf.edu",
        "residences": "{\"residences\":[\"Hub\",\"The retreat east\",\"Plaza on University \",\"The Station\",\"The verge\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "UCF",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/default-logo.png",
        "packagingAvailable": false,
        "createdAt": "2022-02-04T03:13:10.920Z",
        "longitude": -81.20342999999997,
        "latitude": 28.59677000000005,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1643944390941,
        "updatedAt": "2022-02-04T03:13:10.920Z"
    },
    {
        "id": "ufbdf636",
        "name": "University of Florida ",
        "type": "School",
        "emailExtension": "@ufl.edu",
        "residences": "{\"residences\":[\"Liv+ Gainesville \",\"The Standard \",\"The Hub \",\"Redpoint Gainesville\",\"Alight Gainesville \"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "UF",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/uf-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-07T23:16:23.692Z",
        "longitude": -82.34112999999996,
        "latitude": 29.649730000000034,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644275783693,
        "updatedAt": "2022-02-07T23:16:23.692Z"
    },
    {
        "id": "uhm5c732",
        "name": "University of Hawaiʻi at Mānoa",
        "type": "School",
        "emailExtension": "@hawaii.edu",
        "residences": "{\"residences\":[\"Hale Mahana Apartments\",\"The Residences at Bishop Place Apartments\",\"Waikiki Walina Apartments\",\"Moana Vista Apartments\",\"Ala Moana-Kakaako Apartments\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "UHM",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/default-logo.png",
        "packagingAvailable": false,
        "createdAt": "2022-02-12T03:13:21.678Z",
        "longitude": -157.82015999999996,
        "latitude": 21.299380000000042,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644635601696,
        "updatedAt": "2022-02-12T03:13:21.678Z"
    },
    {
        "id": "umb949f7",
        "name": "University of Massachusetts Boston",
        "type": "School",
        "emailExtension": "@umb.edu",
        "residences": "{\"residences\":[\"Harbor Point on the Bay\",\"Peninsula Apartments\",\"Marina Bay\",\"Hub25 Apartments\",\"Continuum\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "UMB",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/default-logo.png",
        "packagingAvailable": false,
        "createdAt": "2022-02-05T01:24:21.606Z",
        "longitude": -71.03701999999998,
        "latitude": 42.312820000000045,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644024261640,
        "updatedAt": "2022-02-05T01:24:21.606Z"
    },
    {
        "id": "unc9436c",
        "name": "University of North Carolina at Chapel Hill",
        "type": "School",
        "emailExtension": "@unc.edu",
        "residences": "{\"residences\":[\"Union\",\"Lark\",\"Autumn Woods\",\"Trilogy\",\"Berkshire\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "UNC",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/unc-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-05T15:09:44.564Z",
        "longitude": -79.05177699999996,
        "latitude": 35.908949000000064,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644073784593,
        "updatedAt": "2022-02-05T15:09:44.564Z"
    },
    {
        "id": "uo42je83",
        "name": "University of Oregon",
        "type": "School",
        "emailExtension": "@uoregon.edu",
        "residences": "{\"residences\": [\"Von Klein\",\"Jennings Group\",\"Chinook Properties Apartments\", \"1 BR\", \"Duck Village\", \"2125 Franklin\"]}",
        "breakDateRanges": "{\n  \"Summer Break\": \"2021-06-12+2021-09-28\",\n  \"Winter Break\": \"2021-12-18+2022-01-02\",\n  \"Spring Break\": \"2022-03-19+2022-03-27\"\n}",
        "acronym": "UO",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3-us-west-2.amazonaws.com/logos/uo-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2021-05-13T18:18:56.858Z",
        "longitude": -123.069519,
        "latitude": 44.045559,
        "hex": "#004F39",
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1620929936877,
        "updatedAt": "2021-05-13T18:18:56.858Z"
    },
    {
        "id": "usce38c8",
        "name": "University of Southern California",
        "type": "School",
        "emailExtension": "@usc.edu",
        "residences": "{\"residences\":[\"The Lorenzo\",\"Icon Plaza\",\"University Gateway\",\"Tuscany\",\"Trojan Plaza\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "USC",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/usc-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-03T07:28:52.236Z",
        "longitude": -118.28319999999997,
        "latitude": 34.01892000000004,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1643873332264,
        "updatedAt": "2022-02-03T07:28:52.236Z"
    },
    {
        "id": "uw79aa40",
        "name": "University of Washington",
        "type": "School",
        "emailExtension": "@uw.edu",
        "residences": "{\"residences\": [\"Cedar Apartments\",\"Commodore Duchess\",\"Nordheim Court\",\"Radford Court\",\"Blakely Village\",\"Laurel Village\", \"M Street\", \"Radford Court\", \"Stevens Court\"]}",
        "breakDateRanges": "{\n  \"Summer Break\": \"2021-06-12+2021-09-28\",\n  \"Winter Break\": \"2021-12-18+2022-01-02\",\n  \"Spring Break\": \"2022-03-19+2022-03-27\"\n}",
        "acronym": "UW",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3-us-west-2.amazonaws.com/logos/uw-logo.jpeg",
        "packagingAvailable": true,
        "createdAt": "2021-05-13T18:18:56.858Z",
        "longitude": -122.29966,
        "latitude": 47.655071,
        "hex": "#4b2e83",
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1620929936877,
        "updatedAt": "2021-05-13T18:18:56.858Z"
    },
    {
        "id": "uwde665a",
        "name": "University of Wisconsin-Madison",
        "type": "School",
        "emailExtension": "@wisc.edu",
        "residences": "{\"residences\":[\"Lucky Apartments\",\"The Hub\",\"The James\",\"Waterfront Apartments\",\"The Roundhouse\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "UWM",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/uwm-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-12T20:33:40.878Z",
        "longitude": -89.39662999999996,
        "latitude": 43.07280000000003,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1644698020911,
        "updatedAt": "2022-02-12T20:33:40.878Z"
    },
    {
        "id": "vta9973b",
        "name": "Virginia Polytechnic Institute and State University",
        "type": "School",
        "emailExtension": "@vt.edu",
        "residences": "{\"residences\":[\"The Edge\",\"The Hub\",\"Alight (Village)\",\"Foxridge\",\"The Retreat\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "VT",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/vt-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2022-02-03T23:01:17.184Z",
        "longitude": -80.42267999999996,
        "latitude": 37.22864000000004,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1643929277214,
        "updatedAt": "2022-02-03T23:01:17.184Z"
    }
  ]
 :
  [
    {
        "id": "cp273c80",
        "name": "Cal Poly",
        "type": "School",
        "emailExtension": "@calpoly.edu",
        "residences": "{\"residences\" : [\"Academy SLO\", \"CalPark Apartments\", \"Casa Bonita Apartments\",\n\"Casa Veranda Townhomes\",\n\"College Gardens Apartments\",\n\"De Tolosa Ranch Apartments\",\n\"Fairview Apartments\",\n\"Ferrini Village Apartments\",\n\"Garfield Arms Apartments\",\n\"Hillrise Manor\",\n\"The Icon SLO\",\n\"Lee Arms Apartments\",\n\"Mustang Village\",\n\"Murray Station\",\n\"Peachtree Apartments\",\n\"Pacific Apartments\",\n\"The SLO\",\n\"Roundhouse Place\",\n\"San Luis Village Apartments\",\n\"Sheri Apartments\",\n\"Stafford Gardens – La Casitas – Triplex\",\n\"Stanmark Apartments\",\n\"Twin Creeks Apartments\",\n\"University Chorro Commons\",\n\"University Foothill Commons\",\n\"University Rockview Place\",\n\"Valencia Apartments\",\n\"Vintage at SLO\"\n]}",
        "breakDateRanges": "{\n  \"Summer Break\": \"2021-06-21+2021-09-20\",\n  \"Winter Break\": \"2021-12-15+2022-01-2\",\n  \"Spring Break\": \"2022-03-22+2022-03-27\"\n}",
        "acronym": "CP",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3-us-west-2.amazonaws.com/logos/cp-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2021-05-13T18:18:56.858Z",
        "longitude": -120.6581,
        "latitude": 35.30039,
        "hex": "#154734",
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1620929936877,
        "updatedAt": "2021-05-13T18:18:56.858Z"
    },
    {
        "id": "csu86ddf",
        "name": "Colorado State",
        "type": "School",
        "emailExtension": "@colostate.edu",
        "residences": "{\"residences\":[\"1\",\"2\",\"3\",\"4\",\"5\"]}",
        "breakDateRanges": "{\"Summer Break\":\"2022-05-08+2022-08-23\",\"Winter Break\":\"2022-12-17+2023-01-10\",\"Spring Break\":\"2023-03-14+2023-03-21\"}",
        "acronym": "CSU",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3.us-west-2.amazonaws.com/logos/default-logo.png",
        "packagingAvailable": false,
        "createdAt": "2022-02-04T01:32:57.473Z",
        "longitude": -105.54781637299999,
        "latitude": 38.99855056200005,
        "hex": null,
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1643938377506,
        "updatedAt": "2022-02-04T01:32:57.473Z"
    },
    {
        "id": "gt0dde06",
        "name": "Georgia Tech",
        "type": "School",
        "emailExtension": "@gatech.edu",
        "residences": "{\"residences\": [\"Signature West Midtown\",\"Square on Fifth\",\"University House Midtown\",\"Modera Midtown\",\"The Mark\",\"Catalyst Midtown\", \"M Street\", \"The Standard\", \"Home Park\"]}",
        "breakDateRanges": "{\n  \"Summer Break\": \"2021-05-08+2021-08-23\",\n  \"Winter Break\": \"2021-12-17+2022-01-10\",\n  \"Spring Break\": \"2022-03-14+2022-03-21\"\n}",
        "acronym": "GT",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3-us-west-2.amazonaws.com/logos/georgia-tech-logo.jpeg",
        "packagingAvailable": true,
        "createdAt": "2021-05-13T16:07:36.730Z",
        "longitude": -84.392929,
        "latitude": 33.771309,
        "hex": "#003057",
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1620922056752,
        "updatedAt": "2021-05-13T16:07:36.730Z"
    },
    {
        "id": "gm0dde06",
        "name": "Google",
        "type": "School",
        "emailExtension": "@gmail.com",
        "residences": "{\"residences\": [\"Signature West Midtown\",\"Square on Fifth\",\"University House Midtown\",\"Modera Midtown\",\"The Mark\",\"Catalyst Midtown\", \"M Street\", \"The Standard\", \"Home Park\"]}",
        "breakDateRanges": "{\n  \"Summer Break\": \"2021-05-08+2021-08-23\",\n  \"Winter Break\": \"2021-12-17+2022-01-10\",\n  \"Spring Break\": \"2022-03-14+2022-03-21\"\n}",
        "acronym": "GM",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3-us-west-2.amazonaws.com/logos/georgia-tech-logo.jpeg",
        "packagingAvailable": true,
        "createdAt": "2021-05-13T16:07:36.730Z",
        "longitude": -122.084267,
        "latitude": 37.422161,
        "hex": "#1aa260",
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1620922056752,
        "updatedAt": "2021-05-13T16:07:36.730Z"
    },
    {
        "id": "uw79aa40",
        "name": "University of Washington",
        "type": "School",
        "emailExtension": "@uw.edu",
        "residences": "{\"residences\": [\"Cedar Apartments\",\"Commodore Duchess\",\"Nordheim Court\",\"Radford Court\",\"Blakely Village\",\"Laurel Village\", \"M Street\", \"Radford Court\", \"Stevens Court\"]}",
        "breakDateRanges": "{\n  \"Summer Break\": \"2021-06-12+2021-09-28\",\n  \"Winter Break\": \"2021-12-18+2022-01-02\",\n  \"Spring Break\": \"2022-03-19+2022-03-27\"\n}",
        "acronym": "UW",
        "iconUrl": "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3-us-west-2.amazonaws.com/logos/uw-logo.jpeg",
        "packagingAvailable": false,
        "createdAt": "2021-05-13T16:12:29.830Z",
        "longitude": -122.29966,
        "latitude": 47.655071,
        "hex": "#4b2e83",
        "_version": 1,
        "_deleted": null,
        "_lastChangedAt": 1620922349855,
        "updatedAt": "2021-05-13T16:12:29.830Z"
    }
]
