import React, { Component } from 'react';
import Amplify, { Auth, Cache } from 'aws-amplify'
import './auth.css';
import { withRouter } from "react-router-dom";
import * as reservationCache from './reservation_cache.js';
import { CircularProgress } from '@material-ui/core';
import * as utils from '.././utils.js';
import ReactGA from 'react-ga';

class ConfirmEmail extends Component {
    constructor(props) {
      super(props);
      this.state = {
        email: this.props.authData.email,
        password: this.props.authData.password,
        forgotPassword: this.props.authData.forgotPassword,
        code: '',
        error: '',
        loadingSubmit: false,
        showForgotPasswordViews: false
      }
      this.handleCodeChange = this.handleCodeChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleResendCode = this.handleResendCode.bind(this);
      this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    }

    handleNewPasswordChange(event) {
      this.setState({newPassword: event.target.value});
    }

    handleCodeChange(event) {
      this.setState({code: event.target.value});
    }

    async handleResendCode() {
      this.setState({loadingSubmit : true});
      try {
        if (this.state.forgotPassword) {
          await Auth.forgotPassword(this.state.email);
        } else {
          await Auth.resendSignUp(this.state.email);
        }
        console.log('code resent successfully');
        this.setState({loadingSubmit : false});
      } catch (error) {
        console.log('error resending code: ', error);
        this.setState({
          error: error.message,
          loadingSubmit : false
        });
      }
    }

    async confirmSignUp() {
      let username = this.state.email;
      let password = this.state.password;
      let newPassword = this.state.newPassword;
      let code = this.state.code;
      try {
        // User inputs code and is prompted to enter a new password
        if (this.state.showForgotPasswordViews) {
          console.log(code, username, "submit details...");
          let response = await Auth.forgotPasswordSubmit(username, code, newPassword);
          console.log(response, "forgotPasswordSubmit");
          const user = await Auth.signIn(username, newPassword);
          console.log(user, "signIn");
        } else if (this.state.forgotPassword) {
          this.setState({showForgotPasswordViews: true, loadingSubmit: false});
          return;
        } else {
          // Last step of regular sign up flow
          const currentSchoolName = utils.getCurrentSchoolAttribute(username, "name");
          await Auth.confirmSignUp(username, code);
          ReactGA.event({
            category: utils.formatSchoolName(currentSchoolName),
            action: 'Sign Up Complete',
            label: 'confirm_email.jsx',
          });
          await Auth.signIn(username, password);
        }
        this.setState({loadingSubmit : false});
        reservationCache.updateReservation("loggedIn", true);
        this.props.isLoggedIn();
        let user_id = username.substr(0, username.indexOf('@'));
        reservationCache.updateReservation("customer_id", user_id);
        this.props.showAuthModal("");
      } catch (error) {
        console.log("caught error:", error);
        this.setState({
          error: error.message,
          loadingSubmit : false
        });
      }
    }

    handleSubmit(event) {
      this.confirmSignUp();
      event.preventDefault();
    }

    render() {
      var formFields = (
        <form style={{marginBottom: 5}} onSubmit={this.handleSubmit}>
          <p style={{color:"#43495e", marginRight: 15, marginLeft: 15}}>{"Check your school email AND spam folder for a verification code."}</p>
          <input className="fadeInDown" type="number" id="code" placeholder="Code" value={this.state.code} onChange={this.handleCodeChange}/>
          <div className="text-danger">{this.state.error}</div>
          {this.state.loadingSubmit && <div><CircularProgress style={{color: "#fdad16", marginTop:10}}/></div>}
          <button className="home_onboard_customer fadeInDown first" onClick={() => this.setState({loadingSubmit : true})} style={{width: "80%", marginTop: 5}}>Submit</button>
        </form>
      );
      if (this.state.showForgotPasswordViews) {
        formFields = (
          <form style={{marginBottom: 5}} onSubmit={this.handleSubmit}>
            <input type="password" id="password" class="fadeInDown first" placeholder="New Password" value={this.state.newPassword} onChange={this.handleNewPasswordChange}/>
            <div className="text-danger">{this.state.error}</div>
            {this.state.loadingSubmit && <div><CircularProgress style={{color: "#fdad16", marginTop:10}}/></div>}
            <button className="home_onboard_customer fadeInDown first" onClick={() => this.setState({loadingSubmit : true})} style={{width: "80%", marginTop: 5}}>Reset Password</button>
          </form>
        )
      }

      return (
        <div>
          <div id="formContent">
            <p style={{marginTop: 10}} className="brand-registration">StoreStash</p>
              {formFields}
            <div id="formFooter">
              <div><button onClick={this.handleResendCode} className="button_empty underlineHover">Resend Code</button></div>
            </div>
        </div>
      </div>
    );
  }
}

export default ConfirmEmail;
