import React, { Component } from 'react';
import {
  CWidgetDropdown,
  CRow,
  CCol,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CDropdownToggle,
  CCardGroup,
  CCardFooter,
  CLink,
  CWidgetProgress,
  CWidgetIcon,
  CWidgetProgressIcon,
  CWidgetSimple,
  CProgress
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Container, Row, Col } from 'react-bootstrap';
import ChartLineSimple from './ChartLineSimple'
import ChartBarSimple from './ChartBarSimple'
import MainChartExample from './ChartMain.js'
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarContent } from 'react-pro-sidebar';
import './sidebar.css';
import 'react-pro-sidebar/dist/css/styles.css';
import Amplify, { Auth, API } from 'aws-amplify'
import { CircularProgress } from '@material-ui/core';
import ContactUs from './contact_us';
import FadeIn from 'react-fade-in';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import VisibilitySensor from "react-visibility-sensor";

import boxesImage from '.././assets/boxes.png'
import emailImage from '.././assets/dashboard_email.png'
import flyerImage from '.././assets/dashboard_flyer.png'
import listerImage from '.././assets/dashboard_lister.png'
import userImage from '.././assets/dashboard_user.png'
import emptyPayments from '.././assets/empty_payments.png'
import * as utils from '.././utils.js';

class AdminDashboard extends Component {

  state = {
    trafficBySchool: null,
    selectedPanel: "Payments",
    allTraffic: [],
    showAccessDenied: false,
    showContactUs: false,
    showMarketerPayments: false,
    showPayments: true,
    showWaysToEarn: false,
    showChart: true,
    isShowingMore: false,
    emails: [],
    flyers: [],
    rewardThresholds: { // Object to reach for each marketing category
      emails: 10,
      flyers: 15,
      users: 30,
      listers: 10,
      customers: 10,
    },
    // $6   per   10  emails    (1 time per week)
    // $8   per   10  flyers    (1 time)
    // $20  per   10  customers (all time)
    // $15  per   10  customers (1 time)
    rewardAmounts: { // Dollar amount for each threshold reached
      emails: 6,
      flyers: 8,
      users: 15,
      listers: 15,
      customers: 20,
    },
    personalSignUps: [],
    personalListers: [],
    personalCustomers: [],
    teamSignUps: [],
    teamListers: [],
    teamCustomers: [],
    teamTraffic: [],
    payments: []
  }

  constructor(props) {
    super(props);
    this.getAllTraffic = this.getAllTraffic.bind(this);
    this.getAllEmails = this.getAllEmails.bind(this);
    this.getAllUsers = this.getAllUsers.bind(this);
    this.getTeamStats = this.getTeamStats.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.parsePayments = this.parsePayments.bind(this);
    this.isLoggedIn();
  }

  componentDidMount() {
    this.getAllTraffic();
    this.getAllEmails();

    this.getTeamStats();
    //mobile rendering event listener
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillMount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async isLoggedIn() {
    try {
      let user = await Auth.currentAuthenticatedUser();
      if (this.state.user == null) {
        let isMarketer = user.attributes['custom:isMarketer'];
        let isAdmin = user.attributes['custom:isAdmin'];
        console.log(isAdmin, "admin?");
        console.log(isMarketer, "marketer?");
        let currentUser = {
          isMarketer: isMarketer != null ? isMarketer : "",
          isAdmin: isAdmin != null ? isAdmin : "",
          email: user.attributes.email,
          username: user.attributes.email.substr(0, user.attributes.email.indexOf('@'))
        }
        if (!isMarketer && !isAdmin) {
          setTimeout(()=> this.setState({showAccessDenied: true}), 3000);
        }
        this.setState({user: currentUser});
      }
      return true;
    } catch(err) {
      console.log("No user", err);
      setTimeout(()=> this.setState({showAccessDenied: true}), 3000);
    }
  }

  closeModal() {
    this.setState({showContactUs: false})
  }

  parsePayments(cognitoStats) {
    var totalPayout = 0;
    var payments = []
    var rewardsToParse = ['Purchase'];

    // $6   per   10  emails    (1 time per week)
    // $8   per   10  flyers    (1 time)
    // $20  per   10  customers (all time)
    // $15  per   10  customers (1 time)
    var rewards = [
      { count: 0, rewardAmount: 6, rewardThreshold: this.state.rewardThresholds.emails,  name: 'Emails', data: this.state.emails},
      { count: 0, rewardAmount: 8, rewardThreshold: this.state.rewardThresholds.flyers,  name: 'Flyers', data: this.state.flyers},
      { count: 0, rewardAmount: 15, rewardThreshold: this.state.rewardThresholds.users, name: 'Sign Ups', data: this.state.personalSignUps},
      { count: 0, rewardAmount: 15, rewardThreshold: this.state.rewardThresholds.listers, name: 'Listers', data: this.state.personalListers},
      { count: 0, rewardAmount: 20, rewardThreshold: this.state.rewardThresholds.customers, name: 'Purchase', data: this.state.personalCustomers},
    ]
    for (var i = 0; i < rewards.length; i++) {
      let currentReward = rewards[i];
      for (var j = 0; j < currentReward.data.length; j++) {
        currentReward.count += 1
        if (currentReward.count % currentReward.rewardThreshold == 0) {
          totalPayout += currentReward.rewardAmount;
          payments.push({
            amount: currentReward.rewardAmount,
            reason: String(currentReward.rewardThreshold) + " " + currentReward.name,
            date: rewardsToParse.includes(currentReward.name) ? currentReward.data[j].createdAt.S : currentReward.data[j].createdAt
          });
        }
      }
    }
    payments.sort((payment1, payment2) => (Date.parse(payment1.date) < Date.parse(payment2.date)) ? 1 : -1);
    this.setState({payments: payments, totalPayout: totalPayout});
  }

  // Triggered when the window is resized
  updateDimensions = () => {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let mobile = windowWidth < 600 ? true : false;
    var size = 0
    if (windowWidth >= 450) {
      size = 1
    }
    if (windowWidth >= 576) {
      size = 2
    }
    if (windowWidth >= 768) {
      size = 3
    }
    if (windowWidth >= 992) {
      size = 4
    }
    if (windowWidth >= 1200) {
      size = 5
    }
    this.setState({windowWidth, size, mobile});
  }

  async getAllUsers() {
    if (this.state.user) {
      let body = {
        email: this.state.user.email,
        permissionLevel: "isAdmin"
      }
      const response = await API.post("cognitoApi", "/get-marketer-cognito-stats", { body });
      this.setState({
        personalSignUps: response.signUps,
        personalListers: response.listers,
        personalCustomers: response.customers
      });
    }
  }

  async getTeamStats() {
    let body = {
      startDateDaysAgo: 50,
      endDateDaysAgo: 0
    }
    const response = await API.post("cognitoApi", "/get-team-cognito-stats", { body });

    // Set up the empty list that will hold all the counts sign ups
    let columnsToShow = 10;
    let dateRangeIntervalSize = (body.startDateDaysAgo - body.endDateDaysAgo) / columnsToShow;

    var allStatsMetrics = [[], [], [], []];
    for (var i = 0; i < columnsToShow; i++) {
      allStatsMetrics[0][i] = 0;
      allStatsMetrics[1][i] = 0;
      allStatsMetrics[2][i] = 0;
      allStatsMetrics[3][i] = 0;
    }

    let stats = [response.signUps, response.listers, response.customers, this.state.allTraffic];
    for (var j = 0; j < stats.length; j++) {
      let currentStat = stats[j];
      for (var i = 0; i < currentStat.length; i++) {
        let currentMetric = currentStat[i];
        let createdAtDate = currentMetric.createdAt.slice(0, 10);

        // Get age of sign up & increment count
        var today = new Date().toString();
        var todayString = today.toString();
        var end = moment(todayString);
        let metricAge = end.diff(createdAtDate, "days");
        if (Math.floor(metricAge / dateRangeIntervalSize) == 0) {
          allStatsMetrics[j][0] += 1;
        } else {
          allStatsMetrics[j][Math.floor(metricAge / dateRangeIntervalSize) - 1] += 1;
        }
      }
    }

    this.setState({
      teamSignUps: allStatsMetrics[0].reverse(),
      teamListers: allStatsMetrics[1].reverse(),
      teamCustomers: allStatsMetrics[2].reverse(),
      teamTraffic: allStatsMetrics[3].reverse(),
    });
  }

  async getAllEmails() {
    try {
      let outreachResponse = await utils.getOutreach();
      let allOutreach = outreachResponse.data.listOutreachs.items;
      var flyers = [];
      var emails = [];
      for (var i = 0; i < allOutreach.length; i++) {
        let currentOutreach = allOutreach[i];
        if (currentOutreach.marketerEmail == this.state.user.email) {
          if (currentOutreach.type == "flyer") {
            flyers.push(currentOutreach);
          } else if (currentOutreach.type == "email") {
            emails.push(currentOutreach);
          }
        }
      }
      this.setState({emails: emails, flyers: flyers});
      await this.getAllUsers();
      this.parsePayments({});
    } catch(error) {
      console.log(error);
    }
  }

  async getAllTraffic() {
    var lastMonthDate = new Date();
    lastMonthDate.setMonth(lastMonthDate.getMonth()-1);
    const lastMonthEpochTime = parseInt(lastMonthDate.getTime() / 1000, 10);
    const body = { "lastMonthEpochTime" : lastMonthEpochTime };
    const response = await API.post('dynamoApi', '/get-visits', { body });

    try {
      let allVisits = response.allVisits;
      console.log(allVisits, "all of the visits...");
      var today = new Date().toString();
      var todayString = today.toString();

      // Chart attributes
      var trafficBySchool = {}
      var schoolColorsDict = {}
      var maxTraffic = 0

      // Iterate through all the traffic
      for (var i = 0; i < allVisits.length; i++) {
        let currentVisit = allVisits[i];
        var minDistance = Infinity;
        var minIndex = -1
        // Find the closest school
        for (var j = 0; j < window.schools.length; j++) {
          schoolColorsDict[window.schools[j].name] = window.schools[j].hex ?? "#aaaaaa"
          const currentDistance = utils.distance(window.schools[j].latitude, window.schools[j].longitude, currentVisit.latitude.N, currentVisit.longitude.N, "M");
          if (currentDistance < minDistance) {
            minDistance = currentDistance;
            minIndex = j;
          }
        }

        // Get age of traffic
        let start = currentVisit.createdAt.S.slice(0, 10)
        var end = moment(todayString);
        let trafficAge = end.diff(start, "days")

        // Add traffic to results
        if (minIndex >= 0 && currentVisit.countryName.S == "United States") {
          const closestSchool = window.schools[minIndex].name
          if (trafficAge < 29) {
            console.log("Closest school:", window.schools[minIndex].name);
            if (closestSchool in trafficBySchool) {
                trafficBySchool[closestSchool][trafficAge] += 1
                maxTraffic = Math.max(trafficBySchool[closestSchool][trafficAge], maxTraffic)
            } else {
              var emptyTrafficByDate = {}
              for (var k = 0; k < 29; k++) {
                emptyTrafficByDate[k] = 0
              }
              trafficBySchool[closestSchool] = emptyTrafficByDate
              trafficBySchool[closestSchool][trafficAge] += 1
            }
          }
        }
      }
      this.setState({trafficBySchool: trafficBySchool, schoolColorsDict: schoolColorsDict, maxTraffic: maxTraffic, allTraffic: allVisits});
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const sideBar = (<div id="sidebar">
      <ProSidebar>
        <Menu iconShape="square">
          <SidebarContent>
          <MenuItem style={{marginLeft: -10, cursor: "default"}}>Filters</MenuItem>
          <hr style={{marginTop: -15, backgroundColor: "white", width: "80%", marginLeft: 10}}></hr>
          <SubMenu title="View" style={{marginLeft: -5, marginTop: -10}}>
            <MenuItem onClick={() => this.setState({showPayments: true, showWaysToEarn: false, showChart: true, selectedPanel: "Payments"})} active={this.state.showPayments}>Payments</MenuItem>
            <MenuItem onClick={() => this.setState({showPayments: false, showWaysToEarn: false, showChart: true, selectedPanel: "Users"})} active={!this.state.showPayments}>Users</MenuItem>
          </SubMenu>
          {/* Uncomment if map feature is built out
            <SubMenu title="Traffic" style={{marginLeft: -5, marginTop: -10}}>
              <MenuItem onClick={() => this.setState({showChart: true, selectedPanel: "Charts"})} active={this.state.showChart}>Chart</MenuItem>
              <MenuItem onClick={() => this.setState({showChart: false, selectedPanel: "Map"})} active={!this.state.showChart}>Map</MenuItem>
            </SubMenu>
           */}

          <br></br>
          </SidebarContent>
            <MenuItem style={{marginLeft: -10}}>Content</MenuItem>
            <hr style={{marginTop: -15, backgroundColor: "white", width: "80%", marginLeft: 10}}></hr>
            <MenuItem onClick={() => this.setState({showChart: true, showPayments: false, selectedPanel: "Ways to Earn", showWaysToEarn: true})} style={{marginLeft: -5, marginTop: -15}}>Ways to Earn</MenuItem>
            <MenuItem onClick={() => this.setState({showChart: false, showWaysToEarn: false, selectedPanel: "Templates"})} style={{marginLeft: -5, marginTop: -15}}>Email Templates</MenuItem>
          <SidebarContent>
          <br></br>
          </SidebarContent>
            <MenuItem style={{marginLeft: -10}}>Info</MenuItem>
            <hr style={{marginTop: -15, backgroundColor: "white", width: "80%", marginLeft: 10}}></hr>
            <MenuItem onClick={() => this.setState({showChart: false, showWaysToEarn: false, selectedPanel: "Tutorials"})} style={{marginLeft: -5, marginTop: -15}}>Tutorials</MenuItem>
            <MenuItem onClick={()=> this.setState({showContactUs: true})} style={{marginLeft: -5, marginTop: -15}}>Contact Us</MenuItem>
          <SidebarContent>
          </SidebarContent>
        </Menu>
      </ProSidebar>
    </div>)

    const mobileTopBar = (<div style={{width: "100%", backgroundColor: "#43495e", height: this.state.size < 2 ? 320 : 100}}>
      <Row>
        <Col sm={4} style={{marginBottom: this.state.size < 2 ? 20 : 0}}>
          <button class="button_secondary" style={{fontFamily:'glacialindifference-bold', fontSize: 16, color: "white"}}>Filters</button>
          <hr style={{backgroundColor: "white", width: "25%", marginTop: -10, marginBottom: -1}}></hr>
          <button style={{fontFamily:'glacialindifference-bold', border: "none", backgroundColor: "#ffffff00", fontSize: 16, color: "white", marginTop: 5}}>Time Frame</button>
          <br></br>
          <button style={{fontFamily:'glacialindifference-bold', border: "none", backgroundColor: "#ffffff00", fontSize: 16, color: "white"}}>Traffic</button>
        </Col>
        <Col sm={4} style={{marginBottom: this.state.size < 2 ? 20 : 0}}>
          <button class="button_secondary" style={{fontFamily:'glacialindifference-bold', fontSize: 16, color: "white"}}>Content</button>
          <hr style={{backgroundColor: "white", width: "25%", marginTop: -10, marginBottom: -1}}></hr>
          <button style={{fontFamily:'glacialindifference-bold', border: "none", backgroundColor: "#ffffff00", fontSize: 16, color: "white", marginTop: 5}}>Templates</button>
          <br></br>
          <button style={{fontFamily:'glacialindifference-bold', border: "none", backgroundColor: "#ffffff00", fontSize: 16, color: "white"}}>Brand Guide</button>
        </Col>
        <Col sm={4}>
          <button class="button_secondary" style={{fontFamily:'glacialindifference-bold', fontSize: 16, color: "white"}}>Info</button>
          <hr style={{backgroundColor: "white", width: "25%", marginTop: -10, marginBottom: -1}}></hr>
          <button style={{fontFamily:'glacialindifference-bold', border: "none", backgroundColor: "#ffffff00", fontSize: 16, color: "white", marginTop: 5}}>Tutorials</button>
          <br></br>
          <button onClick={()=> this.setState({showContactUs: true})} style={{fontFamily:'glacialindifference-bold', border: "none", backgroundColor: "#ffffff00", fontSize: 16, color: "white"}}>Contact Us</button>
        </Col>
      </Row>
    </div>);

    var payments = this.state.payments;
    var paymentsDetails;
    let isShowingMore = this.state.isShowingMore;
    if (payments && payments.length > 0) {
      if (payments.filter(function(item) { return item.reason == 'Milestone' }).length == 0) {
        payments.unshift({'amount': '', 'reason': 'Milestone', 'date': 'Date'});
      }
      paymentsDetails = this.state.payments.map(function(item, i) {
        if (i <= 4 || isShowingMore) {
          let date = '---';
          if (item.date) {
            if (typeof(item.date) === 'string' ) {
              date = item.date.substring(5,7) + "/" + item.date.substring(8,10)
            } else if (typeof(item.date) === 'object') {
              date = item.date.S.substring(5,7) + "/" + item.date.S.substring(8,10)
            }
          }
          return (<div style={{height : 40}}><div style={{marginTop: i == 0 ? 0 : -10, width: "100%", display: "flex", justifyContent: "space-between"}}>
            <p style={{overflow: "hidden", maxHeight: 30, width: "30%", fontWeight: i == 0 ? "bold" : "normal", color: "#6d757d", marginLeft: 10, textAlign: "center"}}>{item.reason}</p>
            <p style={{width: "20%", fontWeight: i == 0 ? "bold" : "normal", color: "#6d757d"}}>{i == 0 ? "Date" : date}</p>
            <p style={{width: "10%", fontWeight: i == 0 ? "bold" : "normal", color: "green", marginRight: 10, textAlign: "center"}}>{"$" + item.amount}</p>
          </div>
          {i == 0 && <hr style={{height: 1, backgroundColor: "#aaaaaa", width: "100%",  marginTop: -15}}></hr>}
          {i != 0 && <hr style={{width: "100%", marginTop: -15}}></hr>}
          </div>);
        }
      })
    } else {
      paymentsDetails = (<div>
        <img style={{marginLeft: "10%", width: "80%"}} src={emptyPayments}></img>
        <p style={{color: "#aaaaaa", textAlign: "center"}}>{"Get rewarded for your milestones. Click 'Earn Now' below to learn more"}</p>
        <button className="home_onboard_customer" style={{width: "37%", marginLeft: "32%", marginBottom: 10}} onClick={() => this.setState({showChart: true, showPayments: false, selectedPanel: "Ways to Earn", showWaysToEarn: true})}>Earn Now</button>
      </div>)
    }

    const showMoreRow = (<div hidden={this.state.payments.length <= 4} style={{height : 35, marginTop: -15}}>
        <button onClick={() => this.setState({isShowingMore: true})} style={{width: "80%", marginLeft: "10%", border: "none", backgroundColor: "#ffffff00", height: 35, color: "#43495e", fontFamily:'glacialindifference-bold'}}>Show More</button>
        <hr style={{width: "100%", marginTop: -5}}></hr>
      </div>);
    const showLessRow = (<div hidden={this.state.payments.length <= 4} style={{height : 35}}>
      <button onClick={() => this.setState({isShowingMore: false})} style={{width: "80%", marginLeft: "10%", border: "none", backgroundColor: "#ffffff00", height: 35, color: "#43495e", fontFamily:'glacialindifference-bold'}}>Show Less</button>
      <hr style={{width: "100%", marginTop: -5}}></hr>
    </div>);

    const waysToEarnView = (
      <div style={{marginLeft: this.state.size > 3 ? 260 : 40, marginRight: 40, marginBottom: 40, textAlign: "left", display: "flex", justifyContent: "space-between"}}>
        <fieldset style={{width: "60%", paddingLeft: 10}}>
          <h5 style={{color: "#43495e"}}>Emails</h5>
          <p style={{color: "#83848c", fontSize: 12, marginTop: -6}}>{"Achievable 1 time per week"}</p>
          <p>{"Send " + String(this.state.rewardThresholds.emails - (this.state.emails.length % this.state.rewardThresholds.emails)) + " more emails and get a $" + this.state.rewardAmounts.emails + " reward!"}</p>
          <p style={{marginTop: 20}}>{"Earn when you send a valid StoreStash email to a member of your school who has potential outreach. " +
            "For example, fraternity or sorority chairs, RA's in on campus apartments, the department heads, and club presidents. " +
            "Make sure to cc the email to gwilson@mystorestash.com and the subject contains the word storage for the email to be counted."}</p>
        </fieldset>
        <fieldset style={{width: "60%", paddingLeft: 10}}>
          <h5 style={{color: "#43495e"}}>Flyers</h5>
          <p style={{color: "#83848c", fontSize: 12, marginTop: -6}}>{"Achievable 1 time per internship"}</p>
          <p>{"Post " + String(this.state.rewardThresholds.flyers - (this.state.flyers.length % this.state.rewardThresholds.flyers)) + " more flyers and get an $" + this.state.rewardAmounts.flyers + " reward!"}</p>
          <p style={{marginTop: 20}}>{"Earn when you post a flyer around important locations of your campus. i.e. residence halls, study areas, libraries, and dinings halls. " +
            "Be sure to send a photo of the flyer to gwilson@mystorestash.com and have the word 'flyer' in the subject line. " +
            "The flyer should ideally be printed in color and on a standard 8.5x11 sheet of paper."}</p>
        </fieldset>
        <fieldset style={{width: "60%", paddingLeft: 10}}>
          <h5 style={{color: "#43495e"}}>Sign Ups</h5>
          <p style={{color: "#83848c", fontSize: 12, marginTop: -6}}>{"Achievable: Unlimited"}</p>
          <p>{"Get " + String(this.state.rewardThresholds.users - (this.state.personalSignUps.length % this.state.rewardThresholds.users)) + " more students to sign up for StoreStash and get a $" + this.state.rewardAmounts.users + " reward!"}</p>
          <p style={{marginTop: 20}}>{"Earn when students sign up for StoreStash at your school."}</p>
        </fieldset>
        <fieldset style={{width: "60%", paddingLeft: 10}}>
          <h5 style={{color: "#43495e"}}>Listers</h5>
          <p style={{color: "#83848c", fontSize: 12, marginTop: -6}}>{"Achievable: Unlimited"}</p>
          <p>{"Get " + String(this.state.rewardThresholds.listers - (this.state.personalListers.length % this.state.rewardThresholds.listers)) + " more listers to sign up and get a $" + this.state.rewardAmounts.listers + " reward!"}</p>
          <p style={{marginTop: 20}}>{"Earn for each " + this.state.rewardThresholds.listers + " listers that post their space at your school."}</p>
        </fieldset>
        <fieldset style={{width: "60%", paddingLeft: 10}}>
          <h5 style={{color: "#43495e"}}>Customers</h5>
          <p style={{color: "#83848c", fontSize: 12, marginTop: -6}}>{"Achievable: Unlimited"}</p>
          <p>{"Get " + String(this.state.rewardThresholds.customers - (this.state.personalCustomers.length % this.state.rewardThresholds.customers)) + " more customers sign ups and get a $" + this.state.rewardAmounts.customers + " reward!"}</p>
          <p style={{marginTop: 20}}>{"Earn for each " + this.state.rewardThresholds.customers + " customers that purchase space at your school."}</p>
        </fieldset>
      </div>
    )

    const newView = (
      <CCol sm="6" lg="3" style={{marginBottom: 40, marginTop: 40, maxHeight: payments.length == 0 ? 540 : 370}}>
        <div style={{border: '1px solid', borderColor: "#aaaaaa", paddingTop: 10, borderRadius: 5, borderWidth: "1px", height: "100%"}}>
          <p style={{fontFamily:'glacialindifference-bold', fontSize: 20, color: "#43495e", paddingLeft: 20, paddingRight: 10}}>Payments</p>
          <p style={{fontSize: 13+'px', marginTop: -15, paddingLeft: 20, paddingRight: 10}} class="card-subtitle text-muted">Get rewarded for your outreach</p>
          <hr style={{height: 1, backgroundColor: "#aaaaaa", width: "100%"}}></hr>
          <div style={{overflowY: "scroll", backgroundColor: "#ffffff00", marginTop: -10, height: payments.length == 0 ? "100%" : (this.state.isShowingMore ? "65%" : "50%")}}>
          {paymentsDetails}
          </div>
          {!isShowingMore && showMoreRow}
          {isShowingMore && showLessRow}
          <div hidden={isShowingMore || (payments.length == 0)} style={{ width: "20%", height: "20%", marginLeft: "40%", marginTop: 10 }}>
          <VisibilitySensor>
          {({ isVisible }) => {
            var total = isVisible ? this.state.totalPayout : 0;
            return (
              <CircularProgressbarWithChildren value={total} styles={buildStyles({pathColor: '#fdad16'})}>
                <div style={{ fontSize: 18, marginTop: -5 }}>
                  <strong style={{color: "green"}}>{this.state.totalPayout == null ? '' : `$${total}`}</strong>
                </div>
              </CircularProgressbarWithChildren>
            );
          }}
        </VisibilitySensor>
          </div>
        </div>

      </CCol>
    )
    const payView = (
      <CCol sm="6" lg="3" style={{marginBottom: 40, marginTop: 40}}>
        <CWidgetDropdown
          style={{marginBottom: 40}}
          color="gradient-warning"
          header={this.state.teamListers.reduce((a, b) => a + b, 0)}
          text="Listers"
          footerSlot={
            <ChartBarSimple
              className="mt-3 mx-3"
              dataPoints={this.state.teamListers}
              style={{height: '70px'}}
              backgroundColor="#fed68b"
              label="Listings"
              labels="months"
            />
          }
        >
          <CDropdown>
            <CDropdownToggle caret className="text-white" color="transparent">
              <CIcon name="cil-settings"/>
            </CDropdownToggle>
            <CDropdownMenu className="pt-0" placement="bottom-end">
              <CDropdownItem>Action</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CWidgetDropdown>

        <CWidgetDropdown
          color="gradient-danger"
          header={this.state.teamCustomers.reduce((a, b) => a + b, 0)}
          text="Customers"
          footerSlot={
            <ChartBarSimple
              className="mt-3 mx-3"
              dataPoints={this.state.teamCustomers}
              style={{height: '70px'}}
              backgroundColor="rgb(152, 250, 152)"
              label="Users"
              labels="months"
            />
          }
        >
          <CDropdown>
            <CDropdownToggle caret className="text-white" color="transparent">
              <CIcon name="cil-settings"/>
            </CDropdownToggle>
            <CDropdownMenu className="pt-0" placement="bottom-end">
              <CDropdownItem>Action</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CWidgetDropdown>
      </CCol>
    );

    let milestones = (
      <div style={{marginLeft: this.state.size > 3 ? 260 : 40, marginRight: 40, paddingTop: 40, textAlign: "left"}}>
        <CCardGroup className="mb-4">
          <CWidgetProgressIcon
            header={String(this.state.emails.length % this.state.rewardThresholds.emails)}
            text="EMAILS SENT"
            color="gradient-info"
            progressSlot={
              <CProgress color="gradient-info" size="xs" value={100 * (this.state.emails.length/this.state.rewardThresholds.emails) } className="my-3"
            />}
          >
          <img style={{height: 30}} src={emailImage}></img>
          </CWidgetProgressIcon>
          <CWidgetProgressIcon
            header={String(this.state.flyers.length % this.state.rewardThresholds.flyers)}
            text="FLYERS POSTED"
            color="gradient-info"
            progressSlot={
              <CProgress color="gradient-info" size="xs" value={100 * (this.state.flyers.length/this.state.rewardThresholds.flyers)} className="my-3"
            />}
          >
            <img style={{height: 30}} src={flyerImage}></img>
          </CWidgetProgressIcon>
          <CWidgetProgressIcon
            header={String(this.state.personalSignUps.length % this.state.rewardThresholds.users)}
            text="Users"
            color="gradient-warning"
            progressSlot={
              <CProgress color="gradient-warning" size="xs" value={100 * this.state.personalSignUps.length / this.state.rewardThresholds.users} className="my-3"
            />}
          >
            <img style={{height: 30}} src={userImage}></img>
          </CWidgetProgressIcon>
          <CWidgetProgressIcon
            header={String(this.state.personalListers.length % this.state.rewardThresholds.listers)}
            text="Listers"
            color="gradient-warning"
            progressSlot={
              <CProgress color="gradient-warning" size="xs" value={100 * this.state.personalListers.length / this.state.rewardThresholds.listers} className="my-3"
            />}
          >
            <img style={{height: 30}} src={listerImage}></img>
          </CWidgetProgressIcon>
          <CWidgetProgressIcon
            header={String(this.state.personalCustomers.length % this.state.rewardThresholds.customers)}
            text="Customers"
            color="gradient-danger"
            progressSlot={
              <CProgress color="gradient-danger" size="xs" value={100 * this.state.personalCustomers.length / this.state.rewardThresholds.customers} className="my-3"
            />}
          >
          <img style={{height: 30}} src={boxesImage}></img>
          </CWidgetProgressIcon>
        </CCardGroup>
      </div>
    );

    var content = (<div style={{marginLeft: this.state.size > 3 ? 260 : 40, marginRight: 40, textAlign: "left"}}>
      <CRow>
      {this.state.showPayments && newView}
      {!this.state.showPayments && payView}
      <CCol sm="6" lg="3" style={{marginBottom: 40, marginTop: 40}}>
        <CWidgetDropdown
          style={{marginBottom: 40}}
          color="gradient-traffic"
          header={this.state.teamTraffic.reduce((a, b) => a + b, 0)}
          text="Website Traffic"
          footerSlot={
            <ChartLineSimple
              pointed
              className="c-chart-wrapper mt-3 mx-3"
              style={{height: '70px'}}
              dataPoints={this.state.teamTraffic}
              pointHoverBackgroundColor="white"
              options={{ elements: { line: { tension: 0.00001 }}}}
              label="Users"
              labels="months"
            />
          }
        >
        <CDropdown>
          <CDropdownToggle caret className="text-white" color="transparent">
            <CIcon name="cil-settings"/>
          </CDropdownToggle>
          <CDropdownMenu className="pt-0" placement="bottom-end">
            <CDropdownItem>Action</CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </CWidgetDropdown>

      <CWidgetDropdown
        color="gradient-info"
        header={this.state.teamSignUps.reduce((a, b) => a + b, 0)}
        text="Sign Ups"
        footerSlot={
          <ChartLineSimple
            pointed
            className="mt-3 mx-3"
            style={{height: '70px'}}
            dataPoints={this.state.teamSignUps}
            pointHoverBackgroundColor="white"
            options={{ elements: { line: { tension: 0.00001 }}}}
            label="Users"
            labels="months"
          />
        }
      >
        <CDropdown>
          <CDropdownToggle caret className="text-white" color="transparent">
            <CIcon name="cil-location-pin"/>
          </CDropdownToggle>
          <CDropdownMenu className="pt-0" placement="bottom-end">
            <CDropdownItem>Action</CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </CWidgetDropdown>
    </CCol>

    <CCol sm="6" lg="6" style={{marginBottom: 40, marginTop: 40}}>
      <MainChartExample trafficBySchool={this.state.trafficBySchool} schoolColorsDict={this.state.schoolColorsDict} maxTraffic={this.state.maxTraffic} style={{height: '368px', border: '1px solid', borderColor: "#aaaaaa", padding: 5, borderWidth: "1px", borderRadius: 5}}/>
    </CCol>
  </CRow>
    </div>);

    // Features In Development
    if (!this.state.showChart) {
      content = (<div style={{paddingLeft: this.state.size < 4 ? 0 : 250}}><FadeIn>
      {this.state.showContactUs && <ContactUs closeModal={this.closeModal} customerEmail={this.props.user == null ? "" : this.props.user.email} showContactUs={true}></ContactUs>}
      <h5 style={{fontWeight: "bold", paddingTop: this.state.size < 4 ? 100 : 200,fontFamily:'glacialindifference-bold', fontSize: 24, color: "#43495e"}}>
        {this.state.selectedPanel + " Page Under Construction"}
      </h5>
      <textarea disabled rows="5" hidden={true} style={{width: this.state.size < 4 ? "80%" : "40%", textAlign: "center", color: "#83848c !important", backgroundColor: "white", opacity: 0.65, fontSize: 20}} className="empty_textarea"></textarea>
      <textarea disabled rows={this.state.size < 4 ? "3" : "2"} style={{width: this.state.size < 4 ? "80%" : "40%", textAlign: "center", color: "#83848c !important", backgroundColor: "white", opacity: 0.65, fontSize: 20}} className="empty_textarea">
        {"This feature is in development. If it is blocking a mission critical task, reach out to either team lead."}
      </textarea>
      <br></br>
      <div style={{display: "flex", justifyContent: "space-between", width: this.state.size < 4 ? "60%" : "30%", marginLeft: this.state.size < 4 ? "20%" : "35%"}}>
        <button onClick={()=> this.setState({showChart: true})} style={{marginTop:15, backgroundColor: "#aaaaaa", width: 125}} className="home_onboard_customer">Back</button>
        <button onClick={()=> this.setState({showContactUs: true})} style={{marginTop:15}} className="home_onboard_customer">Contact Us</button>
      </div>
      </FadeIn></div>);
    }

    if (this.state.user !== undefined && (this.state.user.isMarketer || this.state.user.isAdmin)) {
      return (
        <div>
          {this.state.showContactUs && <ContactUs closeModal={this.closeModal} customerEmail={this.props.user == null ? "" : this.props.user.email} showContactUs={true}></ContactUs>}
          {this.state.size > 3 && sideBar}
          {this.state.size <= 3 && mobileTopBar}
          {!this.state.showWaysToEarn && content}
          {(this.state.showWaysToEarn || this.state.showChart) && milestones}
          {this.state.showWaysToEarn && waysToEarnView}
        </div>
      );
    } else {
      if (this.state.showAccessDenied) {
        return (<div>
          <FadeIn>
          {this.state.showContactUs && <ContactUs closeModal={this.closeModal} customerEmail={this.props.user == null ? "" : this.props.user.email} showContactUs={true}></ContactUs>}
          <h5 style={{fontWeight: "bold", marginTop: "10%", fontFamily:'glacialindifference-bold', fontSize: 24, color: "#43495e"}}>
            Access Denied
          </h5>
          <textarea disabled hidden={true} style={{width: "40%", textAlign: "center", color: "#83848c !important", backgroundColor: "white", opacity: 0.65, fontSize: 20}} className="empty_textarea"></textarea>
          <textarea disabled style={{width: "40%", textAlign: "center", color: "#83848c !important", backgroundColor: "white", opacity: 0.65, fontSize: 20}} className="empty_textarea">
            {"You must be a verified StoreStash marketer or admin to access this page. If you think something is wrong, click 'Contact Us' below."}
          </textarea>
          <br></br>
          <button onClick={()=> this.setState({showContactUs: true})} style={{marginTop:15}} className="home_onboard_customer">Contact Us</button>
          </FadeIn>
        </div>)
      } else {
        return (<div>
          <CircularProgress style={{color: "#43495e", marginTop: "10%", marginBottom: 30}}/>
          <h5 style={{fontWeight: "bold", fontFamily:'glacialindifference-bold', fontSize: 24, color: "#43495e"}}>
            Verifying Permissions
          </h5>
          <textarea disabled style={{width: "50%", height: 200, textAlign: "center", color: "#83848c !important", backgroundColor: "white", opacity: 0.65, fontSize: 20, rows: 5}} className="empty_textarea">In order to access this page, you'll need to be signed into your '.edu' email.</textarea>
        </div>)
      }
    }
  }
}

export default AdminDashboard;
