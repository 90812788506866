import React, { Component } from 'react';
import ImageUpload from './image_upload';
import Selector from './selector';
import ReactSimpleOptionsSelector from "react-simple-options-selector";
import { useFileUpload } from "use-file-upload";
import { v4 as uuidv4 } from 'uuid';
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify'
import { createListing, updateListing, createSchool } from '.././graphql/mutations';
import S3 from 'react-aws-s3';
import deliveryInfoImage from '.././assets/delivery_info.png';
import grayStripeLogo from '.././assets/stripe_gray.png';
import stripeLogo from '.././assets/stripeLogo.png';
import venmoLogo from '.././assets/venmoLogo.png';
import grayVenmoLogo from '.././assets/venmo_gray.png';
import { withRouter } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import logoImage from '.././assets/logo.png';
import { Cache } from 'aws-amplify';
import calendarEnteredImage from '.././assets/calendar_entered.png'
import AlertDialog from './alert';
import AWS from 'aws-sdk';
import * as utils from '.././utils.js';
import MetaTags from 'react-meta-tags';
import ReactGA from 'react-ga';

// Date Picking
import { DatePicker } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import * as dateRangeUtils from '.././date_range.js';
import { enGB } from 'date-fns/locale';
import DateRangePickerExample from './unavailable_range_picker';
import { format } from 'date-fns';

// Material UI
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import awsExports from ".././aws-exports";
Amplify.configure(awsExports);


class CreateListing extends Component {

  constructor(props) {
      super(props);

      // Default expiration date
      var nextSeptember = new Date(new Date().getFullYear(), 8, 1, 0, 0, 0);
      // If it's the start of the school year and someone tries to list,
      // the expiration should be for 1.1 years from now. Not a few days.
      var thirtyDaysBefore = new Date(nextSeptember)
      thirtyDaysBefore.setDate(thirtyDaysBefore.getDate() - 60);
      let today = new Date()
      if (nextSeptember < today || today > thirtyDaysBefore) {
        nextSeptember = new Date(nextSeptember.getFullYear() + 1, nextSeptember.getMonth(), nextSeptember.getDate());
      }

      this.state = {
        size: 5,
        showPublishModal: false,
        showVenmoModal: false,
        venmoHandle: "",
        confirmVenmoHandle: "",
        verifiedVenmoHandle: "",
        paymentIdentifier: "",
        loadingVenmo: false,
        getPaidText: "Get paid w/ Venmo",
        tempListingId: uuidv4().substring(0,8),
        newListingId: "",
        updatedListingId: "",
        residences: [],
        postStorageEnabled: false,
        listingToEdit: null,
        isEditing: false,
        chosenResidence: '-1',
        username: "",
        email: "",
        showUnavailableDates: false,
        stripeAccountId: "",
        userListingId: "",
        chosenImageUrl: "",
        loadingImage: false,
        loadingEditListing: true,
        loadingStripe: false,
        file: null,
        image: null,
        breakStage: 0,
        capacity: -1,
        alertDetails: null,
        expirationDate: nextSeptember,
        firstName: "",
        breakStageDict: {
          0 : {
            title: "🏖 Summer Break",
            timeFrame: "(May 08 - Aug 23)",
            available: "summer_available",
            unavailable: "summer_unavailable",
            dateRange: "2021-05-08+2021-08-23"
          },
          1 : {
            title: "⛄ Winter Break",
            timeFrame: "(Dec 17 - Jan 10)",
            available: "winter_available",
            unavailable: "winter_unavailable",
            dateRange: "2021-12-17+2022-01-10"
          },
          2 : {
            title: "🌱 Spring Break",
            timeFrame: "(Mar 14 - Mar 21)",
            available: "spring_available",
            unavailable: "spring_unavailable",
            dateRange: "2022-03-14+2022-03-21"
          }
        },
        unavailable_breaks: ["-1", "-1", "-1", "-1"],
        file: null,
        allOptions: [
          { value: "2021-05-08+2021-08-23", label: "Summer Break" },
          { value: "2021-12-17+2022-01-10", label: "Winter Break" },
          { value: "2022-03-14+2022-03-21", label: "Spring Break" }
        ],
        selectedUnavailability: [],
        selectedIndex: 1,
        newBreak: "",
        checkedDelivery: false
      };
      this.submitVenmo = this.submitVenmo.bind(this);
      this.handleCapacityChange = this.handleCapacityChange.bind(this);
      this.expirationDateChange = this.expirationDateChange.bind(this);
      this.residenceChange = this.residenceChange.bind(this);
      this.textChanged = this.textChanged.bind(this);
      this.handleNext = this.handleNext.bind(this);
      this.handlePrevious = this.handlePrevious.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleUpdateListing = this.handleUpdateListing.bind(this);
      this.handleToggleDelivery = this.handleToggleDelivery.bind(this);
      this.checkForListingPosted = this.checkForListingPosted.bind(this);
      this.addPayment = this.addPayment.bind(this);
      this.clearCache = this.clearCache.bind(this);
      this.saveNewPaymentInfo = this.saveNewPaymentInfo.bind(this);
      this.updateUserWithListing = this.updateUserWithListing.bind(this);
      this.getCurrentUsersListing = this.getCurrentUsersListing.bind(this);
      this.firstNameChanged = this.firstNameChanged.bind(this);
      this.lastNameChanged = this.lastNameChanged.bind(this);
      this.showVenmoModal = this.showVenmoModal.bind(this);
      this.validateListingAttributes = this.validateListingAttributes.bind(this);
      this.togglePaymentInfo = this.togglePaymentInfo.bind(this);
      this.updateDimensions = this.updateDimensions.bind(this);
      this.showDeliveryInfo = this.showDeliveryInfo.bind(this);
      this.showSignUp = this.showSignUp.bind(this);

      ReactGA.pageview(window.location.pathname);
  }

  componentDidMount() {
    //mobile rendering event listener
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillMount() {
    //remove mobile rendering event listener
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    var size = 0
    if (windowWidth >= 450) {
      size = 1
    }
    if (windowWidth >= 576) {
      size = 2
    }
    if (windowWidth >= 768) {
      size = 3
    }
    if (windowWidth >= 992) {
      size = 4
    }
    if (windowWidth >= 1200) {
      size = 5
    }
    this.setState({size: size});
  }

    showDeliveryInfo() {
      this.setState({alertDetails:
        {
          title: "Delivery",
          description: "If you have a car on campus, you can make $25 per reservation and $5 extra for each additional item you deliver. The price calculated below your storage capacity is for " + this.state.capacity +  " items from " + this.state.capacity + " different students. Read more in the job description on the right.",
          open: true
        },
        checkedDelivery: true
      });
    }

    showSignUp() {
      this.setState({showSignUpNotice: false});
      this.props.showAuthModal("signUp");
    }

    showVenmoModal() {
      if (this.state.username) {
        this.setState({showVenmoModal: true, verifiedVenmoHandle: ""});

        // Emit stat for Venmo Account Verification
        try {
          const currentSchoolName = utils.getCurrentSchoolAttribute(this.state.email, "name");
          ReactGA.event({
            category: utils.formatSchoolName(currentSchoolName),
            action: 'Showed Onboard Venmo',
            label: 'create_listing.jsx',
          });
        } catch(error) {
          console.log("Failed: " + error.message);
        }
      } else {
        this.setState({showSignUpNotice: true});
      }
    }

    async submitVenmo() {
      this.setState({loadingVenmo: true})
      if (this.state.venmoHandle != this.state.confirmVenmoHandle) {
        this.setState({loadingVenmo: false, alertDetails:
          {
            title: "Handle Notice",
            description: "The venmo handles you entered do not match",
            open: true
          }
        })
        return
      }

      const body = { "handle" : this.state.venmoHandle }
      const response = await API.post('venmoApi', '/verify-handle', { body });
      if (response["error"] !== undefined) {
        this.setState({loadingVenmo: false, alertDetails:
          {
            title: "Venmo Notice",
            description: "We couldn't find the venmo handle you entered.",
            open: true
          }
        })
        return
      } else if (response["success"] !== undefined) {

        // Emit stat for Venmo Account Creation
        try {
          const currentSchoolName = utils.getCurrentSchoolAttribute(this.state.email, "name");
          ReactGA.event({
            category: utils.formatSchoolName(currentSchoolName),
            action: 'Entered Venmo',
            label: 'create_listing.jsx',
          });
        } catch(error) {
          console.log("Failed: " + error.message);
        }

        var paymentIdentifier = this.state.venmoHandle.charAt(0) == '@' ? this.state.venmoHandle.substring(1) : this.state.venmoHandle;
        this.setState({showVenmoModal: false, getPaidText: "Edit Venmo Details", verifiedVenmoHandle: this.state.venmoHandle, paymentIdentifier: paymentIdentifier});
      }
    }

    expirationDateChange = (date) => {
      this.setState({expirationDate: date})
    }

    togglePaymentInfo() {
      this.setState({showPaymentMethods: !this.state.showPaymentMethods})
    }

    handleToggleDelivery() {
      if (this.state.chosenResidence == "Other") {
        this.setState({alertDetails:
          {
            title: "Delivery Notice",
            description: "You must include delivery if you are storing items in another storage location.",
            open: true
          }
        })
        return
      }
      this.setState({checkedDelivery: !this.state.checkedDelivery})
    }

    handleImageUpload = (chosenFile) => {
      this.setState({file: chosenFile})
      let reader = new FileReader()
      reader.onload = async (e) => {
        if (e.target.result.length > 3000000) {
          this.setState({alertDetails:
            {
              title: "Upload Notice",
              description: 'Image is loo large - 3Mb maximum',
              open: true
            }
          });
          return
        }
        const body = {
          imageString: e.target.result.split('base64,')[1],
          fileName: this.state.tempListingId
        }
        const response = await API.post("s3Api", "/upload-listing-image", { body });
        if (response["success"]) {
          console.log("Success: /upload-listing-image with response - ", response);
          this.setState({chosenImageUrl: response["location"], loadingImage: false});
        }
      }
      reader.readAsDataURL(chosenFile);
    }

    handleNewUnavailabilityRange = (value) => {

      var temp = this.state.selectedUnavailability;
      let monthDict = {
        0:'Jan', 1:'Feb', 2:'Mar',3:'Apr', 4:'May', 5:'Jun', 6:'Jul',
    		7:'Aug', 8:'Sep', 9:'Oct', 10:'Nov', 11:'Dec'
      }

      let currentDateRange = value.split("+");
      let startDate = new Date(currentDateRange[0])
      let endDate = new Date(currentDateRange[1])
      let range = monthDict[startDate.getMonth()] + " " + (startDate.getDate()+1) + " - " + monthDict[endDate.getMonth()] + " " + (endDate.getDate()+1);
      temp.push({ value: value, label: range});
      this.setState({selectedUnavailability: temp});
      var tempOptions = this.state.allOptions;
      tempOptions.push({ value: value, label:  range});
      this.setState({allOptions: tempOptions});
    }

    handleUnavailabilityUpdate = (unavailablility) => {
        this.setState({selectedUnavailability: unavailablility});
    }

    firstNameChanged(event) {
      if (this.state.showPaymentMethods == null) {
        this.setState({ firstName: event.target.value, showPaymentMethods: true});
      } else {
        this.setState({ firstName: event.target.value});
      }
    }

    lastNameChanged(event) {
      this.setState({ lastName: event.target.value});
    }

    textChanged(event) {
      this.setState({ text: event.target.value});
    }

    handleCapacityChange(event) {
      // Emit stat for Capacity Update
      try {
        if (this.state.enteredCapacity == null) {
          try {
            const currentSchoolName = utils.getCurrentSchoolAttribute(this.state.email, "name");
            ReactGA.event({
              category: utils.formatSchoolName(currentSchoolName),
              action: 'Entered Capacity',
              label: 'create_listing.jsx',
            });
          } catch(error) {
            console.log("Failed: " + error.message);
          }
        }
      } catch(error) {
        console.log("Failed: " + error.message);
      }

      this.setState({capacity: event.target.value, enteredCapacity: true});
    }

    handleNext() {
        var temp = this.state.selectedUnavailability
        let dateRange = this.state.breakStageDict[this.state.breakStage].dateRange

        var index = -1;
        for (var i = 0; i < temp.length; i++) {
          if (temp[i].value.includes(dateRange)) {
            index = i;
          }
        }
        var season = this.state.breakStageDict[this.state.breakStage].title.split(" ")[1];
        if (this.state.selectedIndex == 0) {
          if (index === -1) {
            temp.push({ value: dateRange, label: season + ' Break' });
          }
        } else {
          if (index !== -1) {
            temp.splice(index, 1);
          }
        }
        this.setState({selectedUnavailability: temp});
        this.setState({ breakStage: this.state.breakStage + 1 });
    }

    handlePrevious(event) {
      if (this.state.breakStageDict[this.state.breakStage - 1]) {
        this.setState({ breakStage: this.state.breakStage - 1 })
      }
    }

    residenceChange(event) {
      this.setState({chosenResidence: event.target.value, checkedDelivery: event.target.value == "Other" ? true : this.state.checkedDelivery });
    }

    async createNewListing(fileName) {
      var message = (this.state.text == undefined) ? "Hey there!\n\nThanks for choosing to store your items with me! Swing by to drop off your item(s). Let me know what's" +
      " the best way to contact you and we can coordinate picking up and dropping off during any of the dates I'm available.\n\nBest, \n" + this.state.firstName : this.state.text
      const currentSchoolId = utils.getCurrentSchoolAttribute(this.state.email, "id");
      let newListing = {
        id: fileName,
        type: "Listing",
        capacity: this.state.capacity,
        firstName: this.state.firstName,
        paymentIdentifier: this.state.paymentIdentifier,
        expirationDate: this.state.expirationDate,
        residence_id: this.state.chosenResidence,
        user_id: this.state.username,
        listerEmail: this.state.email,
        img_url: this.state.chosenImageUrl != "" ? this.state.chosenImageUrl : "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3-us-west-2.amazonaws.com/assets/storage.png",
        message: message,
        unavailableRanges: JSON.stringify(this.state.selectedUnavailability),
        includesDelivery: this.state.checkedDelivery,
        school_id: currentSchoolId == "" ? "gt0dde06" : currentSchoolId
      }
      // you are so lovely my best friend for ever and ever
      await API.graphql(graphqlOperation(createListing, {input: newListing}))
      this.setState({newListingId: fileName });
    }

    async updateUserWithListing() {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(user, {
          'custom:listingId': this.state.newListingId
      });
    }

    checkForListingPosted(mode) {
      if (mode == "create") {
        if (this.state.newListingId != "") {
          this.updateUserWithListing()
          this.props.handleNewListing(this.state.newListingId)

          try {
            const currentSchoolName = utils.getCurrentSchoolAttribute(this.state.email, "name");
            ReactGA.event({
              category: utils.formatSchoolName(currentSchoolName),
              action: 'Published Listing',
              label: 'create_listing.jsx',
            });
          } catch(error) {
            console.log("Failed: " + error.message);
          }
          this.props.history.push("/search");
        } else {
          setTimeout(this.checkForListingPosted, 3000, "create");
        }
      } else if (mode == "update") {
        if (this.state.updatedListingId != "") {
          try {
            const currentSchoolName = utils.getCurrentSchoolAttribute(this.state.email, "name");
            ReactGA.event({
              category: utils.formatSchoolName(currentSchoolName),
              action: 'Updated Listing',
              label: 'create_listing.jsx',
            });
          } catch(error) {
            console.log("Failed: " + error.message);
          }
          this.props.history.push("/search");
        } else {
          setTimeout(this.checkForListingPosted, 5000, "update");
        }
      }
    }

    async addPayment() {
      const body = {
        email: this.state.email
      }
      if (this.state.chosenResidence === "-1") {
        this.setState({alertDetails:
          {
            title: "Listing Notice",
            description: 'You must choose a residence',
            open: true
          }
        });
        return;
      } else if (this.state.capacity <= 0) {
        this.setState({alertDetails:
          {
            title: "Listing Notice",
            description: 'Choose a valid number of boxes you can store',
            open: true
          }
        });
        return;
      } else if (this.state.loadingImage) {
        this.setState({alertDetails:
          {
            title: "Listing Notice",
            description: 'Preparing your listing\'s image. This will only take a second.',
            open: true
          }
        });
        return;
      } else if (!this.state.username) {
        this.setState({showSignUpNotice: true});
        return;
      }
      Cache.setItem("createListingState", JSON.stringify(this.state));
      this.setState({loadingStripe: true});
      const response = await API.post("stripeApi", "/onboard-user", { body });

      // Emit stat for Stripe Account Redirect
      try {
        const currentSchoolName = utils.getCurrentSchoolAttribute(this.state.email, "name");
        ReactGA.event({
          category: utils.formatSchoolName(currentSchoolName),
          action: 'Showed Onboard Stripe',
          label: 'create_listing.jsx',
        });
      } catch(error) {
        console.log("Failed: " + error.message);
      }

      this.setState({loadingStripe: false});
      Cache.setItem("newAccount", JSON.stringify(response.success));
      window.location = response.url;
    }

    async handleUpdateListing() {
      var message = (this.state.text == undefined) ? "Hey there!\n\nThanks for choosing to store your items with me! Swing by to drop off your item(s). Let me know what's" +
      " the best way to contact you and we can coordinate picking up and dropping off during any of the dates I'm available.\n\nBest, \n" + this.state.firstName : this.state.text
      let updatedListing = {
        id: this.state.listingToEdit.id,
        _version: this.state.listingToEdit._version,
        user_id: this.state.listingToEdit.user_id,
        school_id: this.state.listingToEdit.school_id,
        type: "Listing",
        capacity: this.state.capacity,
        firstName: this.state.firstName,
        paymentIdentifier: this.state.paymentIdentifier,
        expirationDate: this.state.expirationDate,
        residence_id: this.state.chosenResidence,
        listerEmail: this.state.email,
        img_url: this.state.chosenImageUrl != "" ? this.state.chosenImageUrl : "https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3-us-west-2.amazonaws.com/assets/storage.png",
        message: message,
        unavailableRanges: JSON.stringify(this.state.selectedUnavailability),
        includesDelivery: this.state.checkedDelivery
      }

      // Continue here test update with valid params
      if (this.validateListingAttributes(updatedListing.residence_id, updatedListing.capacity,
        updatedListing.paymentIdentifier, updatedListing.paymentIdentifier, updatedListing.expirationDate, updatedListing.firstName)) {
        this.setState({showUpdateModal: true});
        this.checkForListingPosted("update");
        const result = await API.graphql(graphqlOperation(updateListing, {input: updatedListing}))
        utils.sleep(2000)
        this.setState({updatedListingId: updatedListing.id });
        console.log("Success: Updated the listing with this result - ", result);
      }
    }

    validateListingAttributes(residence, capacity, stripeId, venmoId, expirationDate, firstName) {
        const fourteenDaysBeforeExp = new Date(expirationDate)
        fourteenDaysBeforeExp.setDate(fourteenDaysBeforeExp.getDate() - 14)
        if (residence === "-1") {
          this.setState({alertDetails:
            {
              title: "Listing Notice",
              description: "You must choose a residence",
              open: true
            }
          })
          return false
        } else if (capacity <= 0) {
          this.setState({alertDetails:
            {
              title: "Listing Notice",
              description: "Choose a valid number of boxes you can store",
              open: true
            }
          })
          return false
        } else if (stripeId == "" && venmoId == "") {
          this.setState({alertDetails:
            {
              title: "Listing Notice",
              description: 'Enter your payment info so we can send you the money you earn!',
              open: true
            }
          })
          return false
        } else if (expirationDate == null) {
          this.setState({alertDetails:
            {
              title: "Listing Notice",
              description: 'Enter the Expiration Date for your listing. This is the approximate date that you no longer plan to be living in the space you\'ve listed',
              open: true
            }
          })
          return false
        } else if (firstName == "") {
          this.setState({alertDetails:
            {
              title: "Listing Notice",
              description: 'Enter your first name to post your storage space',
              open: true
            }
          })
          return false
        } else if (fourteenDaysBeforeExp < new Date()) {
          // If current date within 14 days of exp,
          // dont allow update
          this.setState({alertDetails:
            {
              title: "Listing Notice",
              description: 'You cannot update your listing within 2 weeks of the expiration. You need to extend your listing expiration date if you want to update your listing.',
              open: true
            }
          })
          return false
        }
        return true
    }

    async handleSubmit(event) {
      if (this.validateListingAttributes(this.state.chosenResidence, this.state.capacity,
        this.state.stripeAccountId, this.state.verifiedVenmoHandle, this.state.expirationDate,
        this.state.firstName)) {
          if (this.state.verifiedVenmoHandle != "") {
            const user = await Auth.currentAuthenticatedUser();
            const result = await Auth.updateUserAttributes(user, {
                'custom:venmoHandle': this.state.verifiedVenmoHandle
            });
          }
          this.setState({showPublishModal: true});
          this.checkForListingPosted("create");
          const fileName = this.state.tempListingId;
          this.createNewListing(fileName)
      }
    }

    async isLoggedIn() {
      try {
          let user = await Auth.currentAuthenticatedUser()
          if (this.state.username === "") {
            var schoolOptions = [];
            let breaks = JSON.parse(utils.getCurrentSchoolAttribute(user.attributes.email, "breakDateRanges"));

            let residenceData = JSON.parse(utils.getCurrentSchoolAttribute(user.attributes.email, "residences"));
            var tempBreakStageDict = this.state.breakStageDict;

            for (const [key, value] of Object.entries(breaks)) {
              // find the break stage where the name matches and update the date range
              schoolOptions.push({label: key, value: value})
              for (const property in tempBreakStageDict) {
                let newBreak = tempBreakStageDict[property]
                if (newBreak.title.includes(key)) {
                  // update the date range
                  tempBreakStageDict[property].dateRange = value
                  tempBreakStageDict[property].timeFrame = dateRangeUtils.getTimeFrameLabel(tempBreakStageDict[property].dateRange);
                }
              }
            }

            let stripeId = user.attributes['custom:stripeAccountId'];
            let userListingId = user.attributes['custom:listingId'];
            this.setState({
              residences: residenceData.residences,
              allOptions: schoolOptions,
              breakStageDict: tempBreakStageDict,
              stripeAccountId: stripeId != null ? stripeId : "",
              userListingId: userListingId != null ? userListingId : "",
              isEditing: userListingId != null,
              email: user.attributes.email,
              username: user.attributes.email.substr(0, user.attributes.email.indexOf('@'))});
          }
          return true;
      } catch {
        if (window.tempSchool?.emailExtension && this.state.residences.length == 0) {
          let residenceData = JSON.parse(utils.getCurrentSchoolAttribute(window.tempSchool?.emailExtension, "residences"));
          this.setState({
            residences: residenceData.residences,
          });
        }
        return false;
      }
    }

    async clearCache(cachedState) {
      this.setState(JSON.parse(cachedState));
      Cache.removeItem("createListingState");
    }

    async saveNewPaymentInfo(newAccountJson) {
      let newAccount = JSON.parse(newAccountJson);
      Cache.removeItem("newAccount");
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(user, {
          'custom:stripeAccountId': newAccount.id
      });

      // Emit stat for Stripe Account Creation
      try {
        const currentSchoolName = utils.getCurrentSchoolAttribute(this.state.email, "name");
        ReactGA.event({
          category: utils.formatSchoolName(currentSchoolName),
          action: 'Entered Stripe',
          label: 'create_listing.jsx',
        });
      } catch(error) {
        console.log("Failed: " + error.message);
      }

      this.setState({stripeAccountId: newAccount.id, paymentIdentifier: newAccount.id});
    }

    async getCurrentUsersListing() {
      let listingToEdit = await utils.getCurrentUsersListing(this.state.userListingId);
      if (listingToEdit.data.getListing) {
        let currentListing = listingToEdit.data.getListing
        this.setState({
          loadingEditListing: false,
          isEditing: true,
          listingToEdit: currentListing,
          capacity: currentListing.capacity,
          firstName: currentListing.firstName,
          paymentIdentifier: currentListing.paymentIdentifier,
          expirationDate: new Date((currentListing.expirationDate)),
          chosenResidence: currentListing.residence_id,
          username: currentListing.user_id,
          email: currentListing.listerEmail,
          chosenImageUrl: currentListing.img_url,
          text: currentListing.message,
          unavailableRanges: JSON.parse(currentListing.unavailableRanges), // Test this
          checkedDelivery: currentListing.includesDelivery
       });
      }
    }

    render() {
      if (!this.state.showingSignUpModal) {
        this.isLoggedIn();
      }

      let url = window.location.href[window.location.href.length-1] == "/" ? window.location.href.slice(0, -1) : window.location.href
      let urlComponents = url.split("/");
      let status = urlComponents[urlComponents.length - 1];
      var enablePublishListing = false;
      if (this.state.stripeAccountId != "" || this.state.verifiedVenmoHandle != "") {
        enablePublishListing = true;
      }

      if (status == "addedPayments") {
        enablePublishListing = true;
        let newAccountJson = Cache.getItem("newAccount");
        let cachedState = Cache.getItem("createListingState");
        if (cachedState != null) {
          this.clearCache(cachedState)
        }
        if (newAccountJson) {
          this.saveNewPaymentInfo(newAccountJson);
        }
      } else if ((this.state.isEditing || status == "edit") && this.state.listingToEdit == null && this.state.userListingId != "") {
        this.getCurrentUsersListing();
      }

      var currentStage = 0
      if (this.state.expirationDate != null && this.state.firstName != "") {
        currentStage = 2
      } else if (this.state.capacity > 0 && this.state.chosenResidence !== "-1") {
        currentStage = 1
      }
      let stageColors = { 0: ["#cccccc", "#cccccc"], 1: ["#fdac16", "#cccccc"], 2: ["#fdac16", "#fdac16"] };

      const progressBar = (
        <div hidden={this.state.isEditing} style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
          <div className="ml-1" style={{width: "46%", height: 6, backgroundColor: stageColors[currentStage][0], borderRadius: 3}}></div>
          <div className="ml-1" style={{width: "46%", height: 6, backgroundColor: stageColors[currentStage][1], borderRadius: 3}}></div>
        </div>)

      var total = this.state.capacity * 34
      total = this.state.checkedDelivery ? total + this.state.capacity * 25 : total

      const CustomCheckbox = withStyles({
        root: {
          color: "#fdad16",
          '&$checked': {
            color: "#fdad16",
          },
        },
        checked: {},
      })((props) => <Checkbox color="default" {...props} />);

      const CustomOptions = ["Unavailable","At School"]
      const ExtraOptions = ["No","Yes"]

      var textField = (<div className="ml-2 mr-2 mb-2 mt-4">
        <textarea key={uuidv4()} name="description" cols="40" rows="10" class="form-control"
          data-toggle="popover" data-content="Add a description" data-placement="top"
          onclick="toolTipHide('id_description')" placeholder="Description..." id="id_description"
          onChange={this.textChanged}>
          {"Hey there!\n\nThanks for choosing to store your items with me! Swing by to drop off your item(s). Let me know what's" +
          " the best way to contact you and we can coordinate picking up and dropping off during any of the dates I'm available.\n\nBest, \n" + this.state.firstName}
        </textarea>
      </div>);
      const selector = (<div key={this.state.selectedUnavailability}>
        <h5 align="left">🚫 Unavailability</h5>
        <Selector key={uuidv4()} options={this.state.allOptions} defaultValue={this.state.selectedUnavailability} updateHandler={this.handleUnavailabilityUpdate} ></Selector>
        <p style={{color: 'grey'}} align="left">(You can't pick up or drop off boxes during these times)</p>
        <br></br>
      </div>);
      var jobDescriptionTitle = (
        <div align="left">
          <b className="mt-4" style={{fontWeight: "600"}}>Pick Up/Drop Off</b>
        </div>)
      let jobDescription =  <div align="left">
        <span disabled class="text-body" rows={"3"} style={{paddingBottom: 0, marginTop: 0, marginBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", resize: "none", backgroundColor: "#ffffff00"}}>
          {
            "As a lister you're responsible for coordinating the times that your storers will bring you their items to your place. If there are dates when you can't transfer items:"
          }
        </span>
        <button className="button_empty" style={{marginLeft: 0, color:"#43495e", textDecoration: "underline" }}  onClick={() => this.setState({showUnavailableDates: !this.state.showUnavailableDates})}><b>{this.state.showUnavailableDates ? "Hide Unavailable Dates" : "Enter Unavailable Dates"}</b></button>
      </div>

      var introTitle = (
        <div align="left">
          <b className="mt-4" style={{fontWeight: "600"}}>Become a StoreStash lister</b>
        </div>)
      let introDescription =  <div align="left">
        <span disabled class="text-body" rows={"3"} style={{paddingBottom: 0, marginTop: 0, marginBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", resize: "none", backgroundColor: "#ffffff00"}}>
          {
            "Congrats! You're just steps away from making money on StoreStash. Fill out your listing details to continue..."
          }
        </span>
      </div>

      var deliveryTitle = (
        <div class="row" style={{width: "100%", margin:0}}>
          <b className="mt-4" style={{fontWeight: "600"}}>Delivery (Optional)</b>
        </div>)

      var deliveryDescription = <span disabled class="text-body" rows={"3"} style={{float: "left", paddingBottom: 0, marginTop: 0, marginBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", resize: "none", backgroundColor: "#ffffff00"}}>
        {
          "If you included delivery, then you will coordinate when and where to pick you will pick up their items."
        }
      </span>



      let stripeButton = (
        <button hidden={(enablePublishListing || this.state.isEditing || currentStage <= 2) && !this.state.showPaymentMethods} onClick={this.addPayment} style={{height: 35, borderRadius: 25, borderColor: "#cccccc", paddingLeft: 10, paddingRight: 10, fontFamily:'glacialindifference-bold', backgroundColor: "white", color: "#43495e"}}>
          {this.state.size == 3 || this.state.size == 0 ? "Stripe Pay" : "Get paid w/ Stripe"}
        </button>
      )

      let venmoButton = (
        <button hidden={(enablePublishListing || this.state.isEditing || currentStage <= 2) && !this.state.showPaymentMethods} onClick={this.showVenmoModal} style={{height: 35,  marginLeft: -5, borderRadius: 25, borderColor: "#cccccc", paddingLeft: 10, paddingRight: 10, fontFamily:'glacialindifference-bold', backgroundColor: "white", color: "#43495e"}}>
          {this.state.size == 3 || this.state.size == 0 ? "Venmo Pay" : this.state.getPaidText}
        </button>
    )

      var paymentsTitle = (
        <div class="row" style={{width: "100%", margin:0}}>
          <b className="mt-4" style={{fontWeight: "600"}}>Payments</b>
        </div>)
      let paymentsDescription =  <div align="left" class="mb-3">
        <span disabled class="text-body" rows={"3"} style={{paddingBottom: 0, marginTop: 0, marginBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", resize: "none", backgroundColor: "#ffffff00"}}>
          {
            "Choose between Stripe and Venmo to receive payments. Just sign up and you're ready to make money!"
          }
        </span>
        <button className="button_empty" style={{marginLeft: 0, color:"#43495e", textDecoration: "underline" }} onClick={() => this.setState({showPaymentMethods: !this.state.showPaymentMethods})}><b>{this.state.showPaymentMethods ? "Hide Payment Options" : (this.state.isEditing ? "Edit Payment Info" : "Add Payment Info")}</b></button>
        <div style={{width: "100%", marginTop: 40, display: "flex", justifyContent: "space-between"}}>
          <div hidden={(enablePublishListing || this.state.isEditing || currentStage <= 2) && !this.state.showPaymentMethods} style={{width: "43%", display: "flex", justifyContent: "space-between"}}>
            <img src={this.state.username ? venmoLogo : grayVenmoLogo} style={{height: 35, marginLeft: this.state.size < 5 && "45%", marginRight: -20}}></img>
            {this.state.size == 5 && venmoButton}
          </div>
          <p  style={{height: 35, width: 42, borderRadius: 25, borderColor: "#cccccc", marginTop: 5, marginLeft: 10, color: "#bbbbbb"}}
              hidden={(enablePublishListing || this.state.isEditing || currentStage <= 2 || this.state.getPaidText == "Edit Venmo Details") && !this.state.showPaymentMethods}>
              {"-OR-"}
          </p>
          <div hidden={(enablePublishListing || this.state.isEditing || currentStage <= 2 || this.state.getPaidText == "Edit Venmo Details") && !this.state.showPaymentMethods} style={{width: "45%", display: "flex", justifyContent: "space-between"}}>
            <img src={this.state.username ? stripeLogo : grayStripeLogo} style={{height: 35, marginLeft: this.state.size == 5 ? 10 : "38%", marginRight: 10}}></img>
            {this.state.size == 5 && stripeButton}
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
          {this.state.size < 5 && venmoButton}
          <div style={{width: this.state.size == 3 || this.state.size == 0 ? "20%" : "15%"}}></div>
          {this.state.size < 5 && stripeButton}
        </div>
        {this.state.loadingStripe && <div><CircularProgress style={{color: "#625BF6", marginTop: 20, marginLeft: "45%"}}/></div>}
        {this.state.loadingStripe && <div align="center" style={{color:"#43495e"}}><b>Loading Stripe...</b></div>}
      </div>

      var currentBreak = this.state.breakStageDict[this.state.breakStage];
      var breakView = this.state.breakStage < 3 && (<div>
        <div class="row mt-2">
          <div class="col">
            <h5 align="left">{currentBreak.title}</h5>
            <h6 align="left">{currentBreak.timeFrame}</h6>
          </div>
          <div class="col">
            <ReactSimpleOptionsSelector
              align="left"
              selected_text_color="#ffffff"
              selected_border_color="#ffffff"
              selected_background_color="#fdad16"
              onSelectionChange={(name, selected) => {
                this.setState({selectedIndex: CustomOptions.indexOf(selected[0])})
              }}
              options={
                CustomOptions.map((value, index) => {
                  return {
                    id:value,
                    label:value,
                    selected: (index === 1)
                  }
                })
              }
            />
            <p align="left" class="mb-0" style={{ color: "grey", fontSize: 14 }}>{this.state.selectedIndex == 0 ? "You are unavailable to pick up and drop off items" : "You are available to pick up and drop off items" }</p>
          </div>
      </div>
      <div class="row">
        <div class="col">
          <button class="button button_secondary" onClick={this.handlePrevious}>
           {"< Back "}
          </button>
        </div>
        <div class="col"></div>
        <div class="col">
          <button class="button button_secondary" onClick={this.handleNext}>
            {this.state.breakStage == 2 ? "Done" : "Next >" }
          </button>
        </div>
      </div>
    </div>);
    let addedPaymentsCheckmark = (
      <div style={{marginTop: -30}} hidden={this.state.isEditing}>
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
        <h5>Created your payment profile</h5>
      </div>)

      let listingUnfilled = (this.state.capacity <= 0 || this.state.chosenResidence === "-1")

      var calendar = (<div>
        <br></br>
        <div class="row">
          <div class="col">
            <h5 align="left">{"📅 Other Unavailablility"}</h5>
          </div>
          <div class="col">
            <ReactSimpleOptionsSelector
              align="right"
              selected_text_color="#ffffff"
              selected_border_color="#ffffff"
              selected_background_color="#fdad16"
              onSelectionChange={(name, selected)=>{
                var tempBreaks = this.state.unavailable_breaks;
                if (selected[0] === "Yes") {
                  tempBreaks[3] = "Yes";
                } else {
                  tempBreaks[3] = "No"
                }
                this.setState({unavailable_breaks : tempBreaks });
              }}
              options={
                ExtraOptions.map((value, index)=>{
                  return {
                    id:value,
                    label:value,
                    selected: (index === 0)
                  }
                })
              }
            />
          </div>
      </div>
      </div>);
      let options = this.state.residences.map((location) =>
        <option selected={this.state.chosenResidence == location} value={location}>{location}</option>
      )

      let secondStage = (
        <div>
          <div class={this.state.size < 4 ? "" : "row"}>
            <div class="col">
              <p align="left" style={{marginLeft: 10, color: "#aaaaaa", fontSize: 13, marginBottom: -1}}>{this.state.chosenResidence.length >= 13 ? "When do you leave " + this.state.chosenResidence.substring(0,11) + "..?" : "When do you leave " + this.state.chosenResidence + "?"}</p>
              <DatePicker format='dd MMM yyyy' minimumDate={new Date().addDays(60)} maximumDate={new Date().setFullYear(new Date().getFullYear() + 4)} onDateChange={this.expirationDateChange} date={this.state.expirationDate} locale={enGB}>
                {({ inputProps, focused }) => (
                  <input
                    style={{width: "90%", height: 40, textAlign: "center", marginTop: 1}}
                    className={'input' + (focused ? ' -focused' : '')}
                    {...inputProps}
                    placeholder='Expiration (approx.)'
                  />
                )}
              </DatePicker>
            </div>
            <div class="col" style={{marginBottom: this.state.size < 4 && 20}}>
              <div class="form-label-group ml-2 mt-1" style={{marginRight: 10}}>
                  <p align="left" style={{marginTop: -3, color: "#aaaaaa", fontSize: 13}}>For your customers</p>
                  <input style={{width: "100%", marginTop: -15}} class="form-control" value={this.state.firstName} placeholder={"First Name"} required="" onChange={this.firstNameChanged}></input>
              </div>
            </div>
        </div>
      </div>);

      return (
        <div class="container mt-5">
          <MetaTags>
            <title>StoreStash: List your space and earn big this summer</title>
            <meta id="meta-description" name="description" content="Sign up, list your space, and start earning! Yes, it's that simple."/>
          </MetaTags>
          {this.state.alertDetails != null &&
            <AlertDialog
              title={this.state.alertDetails.title}
              description={this.state.alertDetails.description}
              open={this.state.alertDetails.open}
              onClose={()=> this.setState({alertDetails: null})}
            />
          }
          <Modal centered show={this.state.showPublishModal} backdrop="static" keyboard={false}>
            <Modal.Header>
              <Modal.Title>{"Publishing Your Listing..."}</Modal.Title>
              <Modal.Body>
                <CircularProgress style={{position: "absolute", color: "#fdad16", marginTop:100, marginLeft: -150}}/>
                <img className="img_preview" src={logoImage}></img>
              </Modal.Body>
            </Modal.Header>
          </Modal>
          <Modal show={this.state.showUpdateModal} backdrop="static" keyboard={false}>
            <Modal.Header>
              <Modal.Title>{"Updating Your Listing..."}</Modal.Title>
              <Modal.Body>
                <CircularProgress style={{position: "absolute", color: "#fdad16", marginTop:100, marginLeft: -150}}/>
                <img className="img_preview" src={logoImage}></img>
              </Modal.Body>
            </Modal.Header>
          </Modal>
          <Modal centered show={this.state.showSignUpNotice} backdrop="static" keyboard={false} onHide={() => this.setState({showSignUpNotice: false})} align="center">
            <Modal.Header closeButton>
              <Modal.Title>Please create an account to earn as a StoreStash lister.</Modal.Title>
            </Modal.Header>
            <Modal.Footer style={{display: "flex", justifyContent: "center"}}>
              <Button className="button_primary" onClick={this.showSignUp} style={{fontWeight: "bold", backgroundColor: "#fdad16", width: "40%", borderColor: "#ffffff00"}}>
                Okay
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal centered show={this.state.showVenmoModal} backdrop="static" keyboard={false} onHide={() => this.setState({showVenmoModal: false})} align="center">
            <Modal.Header closeButton>
              <Modal.Title>Get Paid through Venmo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Control type="text" onChange={(newText) => this.setState({venmoHandle: newText.target.value})} defaultValue={this.state.venmoHandle} placeholder={"Venmo Handle"}/>
                <Form.Control type="text" onChange={(newText) => this.setState({confirmVenmoHandle: newText.target.value})} value={this.state.confirmVenmoHandle} placeholder="Confirm Venmo Handle"/>
                {this.state.loadingVenmo && <div><CircularProgress style={{color: "#fdad16"}}/></div>}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer style={{display: "flex", justifyContent: "center"}}>
              <Button className="button_primary" onClick={this.submitVenmo} style={{fontWeight: "bold", backgroundColor: "#fdad16", width: "40%", borderColor: "#ffffff00"}}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
          <div class="row mb-5">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style={{marginBottom: 25, marginTop: this.state.size < 3 ? 0 : 10}}>
              <fieldset class="border p-2" styles={{borderRadius: 10}}>
                <legend class="w-auto"><h5 style={{fontSize:"25px"}}>{this.state.isEditing ? "Edit Your Space" : "Your Storage Space"}</h5></legend>
                {progressBar}
                {(this.state.isEditing && this.state.loadingEditListing) && <div><CircularProgress style={{color: "#fdad16", marginTop: 20}}/></div>}
                <div class={this.state.isEditing ? "row" : "row mt-2"}>
                  <div class="col">
                    <div class="form-label-group" hidden={(this.state.isEditing && this.state.loadingEditListing)}>
                        <input style={{width: "100%"}} type='number' value={this.state.capacity == -1 ? null : this.state.capacity} class="form-control" placeholder="# of boxes (1-20)" required="" onChange={this.handleCapacityChange}></input>
                    </div>
                    {this.state.capacity > 0 && <div class="card ml-2 mt-3" style={{borderRadius: 12}}>
                      <div class="card-body">
                        <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                          <FormControlLabel
                            control={<CustomCheckbox checked={this.state.checkedDelivery} onChange={this.handleToggleDelivery} name="checkedG" />}
                            label="Include Delivery"
                          />
                          <img className="img_icon" onClick={this.showDeliveryInfo} src={deliveryInfoImage} style={{marginTop: 8, cursor: "pointer"}}>
                          </img>
                        </div>
                        <hr></hr>
                        {this.state.checkedDelivery && <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                          <p>{"(" + this.state.capacity + "x)" + " Delivery"}</p>
                          <p>{"$" + (this.state.capacity * 25)}</p>
                        </div>}
                        <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                          <p>{"(" + this.state.capacity + "x)" + " Storage"}</p>
                          <p>{"$" + (this.state.capacity * 34)}</p>
                        </div>
                        <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                          <p><b>{"You Earn:"}</b></p>
                          <p><b>{"$" + total.toFixed(2)}</b></p>
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div class="col" hidden={(this.state.isEditing && this.state.loadingEditListing)}>
                    <select style={{width: 238, marginLeft: 7}} name="state" onChange={this.residenceChange} class="browser-default custom-select copy-address-state mt-1 mb-1" id="id_state">
                      <option selected={this.state.chosenResidence == "-1"} value="-1">Choose a Residence</option>
                      {options}
                      <option selected={this.state.chosenResidence == "Other (in state home)"} value="Other (in state home)">Other (in state home)</option>
                      <option selected={this.state.chosenResidence == "Other"} value="Other">Other</option>
                    </select>
                    {this.state.chosenResidence !== "-1" &&
                    <div>
                      {this.state.loadingImage ? <div><CircularProgress style={{color: "#fdad16", marginTop:20}}/></div> :
                        <ImageUpload textMarginTop={10} handleImageUpload={this.handleImageUpload} defaultImage={this.state.chosenImageUrl != "" ? this.state.chosenImageUrl : logoImage}></ImageUpload>
                      }

                    </div>}
                  </div>
              </div>
              <br></br>

              {currentStage >= 1 && secondStage}
              {currentStage == 2 && textField}
              </fieldset>
            </div>

            <div class="col">
              <fieldset class="border p-2" style={{borderRadius: 10, marginTop: this.state.size < 3 ? 0 : 10}}>
                <legend class="w-auto"><h5 style={{fontSize:"25px"}}>{listingUnfilled ? "Welcome!" : "Job Description" }</h5></legend>
                {listingUnfilled && introTitle}
                {listingUnfilled && introDescription}
                {!listingUnfilled && jobDescriptionTitle}
                {!listingUnfilled && jobDescription}
                {!listingUnfilled && this.state.showUnavailableDates && (this.state.selectedUnavailability.length > 0 || this.state.breakStage == 3) && selector}
                {!listingUnfilled && this.state.showUnavailableDates && breakView}
                {!listingUnfilled && this.state.showUnavailableDates && this.state.breakStage == 3 && calendar}
                {!listingUnfilled && this.state.showUnavailableDates && this.state.unavailable_breaks.includes("Yes") && <DateRangePickerExample key={uuidv4()} locale={enGB} handleNewUnavailabilityRange={this.handleNewUnavailabilityRange} selectedUnavailability={this.state.selectedUnavailability} />}
                {!listingUnfilled && deliveryTitle}
                {!listingUnfilled && deliveryDescription}
                {!listingUnfilled && paymentsTitle}
                {!listingUnfilled && paymentsDescription}
              </fieldset>
              <br></br>
              <br></br>
              {enablePublishListing && addedPaymentsCheckmark}
              <div style={{width: "100%", marginTop: 30}}>
                <button hidden={this.state.isEditing} class={enablePublishListing ? "button_primary" : "button_disabled"} onClick={this.handleSubmit}>
                  Post Storage Space
                </button>
                <div hidden={this.state.isEditing} style={{width: "35%", marginLeft: "32%", marginTop: 10, marginBottom: 20}}>
                  <span style={{marginLeft: 0, color:"grey", fontSize: 10}}>By clicking "Post Storage Space", you agree  to our</span>
                  <button className="button_empty" style={{marginLeft: 0, color:"grey", fontSize: 10, textDecoration: "underline"}}  onClick={() => window.open("http://storestash-terms.s3-website-us-west-2.amazonaws.com/", "_blank")}>{"Terms & Conditions"}</button>
                </div>
              </div>
              <button hidden={!this.state.isEditing} class={"home_onboard_customer"} onClick={this.handleUpdateListing}>
                Done Editing
              </button>
            </div>
          </div>
        </div>
      );
    }
}

export default withRouter(CreateListing);
