import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { CircularProgress } from '@material-ui/core';
import { byListing } from '.././graphql/queries';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import emptyReservations from '.././assets/empty_reservations.png';

const referralColumns = [
  { id: 'email', label: 'Email', minWidth: 170 },
  { id: 'items', label: 'Items', minWidth: 100 },
]

const reservationColumns = [
  { id: 'customer', label: 'Customer', minWidth: "15%" },
  { id: 'items', label: 'Items', minWidth: 200 },
  {
    id: 'storage',
    label: 'Storage',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'delivery',
    label: 'Delivery',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'total',
    label: 'Total',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'start',
    label: 'Start Date',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'end',
    label: 'End Date',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toFixed(2),
  },
];

function createData(customer, rawItems, includesDelivery, start, end ) {
  // Parse items
  const itemsObject = JSON.parse(rawItems).items.sort((a, b) => a.name > b.name ? 1 : -1);
  console.log(itemsObject, "items object");
  const itemsMap = {"suitcase": '🧳', "box": '📦', "bicycle": '🚲', 'mini_fridge':'❄️'};
  let items = "";
  let storage = 0;
  for (var i = 0; i < itemsObject.length; i++) {
    const currentItem = itemsObject[i];
    const name = currentItem.name;
    const quantity = currentItem.quantity;
    storage += quantity * 34;
    for (var j = 0; j < quantity; j++) {
      items += itemsMap[name];
      if (i != itemsObject.length - 1 && j == quantity - 1) {
        items += ", "
      }
    }
  }
  const delivery = includesDelivery ? "$" + JSON.parse(rawItems).deliveryCost : "N/A";
  console.log("delivery cost", delivery);
  let total = includesDelivery ? storage + JSON.parse(rawItems).deliveryCost : storage;
  storage = "$" + storage;
  total = "$" + total;
  return { customer, items, storage, delivery, total, start, end };
}

async function getReservations(listingId) {
  const reservationsResponse = await API.graphql(graphqlOperation(byListing, {listing_id: listingId}));
  const reservations = reservationsResponse.data.byListing.items;
  let rows = [];
  if (reservations && reservations.length > 0) {
    reservations.sort((a, b) => Date.parse(a.startDate) > Date.parse(b.startDate) ? 1 : -1);
    var today = new Date();
    // If the last start date is surpassed, sort by the end dates
    if (today > Date.parse(reservations[reservations.length - 1].startDate)) {
      reservations.sort((a, b) => Date.parse(a.endDate) > Date.parse(b.endDate) ? 1 : -1);
    }
    for (var i = 0; i < reservations.length; i++) {
      const reservation = reservations[i];
      try {
        rows.push(createData(reservation.customerEmail, reservation.purchaseDetails, reservation.purchasedDelivery, reservation.startDate, reservation.endDate));
      } catch (error) {
        console.log("Error:", error);
      }
    }
  }
  console.log("Rows to return", rows, listingId);
  return rows;
}

let rows = [];

export default function EarningsTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoadingReservations, setIsLoadingReservations] = React.useState(true);

  const reservations = [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    let isMounted = true;               // note mutable flag
    if (isLoadingReservations) {
      getReservations(props.listingId).then(data => {
        if (isMounted) {
          rows = data;
          setIsLoadingReservations(false);
        }     // add conditional check
      })
    }
    return () => { isMounted = false }; // cleanup toggles value, if unmounted
  }, []);                               // adjust dependencies to your needs

  var columns;

  if (props.listingId) {
    columns = reservationColumns;
  } else {
    columns = reservationColumns;
  }


  if (isLoadingReservations) {
    return (
      <div>
        <CircularProgress style={{color: "#fdad16", marginTop:"10%"}}/>
      </div>
    )
  }

  return (
    <Paper style={{width: "98%", marginTop: 30}} sx={{ width: '98%', overflow: 'hidden'}}>
      <TableContainer sx={{ maxHeight: 640}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b style={{fontFamily:'glacialindifference-bold', color: "#43495e", fontSize: 18}}>{column.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      console.log(value, column);
                      return (
                        <TableCell key={column.id} align={column.align} style={{color: column.id == "total" ? "green" : "black", fontWeight: column.id == "total" ? "bold" : "normal"}}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        hidden={!rows || rows.length == 0}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      { /* Empty Reservations View */}
      <div hidden={rows && rows.length > 0}>
        <img style={{width: 250, marginTop: 20}} src={emptyReservations}></img>
        <h5 class="card-title" style={{color: "#43495e"}}>{"No Reservations Yet"}</h5>
      </div>
    </Paper>
  );
}
