Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export function getTimeFrameLabel(dateRange) {
  let currentDateRange = dateRange.split("+");
  let start = getDateLabel(new Date(currentDateRange[0]).addDays(1))
  let end = getDateLabel(new Date(currentDateRange[1]).addDays(1))
  console.log(start, end, "these are the dates from the range");
  console.log("(" + start + " - " + end + ")");
  return "(" + start + " - " + end + ")"
}

export function getDateLabel(date) {
  let monthDict = {
    0:'Jan', 1:'Feb', 2:'Mar',3:'Apr', 4:'May', 5:'Jun', 6:'Jul',
    7:'Aug', 8:'Sep', 9:'Oct', 10:'Nov', 11:'Dec'
  }
  var dateObject = null;
  if (typeof(date) == "string") {
    dateObject = new Date(date).addDays(1);
  } else if (typeof(date) == "object") {
    dateObject = date;
  }
  return monthDict[dateObject.getMonth()] + " " + (dateObject.getDate())
}

export function isAvailableDate(startDate, endDate, dateRanges) {
  var unavailableDates = getDatesFromRange(JSON.parse(dateRanges).map(entry => entry.value)).map(unavailableDate => unavailableDate.toDateString());
  console.log(unavailableDates, startDate);
  if (startDate != null && unavailableDates.includes(startDate.toDateString())) {
    console.log(startDate, "start contained in unavailablility");
    return false;
  } else if (endDate != null && unavailableDates.includes(endDate.toDateString())) {
    console.log(endDate, "end contained in unavailablility");
    return false;
  }
  return true;
}

export function getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    currentDate = new Date(currentDate).addDays(1);

    while (currentDate <= stopDate) {
        dateArray.push(new Date (currentDate));
        currentDate = currentDate.addDays(1);
    }
    dateArray.push(new Date (stopDate).addDays(1));
    return dateArray;
}

export function getDatesFromRange(datesRanges) {
  var dates = [];
  for (var i = 0; i < datesRanges.length; i++) {
    let currentDateRange = datesRanges[i].split("+");
    dates = dates.concat(getDates(Date.parse(currentDateRange[0]), Date.parse(currentDateRange[1])));
  }
  return dates;
}
