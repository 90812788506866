import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import deliveredImage from '.././assets/delivered.png';
import boxesImage from '.././assets/boxes.png';
import Footer from './footer';
import { withRouter } from 'react-router';
import packagingImage from '.././assets/packaging.png';

const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://serverlessrepo-serverless-s3-uploader-s3bucket-ot2sgee2clv3.s3-us-west-2.amazonaws.com/assets/upperclassmen.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Pricing extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  constructor(props) {
    super(props);
    this.state = {
      checkedDelivery: false,
      capacity: 1,
      selectedCapacity : 1,
      checkedPackaging: false,
      boxesOptions: [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
      ]
    }
    this.handleToggleDelivery = this.handleToggleDelivery.bind(this);
    this.handleCapacityChange = this.handleCapacityChange.bind(this);
    this.handleToggleBoxes = this.handleToggleBoxes.bind(this);
  }

  handleToggleDelivery() {
    this.setState({checkedDelivery: !this.state.checkedDelivery})
  }

  handleToggleBoxes() {
    this.setState({checkedPackaging: !this.state.checkedPackaging})
  }

  handleCapacityChange = (capacity) => {
    this.setState({selectedCapacity: capacity});
  }

  render() {
    const { classes } = this.props;

    const CustomCheckbox = withStyles({
      root: {
        color: "#fdad16",
        '&$checked': {
          color: "#fdad16",
        },
      },
      checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const customStyles = {
      control: base => ({
        ...base,
        height: 32,
        minHeight: 32,
        minWidth: 70,
        marginBottom: 15,
        borderColor: "#ffffff00"
      }),
      valueContainer: base => ({
        ...base,
        padding: 0,
        paddingLeft: 10
      }),
      indicatorSeparator: base => ({
        ...base,
        display: "none"
      })
    };

    var total = this.state.selectedCapacity * 44
    let deliveryCost = 25 + (this.state.selectedCapacity - 1) * 5
    total = this.state.checkedDelivery ? total + deliveryCost : total
    total = this.state.checkedPackaging ? total + this.state.selectedCapacity * 4.5 : total

    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <div className="brand-registration">
              Pricing
            </div>

            <form className={classes.form} noValidate>
            <div class="form-label-group" align="left" style={{width: "100%", display: "flex", justifyContent: "space-between"}}>

            {<div class="card" style={{borderRadius: 12, width: "50%"}}>
            <div class="card-body">
            <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
              <FormControlLabel
                control={<CustomCheckbox checked={this.state.checkedDelivery} onChange={this.handleToggleDelivery} name="checkedG" />}
                label="Include Delivery"
                labelPlacement="end"
              />
              <img className="img_icon" src={deliveredImage}></img>
            </div>
            <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
              control={<CustomCheckbox checked={this.state.checkedPackaging} onChange={this.handleToggleBoxes} name="checkedG" />}
              label="Include Packaging"
              labelPlacement="end"
            />
            <img className="img_icon" src={packagingImage}></img>
            </div>
            <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={{ value: this.state.selectedCapacity, label: "" + this.state.selectedCapacity}}
                  isSearchable={false}
                  options={this.state.boxesOptions}
                  onChange={(capacity)=>this.handleCapacityChange(capacity.value)}
                  styles={customStyles}
                ></Select>
              <img className="img_icon" src={boxesImage}></img>
            </div>
              <hr></hr>
              {this.state.checkedDelivery && <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                <p>{"Delivery Charge"}</p>
                <p>{"$" + deliveryCost + ".00"}</p>
              </div>}
              {this.state.checkedPackaging && <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                <p>{"(" + this.state.selectedCapacity + "x)" + " Packaging"}</p>
                <p>{"$" + (this.state.selectedCapacity * 4.5).toFixed(2)}</p>
              </div>}
              <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                <p>{"(" + this.state.selectedCapacity + "x)" + " Storage"}</p>
                <p>{"$" + (this.state.selectedCapacity * 44).toFixed(2)}</p>
              </div>
              <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                <p><b>{"Total"}</b></p>
                <p><b>{"$" + total.toFixed(2)}</b></p>
              </div>
            </div>
            </div>}

            <div align="left" style={{width: "50%", paddingLeft: 15, paddingTop: 5}}>
              <span disabled class="text-body" rows={"3"} style={{paddingBottom: 0, marginTop: 0, marginBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", resize: "none", backgroundColor: "#ffffff00"}}>
                {
                  "Once you purchase sign up and purchase your space, you'll get an email connecting you with your lister."
                }
              </span>
              <br></br>
              <br></br>
              <span disabled class="text-body" rows={"3"} style={{paddingBottom: 0, marginTop: 0, marginBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", resize: "none", backgroundColor: "#ffffff00"}}>
                {
                  "\n\nCoordinte where and when your items will be transfered to your lister."
                }
              </span>
              <br></br>
              <br></br>
              <span disabled class="text-body" rows={"3"} style={{paddingBottom: 0, marginTop: 0, marginBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", resize: "none", backgroundColor: "#ffffff00"}}>
                {
                  "\n\nYou can store suitcases, boxes, and now bikes. To store a bike, you'll need to purchase two units of space."
                }
              </span>
            </div>
            </div>
            </form>
          </div>
        </Grid>
        <Grid item xs={false} sm={4} md={7} className={classes.image}/>
        <Footer showAuthModal={this.props.showAuthModal} style="pricing-footer"></Footer>
      </Grid>

    );
  }
}
export default withStyles(styles, { withTheme: true })(Pricing);
