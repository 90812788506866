import React, { Component } from 'react';
import Amplify, { API, graphqlOperation, Auth, Cache } from 'aws-amplify'
import axios from "axios";
import * as queries from '.././graphql/queries';
import PayButton from './pay_button';
import ContactUs from './contact_us';
import { CircularProgress } from '@material-ui/core';
import Select from 'react-select';
import * as reservationCache from './reservation_cache.js';
import Selector from './selector';
import { v4 as uuidv4 } from 'uuid';
import AlertDialog from './alert';
import FadeIn from 'react-fade-in';
import * as utils from '.././utils.js';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

// Date picker
import { format } from 'date-fns'
import { enGB } from 'date-fns/locale'
import { DatePickerCalendar } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import { getDay } from 'date-fns';
import { isSameDay } from 'date-fns';
import * as dateRangeUtils from '.././date_range.js';

// React Bootstrap
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

// Images
import infoImage from '.././assets/info.png';
import leftArrowImage from '.././assets/left_arrow.png';
import rightArrowImage from '.././assets/right_arrow.png';
import deliveredImage from '.././assets/delivered.png';
import listItemsImage from '.././assets/list_items.png';
import listItemsPendingImage from '.././assets/list_items_pending.png';
import checkoutBillImage from '.././assets/checkout_bill.png';
import boxesImage from '.././assets/boxes.png';
import packagingImage from '.././assets/packaging.png';
import calendarEnteredImage from '.././assets/calendar_entered.png'
import calendarPendingImage from '.././assets/calendar_pending.png'

import awsExports from ".././aws-exports";
Amplify.configure(awsExports);

class ReservationDetails extends Component {

  constructor(props) {
    super(props);
    // Emit stat for page view
    ReactGA.pageview(window.location.pathname);

    this.handleToggleDelivery = this.handleToggleDelivery.bind(this);
    this.handleToggleBoxes = this.handleToggleBoxes.bind(this);
    this.queryListingFromURL = this.queryListingFromURL.bind(this);
    this.queryPurchasedSpaceFromURL = this.queryPurchasedSpaceFromURL.bind(this);
    this.handleStartDateChosen = this.handleStartDateChosen.bind(this);
    this.handleEndDateChosen = this.handleEndDateChosen.bind(this);
    this.handleCapacityChange = this.handleCapacityChange.bind(this);
    this.handleItemTypeUpdate = this.handleItemTypeUpdate.bind(this);
    this.handleItemQuantityUpdate = this.handleItemQuantityUpdate.bind(this);
    this.showVenmoModal = this.showVenmoModal.bind(this);
    this.handlePaymentTypeUpdate = this.handlePaymentTypeUpdate.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.submitVenmo = this.submitVenmo.bind(this);
    this.handleConfirmationCodeUpdate = this.handleConfirmationCodeUpdate.bind(this);
    this.submitConfirmationCode = this.submitConfirmationCode.bind(this);
    this.getTotalPayment = this.getTotalPayment.bind(this);
    this.showStep1 = this.showStep1.bind(this);
    this.showStep2 = this.showStep2.bind(this);
    reservationCache.updateReservation("paymentType", "Credit Card");
    this.state = {
      hideOld: true,
      windowWidth: 0,
      size: 5,
      selectedStartDateLabel: [],
      selectedEndDateLabel: [],
      choosingItemType: true,
      checkedDelivery: false,
      checkedPackaging: false,
      showCheckout: false,
      showVenmoModal: false,
      alertDetails: null,
      paymentCompletionEnabled: false,
      loadingVenmo: false,
      currentListing: null,
      selectedCapacity : -1,
      spaceRemaining: -1,
      howItWorksStage: 1,
      howItWorksLabel1: -1,
      howItWorksLabel2: -1,
      howItWorksLabel3: -1,
      howItWorksLabel4: -1,
      openedCalendar: "",
      purchasedReservation: null,
      paymentType: "Credit Card",
      confirmationCode: "",
      enteredConfirmationCode: "",
      selectedItemTypes: [],
      selectedItemQuantities: [],
      currentItem: {},
      itemTypeOptions: [
        { value: "box", price: 44, quantity: 0, spaceRequired: 1, originalLabel: "Box", label: "Box", quantityLabel: "Box(es)" },
        { value: "suitcase", price: 44, quantity: 0, spaceRequired: 1, originalLabel: "Suitcase", label: "Suitcase", quantityLabel: "Suitcase(s)" },
        { value: "bicycle", price: 68, quantity: 0, spaceRequired: 2, originalLabel: "Bicycle", label: "Bicycle", quantityLabel: "Bicycle(s)" },
        { value: "mini_fridge", price: 70, quantity: 0, spaceRequired: 1, originalLabel: "Mini Fridge", label: "Mini Fridge", quantityLabel: "Mini Fridge(s)" }
      ],
      itemQuantityOptions: [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
        { value: 11, label: "11" },
        { value: 12, label: "12" },
        { value: 13, label: "13" },
        { value: 14, label: "14" },
        { value: 15, label: "15" },
        { value: 16, label: "16" },
        { value: 17, label: "17" },
        { value: 18, label: "18" },
        { value: 19, label: "19" },
        { value: 20, label: "20" }
      ],
      boxesOptions: [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
        { value: 11, label: "11" },
        { value: 12, label: "12" },
        { value: 13, label: "13" },
        { value: 14, label: "14" },
        { value: 15, label: "15" },
        { value: 16, label: "16" },
        { value: 17, label: "17" },
        { value: 18, label: "18" },
        { value: 19, label: "19" },
        { value: 20, label: "20" }
      ]
    }
  }

  showVenmoModal() {
    // Emit stat when Venmo Modal Appears
    try {
      const schoolName = utils.getCurrentSchoolAttribute(this.state.user.email, "name");
      ReactGA.event({
        category: utils.formatSchoolName(schoolName),
        action: 'Viewed Venmo Modal',
        label: 'reservation_details.jsx',
      });
    } catch (error) {
      console.log("Failure: " + error.message);
    }
    this.setState({showVenmoModal: true});
  }

  showStep1() {
    this.setState({isReviewingStep1: true, isReviewingStep2: false});
  }

  showStep2() {
    this.setState({isReviewingStep1: false, isReviewingStep2: true});
  }


  handleConfirmationCodeUpdate(event) {
    if (event.target.value != null && event.target.value.length == 4) {
      this.setState({paymentCompletionEnabled: true, enteredConfirmationCode: event.target.value});
    } else {
      this.setState({paymentCompletionEnabled: false, enteredConfirmationCode: event.target.value});
    }
  }

  async submitConfirmationCode() {
    this.setState({loadingVenmo: true});
    if (this.state.paymentCompletionEnabled) {
      if (this.state.confirmationCode == this.state.enteredConfirmationCode) {
        const handle = this.state.venmoHandle.charAt(0) == "@" ? this.state.venmoHandle.substring(1) : this.state.venmoHandle;
        let body = { handle: handle, code: this.state.confirmationCode, venmo_user_id: this.state.venmoUserId };
        const response = await API.post('venmoApi', '/verify-transaction-completed', { body });
        if (response["success"] !== undefined) {
          // On success, send the email and report to the user that it's being sent
          // TODO comeback here
          console.log("Success: Correct confirmation code");
          this.setState({loadingVenmo: false});
          reservationCache.updateReservation("listing_id", this.state.currentListing.id);
          this.props.history.push("/profile/recentlyPurchased_" + this.state.currentListing.id);
        } else if (response["pending"] !== undefined) {
          // Confirmation code has been sent but the customer has tried to confirm the purchase too early
          this.setState({loadingVenmo: false, alertDetails:
            {
              title: "Payment Notice",
              description: "Almost there! Make sure you've completed the payment by accepting the payment request sent to your Venmo. Then press the 'Payment Completed' button",
              open: true
            }
          });
        } else if (response["error"] !== undefined) {
          // Venmo API error
          this.setState({loadingVenmo: false, alertDetails:
            {
              title: "Payment Error",
              description: response["error"],
              open: true
            }
          })
          return
        }
      } else {
        console.log("Error: Incorrect confirmation code");
        this.setState({loadingVenmo: false, alertDetails:
          {
            title: "Code Notice",
            description: "The confirmation code you entered was incorrect",
            open: true
          }
        });
      }
    } else {
      // Show error for incorrect length of confirmation code
      this.setState({loadingVenmo: false, alertDetails:
        {
          title: "Code Notice",
          description: "You must enter the 4 digit code sent to your venmo (" + this.state.confirmVenmoHandle + ")",
          open: true
        }
      });
    }

  }

  async submitVenmo() {
    this.setState({loadingVenmo: true});
    if (this.state.venmoHandle != this.state.confirmVenmoHandle) {
      this.setState({loadingVenmo: false, alertDetails:
        {
          title: "Handle Notice",
          description: "The venmo handles you entered do not match",
          open: true
        }
      })
      return
    }

    const body = { "handle" : this.state.venmoHandle }
    const response = await API.post('venmoApi', '/verify-handle', { body });
    if (response["error"] !== undefined) {
      this.setState({loadingVenmo: false, alertDetails:
        {
          title: "Venmo Notice",
          description: "We couldn't find the venmo handle you entered.",
          open: true
        }
      })
      return
    } else if (response["success"] !== undefined) {
      var generatedCode = String(Math.floor(1000 + Math.random() * 9000));
      //const body = { "handle" : this.state.venmoHandle, "user_id": response["user_id"], "confirmation_code" : generatedCode, "payment_amount" : this.getTotalPayment().total};
      let body = Cache.getItem("urlPrefix").includes("mystorestash") ?
        // prod payment info
        { "handle" : this.state.venmoHandle, "user_id": response["user_id"], "confirmation_code" : generatedCode, "payment_amount" : this.getTotalPayment(this.state.selectedItemTypes, 0.0).total}
        :
        // dev payment info
        { "handle" : this.state.venmoHandle, "user_id": response["user_id"], "confirmation_code" : generatedCode, "payment_amount" : 0.01};
      this.setState({verifiedVenmoHandle: this.state.venmoHandle.charAt(0) == "@" ? this.state.venmoHandle.substring(1) : this.state.venmoHandle, venmoUserId: response["user_id"], loadingVenmo: false, confirmationCode: generatedCode});
      const paymentResponse = await API.post('venmoApi', '/send-payment-request', { body });
      if (paymentResponse["success"] !== undefined) {
        this.setState({verifiedVenmoHandle: this.state.venmoHandle, loadingVenmo: false, confirmationCode: generatedCode});
      } else if (paymentResponse["error"] !== undefined) {
        this.setState({loadingVenmo: false, alertDetails:
          {
            title: "Payment Notice",
            description: paymentResponse["error"],
            open: true
          }
        });
      }
    }
  }

  closeModal() {
    this.setState({showContactUs: false})
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillMount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    var size = 0
    if (windowWidth >= 450) {
      size = 1
    }
    if (windowWidth >= 576) {
      size = 2
    }
    if (windowWidth >= 768) {
      size = 3
    }
    if (windowWidth >= 992) {
      size = 4
    }
    if (windowWidth >= 1200) {
      size = 5
    }
    this.setState({windowWidth, size});
  }

  componentWillMount() {
    var listing = this.props.history.location.listing;

    if (listing != undefined) {
      // Get space remaining
      console.log("Not UNDEFINED");
      var spaceRemaining = listing.capacity;
      if (listing.reservations.items.length > 0) {
        spaceRemaining -= listing.reservations.items.map(res => + res.purchasedSpace).reduce((a, b) => a + b, 0);
      }

      reservationCache.updateReservation("listing_id", listing.id);
      let reservation = reservationCache.getReservation();
      this.setState({
        spaceRemaining: spaceRemaining,
        currentListing: listing,
        checkedDelivery: reservation.purchasedDelivery,
        checkedPackaging: reservation.purchasedPackaging,
        selectedCapacity: reservation.purchasedSpace,
        selectedStartDateLabel: reservation.startDate != "" ? [{value: "startDate" + reservation.startDate, label: dateRangeUtils.getDateLabel(reservation.startDate)}] : [],
        selectedEndDateLabel: reservation.endDate != "" ? [{value: "endDate" + reservation.endDate, label: dateRangeUtils.getDateLabel(reservation.endDate)}] : []
      });
    } else if (window.location.href.includes("view"))  {
      console.log("QUERY LISTING FROM purchased");
      this.queryPurchasedSpaceFromURL()
    } else {
      console.log("QUERY LISTING FROM URL");
      this.queryListingFromURL()
    }
  }

  async queryPurchasedSpaceFromURL() {
    let urlComponents = window.location.href.split("_");
    let listingId = urlComponents[urlComponents.length - 2];
    let reservationId = urlComponents[urlComponents.length - 1];
    const queriedListing = await API.graphql({ query: queries.getListing, variables: { id: listingId }});
    let purchasedReservation = queriedListing.data.getListing.reservations.items.filter(reservation => reservation.id == reservationId)[0]
    this.setState({
      currentListing: queriedListing.data.getListing,
      purchasedReservation: purchasedReservation,
      checkedDelivery: purchasedReservation.purchasedDelivery,
      checkedPackaging: purchasedReservation.purchasedPackaging,
      selectedCapacity: purchasedReservation.purchasedSpace,
      selectedStartDateLabel: purchasedReservation.startDate != "" ? [{value: "startDate" + purchasedReservation.startDate, label: dateRangeUtils.getDateLabel(purchasedReservation.startDate)}] : [],
      selectedEndDateLabel: purchasedReservation.endDate != "" ? [{value: "endDate" + purchasedReservation.endDate, label: dateRangeUtils.getDateLabel(purchasedReservation.endDate)}] : []
    });
  }

  async queryListingFromURL() {
    let reservation = reservationCache.getReservation();
    let urlComponents = window.location.href.split("/");
    var currentId = urlComponents[urlComponents.length - 1];
    currentId = currentId.replace('?', '');
    const listingResponse = await API.graphql({ query: queries.getListing, variables: { id: currentId }});
    const queriedListing = listingResponse.data.getListing;
    var spaceRemaining = queriedListing.capacity;
    if (queriedListing.reservations.items.length > 0) {
      spaceRemaining -= queriedListing.reservations.items.map(res => + res.purchasedSpace).reduce((a, b) => a + b, 0);
    }

    let reservationDetails = {
      isMocked: queriedListing.isMocked,
      alreadyPurchased: reservation.alreadyPurchased,
      loggedIn: this.state.user != null,
      listingId : queriedListing.id,
      customer_id: this.state.user != null ? this.state.user.username : "n/a",
      customerEmail: this.state.user != null ? this.state.user.email : "n/a",
      listerEmail: queriedListing.listerEmail,
      purchasedPackaging: reservation.purchasedPackaging,
      purchasedDelivery: reservation.purchasedDelivery,
      purchasedSpace: reservation.purchasedSpace,
      startDate : reservation.startDate,
      endDate: reservation.endDate
    }
    reservationCache.setReservation(reservationDetails);

    this.setState({
      spaceRemaining: spaceRemaining,
      currentListing: queriedListing,
      checkedDelivery: reservation.purchasedDelivery,
      checkedPackaging: reservation.purchasedPackaging,
      selectedCapacity: reservation.purchasedSpace,
      selectedStartDateLabel: reservation.startDate != "" ? [{value: "startDate" + reservation.startDate, label: dateRangeUtils.getDateLabel(reservation.startDate)}] : [],
      selectedEndDateLabel: reservation.endDate != "" ? [{value: "endDate" + reservation.endDate, label: dateRangeUtils.getDateLabel(reservation.endDate)}] : []
    });
  }

  handleStartDateChosen = (chosenDate) => {
    let dateString = format(chosenDate, 'yyyy-MM-dd', { locale: enGB });
    reservationCache.updateReservation("startDate", dateString);
    this.setState({
      openedCalendar: "",
      startDate: chosenDate,
      selectedStartDateLabel: [{value: "startDate" + dateString, label: dateRangeUtils.getDateLabel(chosenDate)}]
    });
  }

  handleEndDateChosen = (chosenDate) => {
    let dateString = format(chosenDate, 'yyyy-MM-dd', { locale: enGB });
    reservationCache.updateReservation("endDate", dateString);
    this.setState({
      openedCalendar: "",
      endDate: chosenDate,
      selectedEndDateLabel: [{value: "endDate" + format(chosenDate, 'yyyy-MM-dd', { locale: enGB }), label: dateRangeUtils.getDateLabel(chosenDate)}]
    });
  }

  handleItemQuantityUpdate = (quantities) => {
    var selectedItemTypes = this.state.selectedItemTypes;
    selectedItemTypes[selectedItemTypes.length - 1].label = selectedItemTypes[selectedItemTypes.length - 1].label + " (" + String(quantities[0].value) + "x)";
    selectedItemTypes[selectedItemTypes.length - 1].quantity = quantities[0].value
    console.log("HANDLE new QUANTITY:", this.state.currentItem);
    console.log("New space remaining:", this.state.spaceRemaining - quantities[0].value * this.state.currentItem.spaceRequired);



    // Remove this eventually. Should only allow selection of available space quantity
    let newSpaceRemaining = this.state.spaceRemaining - quantities[0].value * this.state.currentItem.spaceRequired;
    if (newSpaceRemaining < 0) {
      this.setState({alertDetails:
        {
          title: "Storage Space Notice",
          description: "There is not enough space in this unit. Bicycle's take 2 units of space. The item(s) you have chosen will max out the space of this listing.",
          open: true
        },
        choosingItemType: true
      });
    } else {
      this.setState({selectedItemQuantities: quantities, selectedItemTypes: selectedItemTypes, choosingItemType: true, spaceRemaining: this.state.spaceRemaining - quantities[0].value * this.state.currentItem.spaceRequired});
    }
  }

  handleItemTypeUpdate = (types) => {
    if (types.length < this.state.selectedItemTypes.length) {
      // Removing Item
      let selectedValues = this.state.selectedItemTypes.map(a => a.value);
      let allOptions = [...this.state.itemTypeOptions];
      var updatedOptions = [];
      var remainingOptions = types;

      var currentTypes = [];
      var spaceRemaining = this.state.currentListing.capacity;

      // When a user removes an item, the item's quantity should be restored to the spaceRemaining
      for (var i = 0; i < allOptions.length; i++) {
        let currentOption = allOptions[i];
        if (!remainingOptions.includes(currentOption)) {
          if (currentOption.label.includes("(")) {
            allOptions[i].label = currentOption.originalLabel;
            remainingOptions.push(allOptions[i]);
          } else {
            if (!selectedValues.includes(allOptions[i].value)) {
              remainingOptions.push(allOptions[i])
            }
          }
        } else {
          console.log("this is now a current option", currentOption);
          spaceRemaining -= currentOption.spaceRequired;
          currentTypes.push(currentOption);
        }
      }
      console.log("Space remaining:", spaceRemaining);
      this.setState({choosingItemType: true, selectedItemTypes: currentTypes, itemTypeOptions: allOptions, spaceRemaining: spaceRemaining});
    } else {
      // Adding Item
      if (types.length > 0) {

        var currentTypes = this.state.selectedItemTypes;
        let newType = types[types.length - 1]
        currentTypes.push(newType);

        console.log(newType, "this is the new time");
        console.log(this.state.selectedItemTypes, "this is the new item type");

        console.log("spaceRemaining", this.state.spaceRemaining);
        console.log("spaceRequired", newType.spaceRequired);
        if ((this.state.spaceRemaining - newType.spaceRequired) < 0) {
          let newSelectedItemTypes = this.state.selectedItemTypes;
          newSelectedItemTypes.pop()
          this.setState({alertDetails:
            {
              title: "Storage Space Notice",
              description: this.state.spaceRemaining == 0 ? "There is no more space in this unit. The item(s) you have chosen will max out the space of this listing." : "There is not enough space in this unit. The item(s) you have chosen will max out the space of this listing.",
              open: true
            }, selectedItemTypes: newSelectedItemTypes
          });
          return
        } else {
          this.setState({selectedItemTypes: currentTypes, choosingItemType: false, currentItem: newType});
        }
      }
    }
  }

  handleCapacityChange = (capacity) => {
    reservationCache.updateReservation("purchasedSpace", capacity);
    this.setState({selectedCapacity: capacity});
  }

  handleToggleDelivery() {
    reservationCache.updateReservation("purchasedDelivery", !this.state.checkedDelivery);
    this.setState({checkedDelivery: !this.state.checkedDelivery});
  }

  handleToggleBoxes() {
    reservationCache.updateReservation("purchasedPackaging", !this.state.checkedPackaging);
    this.setState({checkedPackaging: !this.state.checkedPackaging});
  }

  handlePaymentTypeUpdate = (event) => {
    let paymentType = event.target.value;
    reservationCache.updateReservation("paymentType", paymentType);
    this.setState({paymentType: paymentType});
  }

  getTotalPayment(selectedItems, purchaseTotal) {
    var total = 0;
    var totalQuantity = 0;
    var boxesCount = 0;
    var purchasedItems = [];
    var itemizedPurchase = selectedItems.map(function(item, i) {
      if (item.quantity > 0) {
        console.log("check the quantity and price:", item.price, item.quantity);
        totalQuantity += item.quantity;
        let itemTypePrice = item.quantity * item.price;
        total += itemTypePrice;
        if (item.value == "box") {
          boxesCount = item.quantity
        }
        purchasedItems.push({
          name: item.value,
          price: item.price,
          quantity: item.quantity
        });
        return (<div class="row" style={{width: "100%", margin: 0, display: "flex", justifyContent: "space-between"}}>
          <p>{"(" + item.quantity + "x) " + item.originalLabel}</p>
          <p>{"$" + itemTypePrice.toFixed(2)}</p>
          <div hidden={purchaseTotal == 0} class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
            <p><b>{"Total"}</b></p>
            <p><b>{"$" + purchaseTotal.toFixed(2)}</b></p>
          </div>

        </div>);
      }
    });

    let deliveryCost = 25 + (totalQuantity-1) * 5
    total = this.state.currentListing.includesDelivery && this.state.checkedDelivery ? total + deliveryCost : total
    total = this.state.checkedPackaging ? total + boxesCount * 4.5 : total
    reservationCache.updateReservation("purchasedSpace", totalQuantity);

    let purchaseDetails = JSON.stringify({
      items: purchasedItems,
      deliveryCost: deliveryCost,
      totalCost: total
    });
    reservationCache.updateReservation("purchaseDetails", purchaseDetails);
    return { total : total, totalQuantity: totalQuantity, boxesCount: boxesCount,
             deliveryCost: deliveryCost, itemizedPurchase: itemizedPurchase, purchaseDetails: purchaseDetails };
  }

  async isLoggedIn() {
    let currentReservation = JSON.parse(Cache.getItem("reservation"));
    try {
      let user = await Auth.currentAuthenticatedUser()
      console.log(user, "logged in user?");
      if (this.state.user == null) {

        // Emit stat when User Views Space
        try {
          const schoolName = utils.getCurrentSchoolAttribute(user.attributes.email, "name");
          ReactGA.event({
            category: utils.formatSchoolName(schoolName),
            action: 'Viewed Listing',
            label: 'reservation_details.jsx',
          });
        } catch (error) {
          console.log("Failure: " + error.message);
        }

        let stripeId = user.attributes['custom:stripeAccountId'];
        let userListingId = user.attributes['custom:listingId'];
        let reservationId = user.attributes['custom:reservationId'];
        let currentUser = {
          stripeAccountId: stripeId != null ? stripeId : "",
          userListingId: userListingId != null ? userListingId : "",
          reservationId: reservationId != null ? reservationId : "",
          email: user.attributes.email,
          username: user.attributes.email.substr(0, user.attributes.email.indexOf('@'))}
        this.setState({user: currentUser});
        reservationCache.updateReservation("customerEmail", user.attributes.email);
      }
      return true;
    } catch {
      console.log("No user");
      return false;
    }

  }

  render() {
    this.isLoggedIn();
    let listing = this.state.currentListing;
    // Customer is reviewing his/her own purchase
    let isViewingPurchasedReservation = this.state.purchasedReservation;
    var purchasedItems = [];
    var purchasedTotal = 0.0;
    if (isViewingPurchasedReservation) {
      let purchaseDetails = JSON.parse(isViewingPurchasedReservation.purchaseDetails);
      purchasedTotal = purchaseDetails.totalCost;
      for (var i = 0; i < purchaseDetails.items.length; i++) {
        let purchasedItem = purchaseDetails.items[i];
        purchasedItems.push({
          label: purchasedItem.name.charAt(0).toUpperCase() + purchasedItem.name.slice(1) + " (" + String(purchasedItem.quantity) + "x)",
          originalLabel: purchasedItem.name.charAt(0).toUpperCase() + purchasedItem.name.slice(1),
          quantity: purchasedItem.quantity,
          price: purchasedItem.price
        });
      }
    }

    if (listing == null) {
      return (
        <div>
          <br></br>
          <br></br>
          <br></br>
          <CircularProgress style={{color: "#fdad16"}}/>
        </div>
      );
    }
    const CustomCheckbox = withStyles({
      root: {
        color: "#fdad16",
        '&$checked': {
          color: isViewingPurchasedReservation ? "lightgrey" : "#fdad16",
        },
      },
      checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const CustomRadio = withStyles({
      root: {
        color: "#aaaaaa",
        '&$checked': {
          color: "#fdad16",
        },
      },
      checked: {},
    })((props) => <Radio color="default" {...props} />);

    const customSelectStyle = base => ({
        height: 50,
    });

    const customStyles = {
      control: base => ({
        ...base,
        height: 32,
        minHeight: 32,
        minWidth: 70,
        marginBottom: 15,
        borderColor: "#ffffff00"
      }),
      valueContainer: base => ({
        ...base,
        padding: 0,
        paddingLeft: 10
      }),
      indicatorSeparator: base => ({
        ...base,
        display: "none"
      })
    };

    let totalPaymentDetails = this.getTotalPayment(isViewingPurchasedReservation ? purchasedItems : this.state.selectedItemTypes, purchasedTotal);
    if (this.state.showCheckout) {
      return (
        <div>
          Checkout
        </div>
      );
    }

    var unavailableLabels = JSON.parse(listing.unavailableRanges).map(unavailablility => " " + unavailablility.label);
    var unavailableRanges = JSON.parse(listing.unavailableRanges).map(unavailablility => unavailablility.value);
    let disabledDates = dateRangeUtils.getDatesFromRange(unavailableRanges);

    const modifiers = {
      disabled: date => disabledDates.some(selectedDate => isSameDay(selectedDate, date))
    }

    let startDateObject = this.state.selectedStartDateLabel.length > 0 && new Date(this.state.selectedStartDateLabel[0].value.substring(9)).addDays(1);
    let endDateObject = this.state.selectedEndDateLabel.length > 0 && new Date(this.state.selectedEndDateLabel[0].value.substring(7)).addDays(1);
    const startDatePicker = (
      <div style={{marginTop: -25}}>
        <DatePickerCalendar
          date={startDateObject}
          onDateChange={this.handleStartDateChosen}
          minimumDate={new Date().addDays(1)}
          maximumDate={endDateObject}
          modifiers={modifiers}
          locale={enGB}
        />
      </div>
    );

    const endDatePicker = (
      <div style={{marginTop: -25}}>
        <DatePickerCalendar
          date={endDateObject}
          onDateChange={this.handleEndDateChosen}
          minimumDate={startDateObject ? startDateObject.addDays(21) : new Date().addDays(21)}
          maximumDate={startDateObject && startDateObject.addDays(150)}
          modifiers={modifiers}
          locale={enGB}
        />
      </div>
    );

    var isHeightSet = this.state.howItWorksLabel1 != -1 && this.state.howItWorksLabel2 != -1 && this.state.howItWorksLabel3 != -1 && this.state.howItWorksLabel4 != -1;
    const leftColumn = (
      <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12" style={{margin: 0, paddingLeft: 0, paddingRight: 0}}>
        <div class="row justify-content-between border-bottom pb-3" style={{margin: 0, paddingLeft: 0, paddingRight: 0}}>
          <div class="card-body" style={{margin: 0, paddingLeft: 0, paddingRight: 0}}>
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
              <p class="text-body" style={{fontSize: 24, textAlign: "left", marginTop: 10, lineHeight: "25px"}}><b>{"How It Works"}</b></p>
              <button class="button_secondary" style={{height: 40, paddingLeft: 20, textAlign: "right", lineHeight: "22px", marginTop: this.state.size < 2 ? 10 : 0}} onClick={()=>this.setState({showContactUs: true})}>{"📝 Contact StoreStash"}</button>
            </div>


            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
              <div style={{width: "2%", marginTop: "3%"}}>
                <div class="vertical-line" style={{height: "96%"}}></div>
                  <div onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 1 ? this.state.howItWorksStage - 1 : 1})} style={{cursor: "pointer", position: "absolute", width: 20, height: 20, backgroundColor: "lightgrey", marginTop: "2%", borderRadius: 10, marginLeft: -8}}>
                    <div style={{position: "absolute", width: 14, height: 14, backgroundColor: this.state.howItWorksStage < 1 ? "lightgrey" : "#fdad16", marginTop: 3, marginLeft: 3, borderRadius: 7}}></div>
                  </div>

                  <div onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 2 ? this.state.howItWorksStage - 1 : 2})} style={{cursor: "pointer", position: "absolute", width: 20, height: 20, backgroundColor: "lightgrey", marginTop: isHeightSet ? (6 + this.state.howItWorksLabel2 - this.state.howItWorksLabel1) : "23%", borderRadius: 10, marginLeft: -8}}>
                    <div style={{position: "absolute", width: 14, height: 14, backgroundColor: this.state.howItWorksStage < 2 ? "lightgrey" : "#fdad16", marginTop: 3, marginLeft: 3, borderRadius: 7}}></div>
                  </div>

                  <div onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 3 ? this.state.howItWorksStage - 1 : 3})} style={{cursor: "pointer", position: "absolute", width: 20, height: 20, backgroundColor: "lightgrey", marginTop: isHeightSet ? (6 + this.state.howItWorksLabel3 - this.state.howItWorksLabel1) : "44%", borderRadius: 10, marginLeft: -8}}>
                    <div style={{position: "absolute", width: 14, height: 14, backgroundColor: this.state.howItWorksStage < 3 ? "lightgrey" : "#fdad16", marginTop: 3, marginLeft: 3, borderRadius: 7}}></div>
                  </div>

                  <div onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 4 ? this.state.howItWorksStage - 1 : 4})} style={{cursor: "pointer", position: "absolute", width: 20, height: 20, backgroundColor: "lightgrey", marginTop: isHeightSet ? (6 + this.state.howItWorksLabel4 - this.state.howItWorksLabel1) : "66%", borderRadius: 10, marginLeft: -8}}>
                    <div style={{position: "absolute", width: 14, height: 14, backgroundColor: this.state.howItWorksStage < 4 ? "lightgrey" : "#fdad16", marginTop: 3, marginLeft: 3, borderRadius: 7}}></div>
                  </div>
              </div>

              <div style={{width: "95%"}}>
                <p onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 1 ? this.state.howItWorksStage - 1 : 1})} ref={element => { if (!element) return; if (this.state.howItWorksLabel1 != element.getBoundingClientRect().top) this.setState({howItWorksLabel1: element.getBoundingClientRect().top}) }} class="mt-3 mb-1" style={{fontSize: 18, width: "max-content", cursor: "pointer", textAlign: "left", color: this.state.howItWorksStage == 1 ? "black" : "lightgrey"}}>
                  <b>1) Reserve</b> Storage Space
                </p>
                <ul hidden={this.state.howItWorksStage < 1} style={{fontSize: 16, textAlign: "left", color: this.state.howItWorksStage == 1 ? "black" : "lightgrey"}}>
                  <li> - Choose the timeline & items for your reservation.</li>
                  <li> - Select a payment method and reserve your space.</li>
                </ul>
                <p onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 2 ? this.state.howItWorksStage - 1 : 2})} ref={element => { if (!element) return; if (this.state.howItWorksLabel2 != element.getBoundingClientRect().top) this.setState({howItWorksLabel2: element.getBoundingClientRect().top}) }} class="mt-3 mb-1" style={{fontSize: 18, width: "max-content", cursor: "pointer", textAlign: "left", color: this.state.howItWorksStage == 2 ? "black" : "lightgrey"}}>
                  <b>2) Coordinate</b> With Your Lister
                </p>
                <ul hidden={this.state.howItWorksStage < 2} style={{fontSize: 16, textAlign: "left", color: this.state.howItWorksStage == 2 ? "black" : "lightgrey"}}>
                  <li> - Check your email/spam for your lister's contact info.</li>
                  <li> - Coordinate when to drop off your items.</li>
                </ul>
                <p onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 3 ? this.state.howItWorksStage - 1 : 3})} ref={element => { if (!element) return; if (this.state.howItWorksLabel3 != element.getBoundingClientRect().top) this.setState({howItWorksLabel3: element.getBoundingClientRect().top}) }} class="mt-3 mb-1" style={{fontSize: 18, width: "max-content", cursor: "pointer", textAlign: "left", color: this.state.howItWorksStage == 3 ? "black" : "lightgrey"}}>
                  <b>3) Drop Off</b> Your Items
                </p>
                <ul style={{fontSize: 16, textAlign: "left", color: this.state.howItWorksStage == 3 ? "black" : "lightgrey"}}>
                  <FadeIn>
                    <li hidden={this.state.howItWorksStage < 3}>{"Bring your items to " + listing.residence_id}</li>
                    <li hidden={this.state.howItWorksStage < 3}>(or choose a listing with delivery...)</li>
                  </FadeIn>
                </ul>
                <p onClick={() => this.setState({howItWorksStage: this.state.howItWorksStage == 4 ? this.state.howItWorksStage - 1 : 4})} ref={element => { if (!element) return; if (this.state.howItWorksLabel4 != element.getBoundingClientRect().top) this.setState({howItWorksLabel4: element.getBoundingClientRect().top}) }} class="mt-3 mb-1" style={{fontSize: 18, width: "max-content", cursor: "pointer", textAlign: "left", color: this.state.howItWorksStage == 4 ? "black" : "lightgrey"}}>
                  <b>4) Pick Up</b> Your Items
                </p>
                <ul style={{fontSize: 16, textAlign: "left", color: this.state.howItWorksStage == 4 ? "black" : "lightgrey"}}>
                  <li hidden={this.state.howItWorksStage < 4}>{"Get your items from " + listing.residence_id}</li>
                  <li hidden={this.state.howItWorksStage < 4}>(or choose a listing with delivery...)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-start mt-4" style={{margin: 0, paddingLeft: 0}}>
            <p class="text-body" style={{fontSize: 24, textAlign: "left"}}><b>Owner's Message</b></p>
        </div>
        <div class="row justify-content-start" style={{margin: 0, paddingLeft: 0}}>
          <p rows="10" style={{width: "100%", fontSize: 16, backgroundColor: "#ffffff00", color: "black", width: "100%", textAlign: "left", border: "none", resize: "none"}}>
            {listing.message}
          </p>
        </div>
      </div>
    )

    console.log(this.state.currentItem, "CHECK CURRENT ITEM");
    const itemTypeMenu = (this.state.choosingItemType ? <div class="row" style={{width: "100%", margin: "20px 0px 0px 0px", display: "flex", justifyContent: "space-between"}}>
      <img className="img_icon" style={{marginTop: 15}} src={this.state.selectedItemQuantities.length > 0 && this.state.selectedItemTypes.length > 0 ? listItemsImage : listItemsPendingImage}></img>
      <div style={{width: "87%", borderColor: "#aaaaaa", borderRadius: "5px"}}>
        <Selector style={{height: 200}} placeholder={"What will you be storing?"} isDisabled={isViewingPurchasedReservation} updateHandler={this.handleItemTypeUpdate} key={uuidv4()} options={this.state.itemTypeOptions} defaultValue={isViewingPurchasedReservation ? purchasedItems : this.state.selectedItemTypes}></Selector>
      </div>
    </div>
    :
    <div class="row" style={{width: "100%", margin: "20px 0px 0px 0px", display: "flex", justifyContent: "space-between"}}>
      <img className="img_icon" style={{marginTop: 10}} src={this.state.selectedItemQuantities.length > 0 && this.state.selectedItemTypes.length > 0 ? listItemsImage : listItemsPendingImage}></img>
      <div style={{width: "87%", borderColor: "#aaaaaa", borderRadius: "5px"}} >
        <Selector style={{height: 200}} placeholder={"How many " + this.state.currentItem.quantityLabel + "?"} updateHandler={this.handleItemQuantityUpdate} key={uuidv4()} options={this.state.itemQuantityOptions.filter(option => option.value * this.state.currentItem.spaceRequired <= this.state.spaceRemaining)}></Selector>
      </div>
    </div>
  );

  const startDatePickerView = (
  <div style={{margin: 0, height: this.state.openedCalendar == "startDate" ? "100%" : 0}}>
    <p style={{color: "#83848c", fontSize: 12, marginTop: 10}} hidden={this.state.openedCalendar != "startDate"} align="center">Choose when you'll Drop Off your items</p>
    {this.state.openedCalendar == "startDate" && startDatePicker}
    <textarea hidden={this.state.openedCalendar != "startDate" || unavailableLabels.length == 0} disabled class="text-body" rows={"3"} style={{paddingBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", resize: "none", backgroundColor: "#ffffff00"}}>
      {listing.user_id + " is unavailable during\n" + unavailableLabels}
    </textarea>
  </div>);

  const endDatePickerView = (
  <div style={{margin: 0, height: this.state.openedCalendar == "endDate" ? "100%" : 0}}>
    <p style={{color: "#83848c", fontSize: 12, marginTop: 10}} hidden={this.state.openedCalendar != "endDate"} align="center">Choose when you'll Pick Up your items</p>
    {this.state.openedCalendar == "endDate" && endDatePicker}
    <textarea hidden={this.state.openedCalendar != "endDate" || unavailableLabels.length == 0} disabled class="text-body" rows={"3"} style={{paddingBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", resize: "none", backgroundColor: "#ffffff00"}}>
      {listing.user_id + " is unavailable during\n" + unavailableLabels}
    </textarea>
  </div>);

  var currentStage = 0
  if (this.state.enteredConfirmationCode.length > 0) {
    currentStage = 3
  } else if (this.state.verifiedVenmoHandle != null && this.state.verifiedVenmoHandle != "") {
    currentStage = 2
  } else if (this.state.venmoHandle != null && this.state.venmoHandle != "") {
    currentStage = 1
  }

  if (this.state.isReviewingStep1) {
    currentStage = 1
  } else if (this.state.isReviewingStep2) {
    currentStage = 2
  }

  let stageData = {
    0 : { colors: ["#cccccc", "#cccccc", "#cccccc"], title: "Purchase with Venmo", subtitle: "Verify your handle"},
    1 : { colors: ["#fdad16", "#cccccc", "#cccccc"], title: "Purchase with Venmo", subtitle: "Verify your handle"},
    2 : { colors: ["#fdad16", "#fdad16", "#cccccc"], title: "Complete Purchase", subtitle: "Check your venmo notifications for a request from StoreStash. Once you complete the request, enter the four digit code below and you're all set!"},
    3 : { colors: ["#fdad16", "#fdad16", "#fdad16"], title: "Confirm Code", subtitle: "Check your venmo notifications for a request from StoreStash. Once you complete the request, enter the four digit code below and you're all set!"},
  }
  const progressBar = (
    <div hidden={this.state.isEditing} style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
      <div style={{marginLeft: "8%", width: "25%", height: 6, marginBottom: 20, backgroundColor: stageData[currentStage].colors[0], borderRadius: 3}}></div>
      <div style={{width: "25%", height: 6, marginBottom: 20, backgroundColor: stageData[currentStage].colors[1], borderRadius: 3}}></div>
      <div style={{marginRight: "8%", width: "25%", height: 6, marginBottom: 20, backgroundColor: stageData[currentStage].colors[2], borderRadius: 3}}></div>
    </div>);

    const alertView = (
      <div>
        {this.state.alertDetails != null && <AlertDialog
          title={this.state.alertDetails.title}
          description={this.state.alertDetails.description}
          open={this.state.alertDetails.open}
          onClose={() => this.setState({alertDetails: null})}
        />}
      </div>
    );

  const venmoModal = (<Modal show={this.state.showVenmoModal} style={{marginTop: "10%"}} backdrop="static" keyboard={false} onHide={() => this.setState({showVenmoModal: false})} align="center">
    <Modal.Header closeButton>
      <Modal.Title style={{float: "left", textAlign: "left"}}>
        {stageData[currentStage].title}
        <br></br>
        <small style={{color: "#6d757d", float: "left"}}>{stageData[currentStage].subtitle}</small>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group>
        {progressBar}
        {(currentStage == 0 || currentStage == 1) &&
          <div>
            <Form.Control type="text" onChange={(newText) => this.setState({venmoHandle: newText.target.value})} defaultValue={this.state.venmoHandle} placeholder={"Venmo Handle"}/>
            <Form.Control type="text" onChange={(newText) => this.setState({confirmVenmoHandle: newText.target.value})} value={this.state.confirmVenmoHandle} placeholder="Confirm Venmo Handle"/>
          </div>
        }
        {currentStage >= 2 &&
          <div>
            <Form.Control type="text" onChange={this.handleConfirmationCodeUpdate} value={this.state.enteredConfirmationCode} placeholder="Enter 4 Digit Code"/>
          </div>
        }
        {this.state.loadingVenmo && <div><CircularProgress style={{color: "#fdad16"}}/></div>}
      </Form.Group>
    </Modal.Body>
    <Modal.Footer style={{display: "flex", justifyContent: "center"}}>
      {(currentStage == 1 || currentStage == 0) && <div style={{width: "100%"}}>
          <Button className="button_primary" onClick={this.submitVenmo} style={{fontWeight: "bold", backgroundColor: "#fdad16", width: "40%", borderColor: "#ffffff00"}}>
            Verify
          </Button>
          <button hidden={!this.state.isReviewingStep1} onClick={this.showStep2} className="button_empty" style={{float: "right", marginTop: 8}}><img style={{height: 20, width: 20}} src={rightArrowImage}></img></button>
        </div>
      }
      {
        (currentStage >= 2) && <div style={{width: "100%"}}>
          <button className="button_empty" onClick={this.showStep1} style={{float: "left", marginTop: 8}}><img style={{height: 20, width: 20}} src={leftArrowImage}></img></button>
          <Button className="button_primary" onClick={this.submitConfirmationCode} style={{fontWeight: "bold", backgroundColor: this.state.paymentCompletionEnabled ? "#fdad16" : "#aaaaaa", width: "60%", borderColor: "#ffffff00"}}>
            Payment Completed
          </Button>
        </div>
      }
    </Modal.Footer>
  </Modal>);
  return (
    <div>
      {alertView}
      {venmoModal}
      {this.state.showContactUs && <ContactUs closeModal={this.closeModal} customerEmail={this.state.user != null ? this.state.user.email : ""} showContactUs={true}></ContactUs>}
      <main class="pt-2 pb-5 container">
        <section class="row" style={{marginLeft: "10%", marginRight: "10%"}}>
          {this.state.size > 4 && leftColumn}
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb-5 pl-md-4 pl-sm-0" style={{margin: 0, padding: 0}}>
              <div>
              <div class="mt-3 text-center">
                <img className="img_storage" src={listing.img_url}></img>
              </div>
                <div class="card mt-3" style={{borderRadius: 12, top: 10}}>
                  <div class="card-body">
                  <div class="row" style={{width: "100%", height: 40, margin:0, marginBottom: this.state.size == 0 ? 40 : 0, display: "flex", justifyContent: "space-between"}}>
                    <img className="img_icon" style={{marginTop: this.state.size == 0 ? 20 : 10}} src={(this.state.selectedStartDateLabel.length > 0 && this.state.selectedEndDateLabel.length > 0) ? calendarEnteredImage : calendarPendingImage}></img>
                    <div style={{width: "87%", display: "flex", justifyContent: "space-between"}}>
                      <div style={{width: "50%", border: '1px solid', borderColor: "#aaaaaa", borderWidth: "1px 0px 1px 1px", borderRadius: "5px 0px 0px 5px"}} >
                        <button style={{marginTop: 8, color: isViewingPurchasedReservation ? "lightgrey" : "black", marginLeft: 5, float: "left"}} onMouseEnter={() => this.setState({hoverDropOff: true})} onMouseLeave={() => this.setState({hoverDropOff: false})} className="button_empty" disabled={isViewingPurchasedReservation} onClick={() => this.state.openedCalendar == "startDate" ? this.setState({openedCalendar: ""}) : this.setState({openedCalendar: "startDate"})}>
                          <b>Drop Off: </b>{this.state.selectedStartDateLabel.length > 0 ? this.state.selectedStartDateLabel[0].label : "(?)"}
                        </button>
                      </div>
                      <div style={{width: "50%", border: '1px solid', borderColor: "#aaaaaa", borderWidth: "1px", borderRadius: "0px 5px 5px 0px"}}>
                        <button style={{marginTop: 8, marginTop: 8, color: isViewingPurchasedReservation ? "lightgrey" : "black", marginLeft: 5, float: "left"}} className="button_empty" disabled={isViewingPurchasedReservation} onClick={() => this.state.openedCalendar == "endDate" ? this.setState({openedCalendar: ""}) : this.setState({openedCalendar: "endDate"})}>
                          <b>Pick Up:</b> {this.state.selectedEndDateLabel.length > 0 ? this.state.selectedEndDateLabel[0].label : "(?)"}
                        </button>
                      </div>
                    </div>
                  </div>
                  {startDatePickerView}
                  {endDatePickerView}
                  {itemTypeMenu}

                  <div class="row" style={{width: "100%", height: 40, margin: "20px 0px 0px 0px", marginBottom: this.state.size == 0 ? 50 : 0, display: "flex", justifyContent: "space-between"}}>
                    <img className="img_icon" style={{marginTop: this.state.size == 0 ? 25 : 10}} src={deliveredImage}></img>
                    <div style={{width: "87%", zIndex: '0', display: "flex", justifyContent: "space-between"}}>
                      <div style={{height: this.state.size == 0 ? 65 : 40, width: "50%", border: '1px solid', borderColor: "#aaaaaa", borderWidth: "1px 0px 1px 1px", borderRadius: "5px 0px 0px 5px"}} >
                        <div>
                        <FormControlLabel
                          style={{float: "left", marginLeft: 1}}
                          disabled={isViewingPurchasedReservation || !listing.includesDelivery}
                          control={<CustomCheckbox checked={listing.includesDelivery && this.state.checkedDelivery} onChange={this.handleToggleDelivery} name="checkedG" />}
                          label={this.state.size == 0 ? "" : "+Delivery"}
                          labelPlacement="end"
                        />
                        <button hidden={this.state.size != 0} className="button_empty" style={{float: "left", marginLeft: 10, marginTop: -15, color: "black"}}>{"+Delivery"}</button>
                        </div>
                        <button hidden={this.state.size == 1 && this.state.size == 2} className="button_empty"><img src={infoImage} onClick={() => this.setState({loadingVenmo: false, alertDetails:
                          {
                            title: "Delivery",
                            description: "Your lister will pick up and drop off your items on the dates you choose. Once we get you in contact with your lister, just tell them where to meet you (within 5 minutes drive of main campus) and they'll take care of the rest.",
                            open: true
                          }
                        })} style={{width: 20, height: 20, marginTop: 9}}></img></button>
                      </div>
                      <div style={{height: this.state.size == 0 ? 65 : 40, zIndex: '0', width: "50%", border: '1px solid', borderColor: "#aaaaaa", borderWidth: "1px", borderRadius: "0px 5px 5px 0px"}}>
                        <div>
                          <FormControlLabel
                            style={{float: "left", marginLeft: 1}}
                            disabled={isViewingPurchasedReservation || totalPaymentDetails.boxesCount == 0}
                            control={<CustomCheckbox checked={this.state.checkedPackaging} onChange={this.handleToggleBoxes} name="checkedG" />}
                            label={this.state.size == 0 ? "" : "+Packaging"}
                            labelPlacement="end"
                          />
                          <button hidden={this.state.size != 0} className="button_empty" style={{float: "left", marginLeft: 0, marginTop: -15, color: "black"}}>{"+Packaging"}</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div hidden={this.state.selectedItemQuantities.length == 0} class="row" style={{width: "100%", height: 40, margin: "22px 0px 0px 0px", display: "flex", justifyContent: "space-between"}}>
                    <img className="img_icon" style={{marginTop: 10}} src={checkoutBillImage}></img>
                      <RadioGroup style={{width: "87%", zIndex: '0', display: "flex", justifyContent: "space-between"}} row aria-label="gender" name="gender1" value={this.state.paymentType} onChange={this.handlePaymentTypeUpdate}>
                        <div style={{height: 40, width: "50%", border: '1px solid', borderColor: "#aaaaaa", borderWidth: "1px 0px 1px 1px", borderRadius: "5px 0px 0px 5px"}}>
                          <FormControlLabel style={{float: "left", marginLeft: 1}} value="Credit Card" control={<CustomRadio />} label="Credit Card" />
                        </div>
                        <div style={{height: 40, zIndex: '0', width: "50%", border: '1px solid', borderColor: "#aaaaaa", borderWidth: "1px", borderRadius: "0px 5px 5px 0px"}}>
                          <FormControlLabel style={{float: "left", marginLeft: 1}} value="Venmo" control={<CustomRadio />} label="Venmo" />
                        </div>
                      </RadioGroup>
                  </div>

                  <div hidden={this.state.hideOld}>
                    <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                      <FormControlLabel
                        disabled={isViewingPurchasedReservation || !listing.includesDelivery}
                        control={<CustomCheckbox checked={listing.includesDelivery && this.state.checkedDelivery} onChange={this.handleToggleDelivery} name="checkedG" />}
                        label="Include Delivery"
                        labelPlacement="end"
                      />
                      <img className="img_icon" src={deliveredImage}></img>
                    </div>
                    <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                    <FormControlLabel
                      disabled={isViewingPurchasedReservation}
                      control={<CustomCheckbox checked={this.state.checkedPackaging} onChange={this.handleToggleBoxes} name="checkedG" />}
                      label="Include Packaging"
                      labelPlacement="end"
                    />
                    <img className="img_icon" src={packagingImage}></img>
                    </div>
                    <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                      {isViewingPurchasedReservation ?
                        <p><b>{this.state.selectedCapacity}</b></p>
                        :
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={{ value: this.state.selectedCapacity, label: "" + this.state.selectedCapacity}}
                          isSearchable={false}
                          options={this.state.boxesOptions.filter(option => option.value <= this.state.spaceRemaining)}
                          onChange={(capacity)=>this.handleCapacityChange(capacity.value)}
                          styles={customStyles}
                        ></Select>
                      }
                      <img className="img_icon" src={boxesImage}></img>
                    </div>
                      <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                      <button onMouseEnter={() => this.setState({hoverDropOff: true})} onMouseLeave={() => this.setState({hoverDropOff: false})} className="button_empty" disabled={isViewingPurchasedReservation} onClick={() => this.state.openedCalendar == "startDate" ? this.setState({openedCalendar: ""}) : this.setState({openedCalendar: "startDate"})}>
                        <b>Drop Off: </b>{this.state.selectedStartDateLabel.length > 0 ? this.state.selectedStartDateLabel[0].label : "(?)"}
                      </button>
                      <img className="img_icon" src={calendarEnteredImage}></img>
                      </div>
                      <p hidden={this.state.openedCalendar != "startDate"} align="left">Choose when you'll drop off your items</p>
                      <br hidden={this.state.openedCalendar == "startDate"}></br>
                      {this.state.openedCalendar == "startDate" && startDatePicker}
                      <textarea hidden={this.state.openedCalendar != "startDate" || unavailableLabels.length == 0} disabled class="text-body" rows={"3"} style={{paddingBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", resize: "none", backgroundColor: "#ffffff00"}}>
                        {listing.user_id + " is unavailable during\n" + unavailableLabels}
                      </textarea>
                      <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                        <button className="button_empty" disabled={isViewingPurchasedReservation} onClick={() => this.state.openedCalendar == "endDate" ? this.setState({openedCalendar: ""}) : this.setState({openedCalendar: "endDate"})}>
                          <b>Pick Up:</b> {this.state.selectedEndDateLabel.length > 0 ? this.state.selectedEndDateLabel[0].label : "(?)"}
                        </button>
                        <img className="img_icon" src={calendarEnteredImage}></img>
                      </div>
                      <div>
                        <RadioGroup style={{display: "flex", justifyContent: "space-between", marginLeft: 10}} row aria-label="gender" name="gender1" value={this.state.paymentType} onChange={this.handlePaymentTypeUpdate}>
                          <FormControlLabel style={{marginTop: 10}} value="Venmo" control={<CustomRadio />} label="Venmo" />
                          <FormControlLabel style={{marginTop: 10}} value="Credit Card" control={<CustomRadio />} label="Credit Card" />
                        </RadioGroup>
                      </div>
                      <p hidden={this.state.openedCalendar != "endDate"} align="left">Choose when you'll pick up your items</p>
                      {this.state.openedCalendar == "endDate" && endDatePicker}
                      <textarea hidden={this.state.openedCalendar != "endDate" || unavailableLabels.length == 0} disabled class="text-body" rows={"3"} style={{paddingBottom: 0, width: "100%", fontSize: 16, textAlign: "left", border: "none", resize: "none", backgroundColor: "#ffffff00"}}>
                        {listing.user_id + " is unavailable during\n" + unavailableLabels}
                      </textarea>
                    </div>

                    <hr style={{marginTop: 25}}></hr>
                    {listing.includesDelivery && this.state.checkedDelivery && <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                      <p>{"Delivery Charge"}</p>
                      <p>{"$" + totalPaymentDetails.deliveryCost + ".00"}</p>
                    </div>}
                    {this.state.checkedPackaging && <div class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                      <p>{"(" + totalPaymentDetails.boxesCount + "x)" + " Packaging"}</p>
                      <p>{"$" + (totalPaymentDetails.boxesCount * 4.5).toFixed(2)}</p>
                    </div>}
                    {totalPaymentDetails.itemizedPurchase}
                    <div hidden={(this.state.selectedItemQuantities.length == 0 && !this.state.checkedDelivery) || isViewingPurchasedReservation} class="row" style={{width: "100%", margin:0, display: "flex", justifyContent: "space-between"}}>
                      <p><b>{"Total"}</b></p>
                      <p><b>{"$" + totalPaymentDetails.total.toFixed(2)}</b></p>
                    </div>

                    <div class="row" style={{margin:0}}>
                      {(!isViewingPurchasedReservation && !this.state.loadingVenmo) &&
                        <PayButton
                          stripePublicKey={Cache.getItem("urlPrefix").includes("mystorestash") ? "pk_live_51IchnRCeutXiPmnIGQJLuvpSUPzYU5ZbJ6hDiJrfUsBbNdwGkkvowcH9uxdE1hqtV3Pfg6EJARlqgjRWDXMfXx6700M6zrS2EV" : "pk_test_51IchnRCeutXiPmnI2hsMT6rwxsyip9cVTnO2MwUrjZHk12GZRp3in6MMggqjWvccCJhZrN2AewfuUG4WGkDDgjQA00bEdk5N8l"}
                          apiName="stripeApi"
                          apiEndpoint="/checkout"
                          currentSchool={this.state.user == null ? 'No School' : utils.formatSchoolName(utils.getCurrentSchoolAttribute(this.state.user.email, "name"))}
                          showAuthModal={this.props.showAuthModal}
                          showVenmoModal={this.showVenmoModal}
                          name={(this.state.selectedStartDateLabel.length > 0 && this.state.selectedEndDateLabel.length > 0) && "Storage" + " (" + this.state.selectedStartDateLabel[0].label + "-" + this.state.selectedEndDateLabel[0].label + ")"}
                          description={"Storage with " + listing.user_id + " at " + listing.residence_id}
                          images={[listing.img_url]}
                          amount={totalPaymentDetails.total * 100}
                          currency='usd'
                          quantity={1}
                          success_url={Cache.getItem("urlPrefix") + "profile/recentlyPurchased_" + listing.id}
                          cancel_url={Cache.getItem("urlPrefix") + "details/" + listing.id}
                        />
                      }
                      <div style={{width: "100%", marginTop: 10}}>
                        <span style={{marginLeft: 0, color:"grey", fontSize: 10}}>By clicking "Reserve", you agree  to our</span>
                        <button className="button_empty" style={{marginLeft: 0, color:"grey", fontSize: 10, textDecoration: "underline"}}  onClick={() => window.open("http://storestash-terms.s3-website-us-west-2.amazonaws.com/", "_blank")}>{"Terms & Conditions"}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
            </div>
          </div>
          {this.state.size <= 4 && leftColumn}
        </section>
      </main>
    </div>);
  }
}

export default ReservationDetails;
